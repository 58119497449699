import {useMutation, useQueryClient} from "@tanstack/react-query";
import {ChangePasswordInputModel} from "../../../interfaces/models/accountModels/ChangePassword.model";
import {ContactInputModel} from "../../../interfaces/models/accountModels/Contact.model";
import {ForgotPasswordInputModel} from "../../../interfaces/models/accountModels/ForgotPassword.model";
import {ResetPasswordInputModel} from "../../../interfaces/models/accountModels/ResetPassword.model";
import {SignInInputModel, SignInResponseModel,} from "../../../interfaces/models/accountModels/SignIn.model";
import {MyAxiosErrorResponse} from "../../../interfaces/models/AxiosErrorResponse";
import {BasicResponseModel} from "../../../interfaces/models/BasicResponse.model";
import {ErrorResponse} from "../../../interfaces/models/ErrorResponse.model";
import {deleteProperties} from "../../../utils/deleteObjectProperties";
import {isServerError} from "../../../utils/isServerError";
import {readAuthenticationToken, writeAuthenticationToken,} from "../../../utils/storage/authentication";
import {useErrorsStore} from "../../../zustand-store/Errors.store";
import {
  deleteDeactivateMyAccount,
  deleteDeleteProfilePicture,
  postChangePassword,
  postContactUs,
  postForgotPassword,
  postResetPassword,
  postSignIn,
  putEditMyProfile,
} from "../../mutations/Account";
import jwt_decode from "jwt-decode";
import {useUserClaimStore} from "../../../zustand-store/UserClaim.store";

//-----POST mutationsHooks------
export const usePostSignIn = () => {
    const {setErrors, setHasServerError, setHookName, reset} = useErrorsStore();
    const {setAllowedRoutes, setUserClaims} = useUserClaimStore();
    return useMutation(
        ["signIn"],
        (postSignInPayload: SignInInputModel) => postSignIn(postSignInPayload),
        {
            onSuccess: (SignInResponse: BasicResponseModel<SignInResponseModel>) => {
                reset();
                writeAuthenticationToken(SignInResponse.data.token);
                setUserClaims(
                    deleteProperties(
                        jwt_decode(readAuthenticationToken() ?? "") as object,
                        ["email", "role", "nbf", "exp", "iat", "nameid"]
                    )
                );
                setAllowedRoutes(
                    Object.keys(
                        deleteProperties(
                            jwt_decode(readAuthenticationToken() ?? "") as object,
                            ["email", "role", "nbf", "exp", "iat", "nameid"]
                        )
                    )
                );
            },
            onError: (response: unknown) => {
                const responseOnError = response as MyAxiosErrorResponse<ErrorResponse>;
                setHookName("usePostSignIn");
                setErrors(responseOnError.response.data.errors);
                setHasServerError(!!isServerError(responseOnError.response.status));
            },
        }
    );
};
export const usePostContactUs = () => {
    const {setErrors, setHasServerError, setHookName} = useErrorsStore();
    return useMutation(
        ["contactUs"],
        (postContactUsPayload: ContactInputModel) =>
            postContactUs(postContactUsPayload),
        {
            onError: (response: unknown) => {
                setHookName("usePostContactUs");
                const responseOnError = response as MyAxiosErrorResponse<ErrorResponse>;
                setErrors(responseOnError.response.data.errors);
                setHasServerError(!!isServerError(responseOnError.response.status));
            },
        }
    );
};
export const usePostForgotPassword = () => {
    return useMutation(
        ["forgotPassoword"],
        (postForgotPasswordPayload: ForgotPasswordInputModel) =>
            postForgotPassword(postForgotPasswordPayload)
    );
};
export const usePostResetPassword = () => {
    const {setErrors, setHasServerError, setHookName} = useErrorsStore();
    return useMutation(
        ["resetPassoword"],
        (postResetPasswordPayload: ResetPasswordInputModel) =>
            postResetPassword(postResetPasswordPayload),
        {
            onError: (response: unknown) => {
                setHookName("usePostResetPassword");
                const responseOnError = response as MyAxiosErrorResponse<ErrorResponse>;
                setErrors(responseOnError.response.data.errors);
                setHasServerError(!!isServerError(responseOnError.response.status));
            },
        }
    );
};
export const usePostChangePassword = () => {
    const {setErrors, setHasServerError, setHookName} = useErrorsStore();
    return useMutation(
        ["changePassoword"],
        (postChangePasswordPayload: ChangePasswordInputModel) =>
            postChangePassword(postChangePasswordPayload),
        {
            onError: (response: unknown) => {
                setHookName("usePostChangePassword");
                const responseOnError = response as MyAxiosErrorResponse<ErrorResponse>;
                setErrors(responseOnError.response.data.errors);
                setHasServerError(!!isServerError(responseOnError.response.status));
            },
        }
    );
};
//-----PUT mutationsHooks------
export const usePutEditMyProfile = () => {
    const {setErrors, setHasServerError} = useErrorsStore();
    const queryClient = useQueryClient();
    return useMutation(
        ["editMyProfile"],
        (putEditProfilePayload: FormData) =>
            putEditMyProfile(putEditProfilePayload),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["myProfile"]);
            },
            onError: (response: unknown) => {
                const responseOnError = response as MyAxiosErrorResponse<ErrorResponse>;
                setErrors(responseOnError.response.data.errors);
                setHasServerError(!!isServerError(responseOnError.response.status));
            },
        }
    );
};

//-----DELETE mutationsHooks-----
export const useDeleteDeactivateMyAccount = () => {
    const {setErrors, setHasServerError} = useErrorsStore();
    return useMutation(
        ["deactivateMyAccount"],
        () => deleteDeactivateMyAccount(),
        {
            onError: (response: unknown) => {
                const responseOnError = response as MyAxiosErrorResponse<ErrorResponse>;
                setErrors(responseOnError.response.data.errors);
                setHasServerError(!!isServerError(responseOnError.response.status));
            },
        }
    );
};
export const useDeleteProfilePicture = () => {
    const {setErrors, setHasServerError} = useErrorsStore();
    return useMutation(
        ["deleteProfilePicture"],
        () => deleteDeleteProfilePicture(),

        {
            onError: (response: unknown) => {
                const responseOnError = response as MyAxiosErrorResponse<ErrorResponse>;
                setErrors(responseOnError.response.data.errors);
                setHasServerError(!!isServerError(responseOnError.response.status));
            },
        }
    );
};
