/**
 *
 * @param {one of File or null or undefined} file
 * *Allowed file size
 * @param {number} allowed
 */

import Joi from "joi";

export const validateFileSize = (
  file: File | null | undefined,
  allowed: number
): boolean => (file != null ? file.size / 1024 / 1024 < allowed : false);

/**
 *
 * @param {one of File or null or undefined} file
 * *Allowed file type
 * @param {string[]} allowedFileType
 */

export const validateFileType = (
  file: File | null | undefined,
  allowedFileType: string[]
): boolean =>
  file != null
    ? allowedFileType.some((i) => i.trim().includes(file?.type))
    : false;

// file validation
export const validateFile = (
  file: File | null,
  size: number,
  type: string[],
  helpers: Joi.CustomHelpers
): File | Joi.ErrorReport | null => {
  // validate
  if (!file) return null;
  if (file !== null && !validateFileSize(file, size)) {
    return helpers.error("any.fileSize");
  }

  return file;
};
