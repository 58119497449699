import EditIcon from "@mui/icons-material/Edit";
import { FC, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDeleteMagazineArticle } from "../../../react-query/hooks/mutationHooks/Magazine";
import { closeModalWithDelay } from "../../../utils/closeModalWithDelay";
import MuiModal from "../../../modals/MuiModal";
import Delete from "../../../modals/dashboard/Delete";
import AddArticleInformations from "../../../modals/dashboard/AddArticleInformations";
import { useUserClaimStore } from "../../../zustand-store/UserClaim.store";
import { useTranslation } from "react-i18next";

interface ArticleProps {
  articleId: number;
  articleTitle: string;
  articleTags: string[];
}
const Article: FC<ArticleProps> = ({
  articleId,
  articleTitle,
  articleTags,
}) => {
  const { t } = useTranslation();
  const { canRoleEdit, canRoleDelete } = useUserClaimStore();

  const [modalShow, setModalShow] = useState(false);
  const handleModalShow = () => setModalShow(true);

  //deleteArticle
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const handleDeleteModalShow = () => setDeleteModalShow(true);
  const {
    mutate: deleteArticle,
    isLoading,
    isSuccess,
  } = useDeleteMagazineArticle();
  const handleDeleteArticle = () => {
    deleteArticle(articleId, {
      onSuccess: () => {
        closeModalWithDelay(setDeleteModalShow);
      },
    });
  };
  return (
    <>
      <div className="article">
        <div>
          <h4>{articleTitle}</h4>
          <div className="article-Tags">
            {articleTags?.map((article) => {
              return (
                <div className="article-Tags-tag" key={`${article}`}>
                  {article}
                </div>
              );
            })}
          </div>
        </div>
        <div>
          {canRoleEdit && (
            <EditIcon onClick={handleModalShow} className="muiIcon" />
          )}

          {canRoleDelete && (
            <DeleteIcon className="muiIcon" onClick={handleDeleteModalShow} />
          )}
        </div>
      </div>
      <MuiModal
        title={t("dashboard.magazine.articles.modals.delete_folder")}
        description={t("dashboard.magazine.articles.modals.are_you_sure")}
        modalContent={
          <Delete
            isLoading={isLoading}
            isSuccess={isSuccess}
            deleteFunction={handleDeleteArticle}
            setModalShow={setDeleteModalShow}
          />
        }
        modalShow={deleteModalShow}
        setModalShow={setDeleteModalShow}
      />
      <MuiModal
        title="Article informations"
        description="Change those field and save to update an article"
        modalContent={
          <AddArticleInformations
            update
            title={articleTitle}
            setModalShow={setModalShow}
            articleTags={articleTags}
            articleId={articleId}
          />
        }
        modalShow={modalShow}
        setModalShow={setModalShow}
      />
    </>
  );
};
export default Article;
