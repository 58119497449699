import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import "./_MediaFilePreview.scss";

export default function MediaFilePreviewModal({
  modalShow,
  setModalShow,
  filePath,
  isVideo,
  isImage,
  videoType,
  fileName,
}: {
  modalShow: boolean;
  setModalShow: (modalShow: boolean) => void;
  filePath: string;
  isVideo: boolean;
  isImage: boolean;
  videoType?: string;
  fileName: string;
}) {
  //close modal
  const handleCloseModal = () => {
    setModalShow(false);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={modalShow}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      sx={{ background: "rgba(0, 0, 0, 0.9)", zIndex: "10005" }}
    >
      <Fade in={modalShow}>
        <Box>
          <div className="mediaFilePreviewModal">
            {isImage && <img src={filePath} alt={fileName} />}
            {isVideo && (
              <video controls autoPlay={false}>
                <source src={filePath} type={`video/${videoType}`} />
              </video>
            )}
            <div className="mediaFilePreviewModal-icon">
              <HighlightOffIcon
                sx={{
                  color: "rgba(255, 255, 255, 0.5)",
                  "&:hover": { color: "white" },
                  fontSize: "25px",
                }}
                onClick={handleCloseModal}
              />
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
