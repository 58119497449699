import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { basicParamsTypes } from '../../../interfaces/models/basicParams.model';
import { FileResponseModel } from '../../../interfaces/models/mediaFile/FileResponse.model';
import { ResponseModel } from '../../../interfaces/models/Response.model';
import { getAllMediaFiles, getMediaFileById } from '../../queries/MediaFile';

export const useGetAllMediaFilesQuery = (
  getAllMediaFilesParams?: basicParamsTypes,
  options?: Omit<
    UseQueryOptions<ResponseModel<FileResponseModel[]>>,
    'queryKey' | 'queryFn'
  >
): UseQueryResult<ResponseModel<FileResponseModel[]>> => {
  return useQuery(
    [
      'getAllMediaFiles',
      getAllMediaFilesParams?.pageNumber,
      getAllMediaFilesParams?.searchWord,
    ],
    () => getAllMediaFiles(getAllMediaFilesParams),
    options
  );
};

export const useGetMediaFileByIdQuery = (
  mediaFileId: number,
  options?: Omit<
    UseQueryOptions<ResponseModel<FileResponseModel>>,
    'queryKey' | 'queryFn'
  >
): UseQueryResult<ResponseModel<FileResponseModel>> => {
  return useQuery(
    ['getMediaFileById'],
    () => getMediaFileById(mediaFileId),
    options
  );
};
