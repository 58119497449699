import { joiResolver } from "@hookform/resolvers/joi";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { FC } from "react";
import { useForm } from "react-hook-form";
import Button from "../../components/atoms/Button/Button.tsx";
import StatusState from "../../components/molecules/StatusState/StatusState";
import { CreateUserInputModel } from "../../interfaces/models/adminModels/CreateUser.model";
import {
  usePostCreateUser,
  usePutCreateUser,
} from "../../react-query/hooks/mutationHooks/Admin";
import { useGetAllRolesQuery } from "../../react-query/hooks/queriesHooks/Admin";
import { CreateUserSchema } from "../../schemas/adminSchemas/CreateUser.schema";
import { closeModalWithDelay } from "../../utils/closeModalWithDelay";
import { useErrorsStore } from "../../zustand-store/Errors.store";
import { useTranslation } from "react-i18next";

interface AddUserProp {
  update?: boolean;
  userId?: string;
  userFullName?: string;
  userEmail?: string;
  userRole?: {
    role: string;
    roleId: string;
  }[];
  setModalShow: (modalshow: boolean) => void;
}
const AddUser: FC<AddUserProp> = ({
  update,
  userId,
  userEmail,
  userFullName,
  userRole,
  setModalShow,
}) => {
  const { t } = useTranslation();
  let name = userFullName?.split(" ")[0];
  let lastName = userFullName?.split(" ")[1];
  const { errors: serverResponseErrors, hookName } = useErrorsStore();
  const { data: { data: roles } = {} } = useGetAllRolesQuery("");
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CreateUserInputModel>({
    resolver: joiResolver(CreateUserSchema),
    defaultValues: {
      FirstName: name,
      LastName: lastName,
      Email: userEmail,
      Role: userRole && userRole[0].role,
    },
  });
  const handleCancel = () => {
    reset();
    setModalShow(false);
  };
  const {
    mutate: createUser,
    isLoading: isPostLoading,
    isError: isPostError,
    isSuccess: isPostSuccess,
  } = usePostCreateUser();
  const handleAddUser = (addUserPayload: CreateUserInputModel) => {
    createUser(addUserPayload, {
      onSuccess: () => {
        closeModalWithDelay(setModalShow);
      },
    });
  };
  const {
    mutate: putEditUserProfile,
    isLoading: isPutLoading,
    isError: isPutError,
    isSuccess: isPutSuccess,
  } = usePutCreateUser(userId!);
  const handleUpdateAddUser = (updateAddUserPayload: CreateUserInputModel) => {
    putEditUserProfile(
      { ...updateAddUserPayload, Role: updateAddUserPayload.Role },
      {
        onSuccess: () => {
          closeModalWithDelay(setModalShow);
        },
      }
    );
  };
  return (
    <form
      className="muiModal-content"
      onSubmit={handleSubmit(update ? handleUpdateAddUser : handleAddUser)}
    >
      <div className="muiModal-content-inputs">
        <TextField
          className="muiModal-content-inputs-input"
          fullWidth
          id="outlined-basic"
          label={t("reusable.name")}
          color="success"
          variant="outlined"
          error={!!errors?.FirstName}
          helperText={errors?.FirstName?.message}
          {...register("FirstName")}
        />
        <TextField
          className="muiModal-content-inputs-input"
          fullWidth
          id="outlined-basic"
          label={t("reusable.last_name")}
          color="success"
          variant="outlined"
          error={!!errors?.LastName}
          helperText={errors?.LastName?.message}
          {...register("LastName")}
        />
        <TextField
          className="muiModal-content-inputs-input"
          fullWidth
          disabled={update}
          id="outlined-basic"
          label={t("reusable.email")}
          color="success"
          variant="outlined"
          error={!!errors?.Email}
          helperText={errors?.Email?.message}
          {...register("Email")}
        />
        <TextField
          fullWidth
          className="muiModal-content-inputs-input"
          select
          label={t("dashboard.reusable.role")}
          color="success"
          defaultValue={userRole && userRole[0].role}
          error={!!errors?.Role}
          helperText={errors?.Role?.message}
          {...register("Role")}
        >
          {roles?.map((role) => {
            return (
              <MenuItem value={role?.name} key={role?.roleId}>
                {role.name}
              </MenuItem>
            );
          })}
        </TextField>
      </div>
      <div className="muiModal-content-status">
        {isPostLoading && (
          <StatusState
            statusText={t("reusable.loading")}
            statusType="loading"
          />
        )}
        {isPostSuccess && (
          <StatusState
            statusText={t("dashboard.users.modals.success_post_status")}
            statusType="success"
          />
        )}
        {isPutLoading && (
          <StatusState
            statusText={t("reusable.loading")}
            statusType="loading"
          />
        )}
        {isPutSuccess && (
          <StatusState
            statusText={t("dashboard.users.modals.success_update_status")}
            statusType="success"
          />
        )}
      </div>
      {!isPostLoading && !isPostSuccess && !isPutLoading && !isPutSuccess && (
        <div className="muiModal-content-modalbuttons">
          <Button
            onClick={handleCancel}
            content={t("dashboard.reusable.cancel")}
            styleType="third"
            type="button"
          />
          <Button
            content={t("dashboard.reusable.save")}
            styleType="second"
            type="submit"
          />
        </div>
      )}
      {serverResponseErrors !== undefined && hookName === "updateUser" && (
        <div className="firstButton" style={{ marginTop: "15px" }}>
          <StatusState
            statusType="error"
            statusText={serverResponseErrors[1].message}
          />
        </div>
      )}
    </form>
  );
};
export default AddUser;
