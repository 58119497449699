import {
  DashboardModel,
  MagazineStatisticsByIdModel,
} from '../../interfaces/models/statistics/Dashboard.model';
import axios from '../../initializations/axios';

export const getDashboardData = async (): Promise<DashboardModel> => {
  const { data } = await axios.get('statistics/dashboard');
  return data.data;
};

export const getMagazineStatisticsById = async (
  id: number
): Promise<MagazineStatisticsByIdModel> => {
  const { data } = await axios.get('statistics/getMagazineStatistics', {
    params: { magazineId: id },
  });
  return data.data;
};
