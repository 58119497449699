import { SubscribeInputModel } from "../../interfaces/models/newsletterModels/Subscribe.model";
import axios from "../../initializations/axios";

//-----POST MUTATION-----
export const postSubscribe = async (
  subscribePayload: SubscribeInputModel
): Promise<any> => {
  const { data } = await axios.post("NewsLetter/Subscribe", subscribePayload);
  return data;
};
//-----DELETE MUTATION-----
export const deleteUnsubscribe = async (email: string): Promise<any> => {
  const { data } = await axios.delete("NewsLetter/Unsubscribe", {
    params: { email: email },
  });
  return data;
};
export const deleteRemoveSubscriber = async (email: string): Promise<any> => {
  const { data } = await axios.delete("NewsLetter/RemoveSubscriber", {
    params: { email: email },
  });
  return data;
};
