import { FC, useRef, useState } from "react";
import { PageResponseModel } from "../../../interfaces/models/magazineModels/PageResponse.model";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import useDraggableScroll from "../../../hooks/useDraggableScroll";
import { Box } from "@mui/material";

interface BestSwiperProps {
    selectedPage: number;
    setSelectedPage: (selectedPage: number) => void;
    allPages: PageResponseModel[] | undefined;
}

const BestSwiper: FC<BestSwiperProps> = ({
  selectedPage,
  setSelectedPage,
  allPages,
}) => {
  const [hiddenPagesNav, setHiddenPagesNav] = useState<boolean>(true);
  const cardsRef = useRef<HTMLDivElement>(null);
  useDraggableScroll(cardsRef);
  const [availablePreview, setAvailablePreview] = useState<number | null>(null);
  const handleBeforeIconClick = () => {
    if (selectedPage !== 0) {
      setSelectedPage(selectedPage - 1);
    }
  };

  const handleNextIconClick = () => {
    if (selectedPage + 1 !== allPages?.length) {
      setSelectedPage(selectedPage + 1);
    }
  };

  return (
    <div className="bestSwiper">
      <div
        className="bestSwiper-pages"
        style={hiddenPagesNav ? { display: "none" } : { display: "flex" }}
        ref={cardsRef}
      >
        {allPages?.map((page, index) => (
          <div
            key={page.id}
            className={`interactive-cursor pageItem ${
              selectedPage === index ? "selected" : ""
            }`}
            onClick={() => setSelectedPage(index)}
          >
            <div className="iframe-container">
              <Box
                position="absolute"
                top={0}
                bottom={0}
                left={0}
                right={0}
                zIndex={availablePreview === page.id ? -4 : 3}
              >
                <button
                  onClick={() => setSelectedPage(page.id)}
                  type="button"
                  style={{
                    zIndex: availablePreview === page.id ? -4 : 3,
                  }}
                  className="btn-overlay"
                />
              </Box>
              <Box position="absolute" top={0} padding={0.5} zIndex={4}>
                <button
                  onClick={() =>
                    setAvailablePreview(
                      availablePreview === page.id ? 0 : page.id
                    )
                  }
                  type="button"
                  className="ambient-button ghost-button interactive-cursor interactive-button"
                >
                  Preview
                </button>
              </Box>
              <iframe
                title={page.title}
                src={page.pagePath}
                style={{
                  width: "100%",
                  border: "0",
                  height: "100%",
                }}
                className={`transition-all duration-300 ${
                  availablePreview === page.id ? "unscale" : "scale"
                }`}
              />
            </div>
            <p>
              <span>{index + 1}.</span>
              {page.title}
            </p>
          </div>
        ))}
      </div>
      <div className="bestSwiper-navigation">
        {/*<div className="bestSwiper-printBtn">*/}
        {/*    <button type="button" className="printBtn">*/}
        {/*        Download magazine*/}
        {/*    </button>*/}
        {/*</div>*/}
        <div className="bestSwiper-navigation-btns">
          <button
            type="button"
            onClick={handleBeforeIconClick}
            className="ghost-button interactive-cursor interactive-button"
          >
            <NavigateBeforeIcon
              className={selectedPage !== 0 ? "" : "disable"}
            />
          </button>
          <button
            type="button"
            onClick={() => setHiddenPagesNav(!hiddenPagesNav)}
            className="bestSwiper-navigation-btns-middle ghost-button interactive-button group interactive-cursor"
          >
            {hiddenPagesNav ? (
              <KeyboardDoubleArrowUpIcon className="group-hover:anchoring" />
            ) : (
              <KeyboardDoubleArrowDownIcon className="group-hover:anchoring" />
            )}
            <p>{`${selectedPage + 1} / ${allPages?.length}`}</p>
          </button>
          <button
            type="button"
            onClick={handleNextIconClick}
            className="ghost-button interactive-button interactive-cursor"
          >
            <NavigateNextIcon
              className={selectedPage + 1 !== allPages?.length ? "" : "disable"}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default BestSwiper;
