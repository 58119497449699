import { joiResolver } from "@hookform/resolvers/joi";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import { ChangePasswordInputModel } from "../../../interfaces/models/accountModels/ChangePassword.model";
import { usePostChangePassword } from "../../../react-query/hooks/mutationHooks/Account";
import { ChangePasswordSchema } from "../../../schemas/accountSchemas/ChangePassword.schema";
import { useErrorsStore } from "../../../zustand-store/Errors.store";
import Button from "../../atoms/Button/Button.tsx";
import StatusState from "../../molecules/StatusState/StatusState";
import { useTranslation } from "react-i18next";

const ChangePassword = () => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ChangePasswordInputModel>({
    resolver: joiResolver(ChangePasswordSchema),
  });
  const {
    mutate: changePassword,
    isLoading,
    isSuccess,
    reset: resetMutateState,
  } = usePostChangePassword();
  const handleResetPassword = (
    changePasswordPayload: ChangePasswordInputModel
  ) => {
    changePassword(changePasswordPayload, {
      onSuccess: () => {
        setTimeout(() => {
          resetMutateState();
          reset();
        }, 3000);
      },
    });
  };
  const { errors: serverResponseErrors, hookName } = useErrorsStore();
  const handleCancel = () => {
    reset();
  };
  return (
    <div className="settingsOrganismsStyle">
      <h3 className="settingsOrganismsStyle-title">Change Password</h3>
      <>
        <form onSubmit={handleSubmit(handleResetPassword)}>
          <div className="settingsOrganismsStyle-inputs">
            <TextField
              fullWidth
              className="input"
              id="outlined-basic"
              label={t("dashboard.settings.current_password")}
              type="password"
              color="success"
              variant="outlined"
              error={!!errors?.currentPassword}
              helperText={errors?.currentPassword?.message}
              {...register("currentPassword")}
            />
            <TextField
              fullWidth
              className="input"
              id="outlined-basic"
              label={t("dashboard.settings.new_password")}
              type="password"
              color="success"
              variant="outlined"
              error={!!errors?.newPassword}
              helperText={errors?.newPassword?.message}
              {...register("newPassword")}
            />
            <TextField
              fullWidth
              className="input"
              id="outlined-basic"
              label={t("dashboard.settings.confrim_new_password")}
              type="password"
              color="success"
              variant="outlined"
              error={!!errors?.confirmNewPassword}
              helperText={errors?.confirmNewPassword?.message}
              {...register("confirmNewPassword")}
            />
          </div>
          {isLoading ? (
            <div className="settingsOrganismsStyle-status">
              <StatusState
                statusText={t("reusable.loading")}
                statusType="loading"
              />
            </div>
          ) : (
            <>
              {isSuccess && (
                <div className="settingsOrganismsStyle-status">
                  <StatusState
                    statusText={t(
                      "dashboard.settings.success_new_password_status"
                    )}
                    statusType="success"
                  />
                </div>
              )}
              <div className="settingsOrganismsStyle-buttons">
                <Button
                  onClick={handleCancel}
                  content={t("dashboard.reusable.cancel")}
                  styleType="third"
                  type="button"
                />
                <Button
                  content={t("dashboard.settings.reset_password")}
                  styleType="second"
                  type="submit"
                  disable={isSuccess}
                />
              </div>
              {serverResponseErrors !== undefined &&
                hookName === "usePostChangePassword" && (
                  <div className="settingsOrganismsStyle-status">
                    {serverResponseErrors.map((error) => {
                      return (
                        <StatusState
                          statusType="error"
                          statusText={error.message}
                        />
                      );
                    })}
                  </div>
                )}
            </>
          )}
        </form>
      </>
    </div>
  );
};

export default ChangePassword;
