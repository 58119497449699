import Joi from "joi";
import { validateFile } from "../../utils/fileValidation";

export const AddMediaFileSchema = Joi.object({
  FileName: Joi.string().required().messages({ "string.empty": "Required!" }),
  File: Joi.alternatives()
    .try(
      Joi.custom(
        (value, helpers) =>
          validateFile(
            value,
            5,
            [
              "image/jpeg",
              "image/gif",
              "image/jpg",
              "image/png",
              "video/mp4",
              "video/webm",
              "video/ogg",
            ],
            helpers
          ),
        "file-validate"
      )
    )
    .messages({
      "any.fileSize": "The maximum file size that can be uploaded  is 5 MB.",
      "any.fileType": "This Type is not allowed!",
    })
    .required()
    .messages({ "any.required": "Required!" }),
});
