import {ReactElement, useLayoutEffect} from "react";
import {Outlet, useLocation} from "react-router-dom";
import {PageTitleEnum} from "../interfaces/enums/PageTitle.enum";
import {PageTitles} from "../interfaces/types/PageTitles.type";
import MuiModal from "../modals/MuiModal";
import {useModalStore} from "../zustand-store/Modal.store";
import {useTranslation} from "react-i18next";

export function MainLayout(): ReactElement {
    const {t} = useTranslation();
    const location = useLocation();
    let locationPath: PageTitles = location.pathname.toString() as PageTitles;

    const numberMatch = location.pathname.match(/\/(\d+)$/);
    if (numberMatch) {
        locationPath = location.pathname.substring(
            0,
            location.pathname.lastIndexOf(`/${numberMatch[1]}`)
        ) as PageTitles;
    }

    const {modalShow, setModalShow, title, description, modalContent} =
        useModalStore();


    useLayoutEffect(() => {
        window.scrollTo(0, 0)
        document.title = t(PageTitleEnum[locationPath]);
    }, [location.pathname]);

    return (
        <>
            <Outlet/>
            <MuiModal
                title={title}
                description={description}
                modalContent={modalContent}
                modalShow={modalShow}
                setModalShow={setModalShow}
                zIndex={10005}
            />
        </>
    );
}
