import { useTranslation } from "react-i18next";
import ChangePassword from "../../components/organisms/SettingsOrganisms/ChangePassword";
import DeactivateAccount from "../../components/organisms/SettingsOrganisms/DeactivatedAccount";
import ProfilDetails from "../../components/organisms/SettingsOrganisms/ProfilDetails";
import "./_Settings.scss";
import { Box } from "@mui/material";

const Settings = () => {
  const { t } = useTranslation();
  return (
    <Box marginTop={2.5} width="100%">
      <h2 className="pagesStyle-title">
        {t("dashboard.settings.account_overview")}
      </h2>
      <div className="settings-content">
        <ProfilDetails />
        <ChangePassword />
        <DeactivateAccount />
      </div>
    </Box>
  );
};
export default Settings;
