import { FC } from 'react';

interface PopoverProps {
  show: boolean;
  text: string;
}
const Popover: FC<PopoverProps> = ({ show, text }) => {
  return (
    <div style={!show ? { display: 'none' } : {}} className="popover">
      {text}
    </div>
  );
};
export default Popover;
