import { useMutation } from "@tanstack/react-query";
import { SubscribeInputModel } from "../../../interfaces/models/newsletterModels/Subscribe.model";
import { postSubscribe } from "../../mutations/NewsLetter";

//-----POST mutationsHooks-----
export const usePostSubscribe = () => {
  return useMutation(
    ["subscribe"],
    (postSubscribePayload: SubscribeInputModel) =>
      postSubscribe(postSubscribePayload)
  );
};
