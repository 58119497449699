import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { ClaimTypeResponseModel } from "../../../interfaces/models/adminModels/ClaimTypeResponse.model";
import { RoleResponseModel } from "../../../interfaces/models/adminModels/Role.model";
import { UserResponseModel } from "../../../interfaces/models/adminModels/User.model";
import { basicParamsTypes } from "../../../interfaces/models/basicParams.model";
import { BasicResponseModel } from "../../../interfaces/models/BasicResponse.model";
import { ResponseModel } from "../../../interfaces/models/Response.model";
import {
  getAllClaimTypes,
  getAllRoles,
  getAllUsers,
  getRoleById,
} from "../../queries/Admin";

export const useGetAllRolesQuery = (
  searchWord: string,
  options?: Omit<
    UseQueryOptions<BasicResponseModel<RoleResponseModel[]>>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<BasicResponseModel<RoleResponseModel[]>> => {
  return useQuery(
    ["getAllRoles", searchWord],
    () => getAllRoles(searchWord),
    options
  );
};
export const useGetAllUsersQuery = (
  getAllUsersParams: basicParamsTypes,
  options?: Omit<
    UseQueryOptions<ResponseModel<UserResponseModel[]>>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<ResponseModel<UserResponseModel[]>> => {
  return useQuery(
    ["getAllUsers", getAllUsersParams.pageNumber, getAllUsersParams.searchWord],
    () => getAllUsers(getAllUsersParams),
    options
  );
};
export const useGetAllClaimTypesQuery = (
  options?: Omit<
    UseQueryOptions<BasicResponseModel<ClaimTypeResponseModel[]>>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<BasicResponseModel<ClaimTypeResponseModel[]>> => {
  return useQuery(["getAllClaimTypes"], () => getAllClaimTypes(), options);
};
export const useGetRoleByIdQuery = (
  roleId: string,
  options?: Omit<
    UseQueryOptions<BasicResponseModel<RoleResponseModel>>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<BasicResponseModel<RoleResponseModel>> => {
  return useQuery(["getRole", roleId], () => getRoleById(roleId), options);
};
