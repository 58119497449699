import Joi from "joi";
import { TFunction } from "react-i18next";

export const ForgotPasswordSchema = (t: TFunction) =>
  Joi.object({
    email: Joi.string()
      .required()
      .messages({ "string.empty": t("reusable.required") }),
    languageId: Joi.string().default("1"),
  });
