import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Menu,
  TextField,
  Typography,
} from "@mui/material";
import "./_Statistics.scss";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { useGetAllMagazinesQuery } from "../../react-query/hooks/queriesHooks/Magazine";
import { useSearchParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

export const Filters = ({ onFilterApply }: { onFilterApply: () => void }) => {
  const { t } = useTranslation();
  //local state
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [page, setPage] = useState<number>(1);

  const queryClient = useQueryClient();

  let [searchParams, setSearchParams] = useSearchParams();
  const [searchWord, setSearchWord] = useState<string>("");

  //react-query
  const { data: { data: Magazines, metaData } = {}, refetch } =
    useGetAllMagazinesQuery({
      pageNumber: String(page),
      searchWord: searchWord,
    });

  //constants
  const magazinesArray =
    Magazines?.map((magazine) => ({
      id: magazine.id,
      name: magazine.title,
    })) ?? [];

  //handlers
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectMagazines = (e: any, value: any) => {
    const selectedItems = value.map((v: any) => v.id);
    setSearchParams(
      (prev) =>
        `${prev.get("startDate") ? `startDate=${prev.get("startDate")}&` : ""}${
          prev.get("endDate") ? `endDate=${prev.get("endDate")}&` : ""
        }&magazineIds=${selectedItems}`
    );
  };

  const handleFilterApply = () => onFilterApply();

  const handleResetFilters = () => {
    queryClient.removeQueries(["getMagazineReadingStatistics"]);
    setSearchParams();
    handleClose();
  };

  useEffect(() => {
    refetch();
  }, [page, searchWord]);

  return (
    <>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        color="success"
        disableElevation
        sx={{ textTransform: "none" }}
        onClick={handleClick}
        endIcon={<FilterAltIcon />}
      >
        {t("dashboard.statistics.filters")}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        elevation={8}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            maxWidth: 320,
            padding: 6,
          },
        }}
      >
        <Typography fontSize="10px">
          {t("dashboard.statistics.periode")}
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 10,
              marginTop: 10,
            }}
          >
            <DatePicker
              inputFormat="MMM YYYY"
              value={searchParams.get("startDate")}
              views={["year", "month"]}
              onChange={(value) =>
                setSearchParams(
                  (prev) =>
                    `startDate=${dayjs(value)
                      .set("date", 1)
                      .format("YYYY-MM-DD")}${
                      prev.get("endDate")
                        ? `&endDate=${prev.get("endDate")}`
                        : ""
                    }${
                      prev.get("magazineIds")
                        ? `&magazineIds=${prev.get("magazineIds")}`
                        : ""
                    }`
                )
              }
              disableFuture
              maxDate={searchParams.get("endDate")}
              InputProps={{
                disableUnderline: true,
                sx: {
                  backgroundColor: "#E5E5E5",
                  borderRadius: "5px",
                  pr: 1,
                  py: 1,
                  fontSize: "10px",
                  height: 40,
                },
              }}
              renderInput={(params) => (
                <TextField variant="standard" size="small" {...params} />
              )}
            />
            <Typography fontSize="10px">
              {t("dashboard.statistics.to")}
            </Typography>
            <DatePicker
              inputFormat="MMM YYYY"
              value={searchParams.get("endDate")}
              views={["year", "month"]}
              onChange={(value) =>
                setSearchParams(
                  (prev) =>
                    `${
                      prev.get("startDate")
                        ? `startDate=${prev.get("startDate")}&`
                        : ""
                    }endDate=${dayjs(value)
                      .set("date", 1)
                      .format("YYYY-MM-DD")}${
                      prev.get("magazineIds")
                        ? `&magazineIds=${prev.get("magazineIds")}`
                        : ""
                    }`
                )
              }
              disableFuture
              InputProps={{
                disableUnderline: true,
                sx: {
                  backgroundColor: "#E5E5E5",
                  borderRadius: "5px",
                  px: 1,
                  py: 1,
                  height: 40,
                },
              }}
              renderInput={(params) => (
                <TextField variant="standard" size="small" {...params} />
              )}
            />
          </div>
        </LocalizationProvider>
        <Typography fontSize="10px" my={1}>
          {t("dashboard.statistics.magazine")}
        </Typography>
        <Autocomplete
          multiple
          limitTags={2}
          id="multiple-limit-tags"
          options={magazinesArray}
          getOptionLabel={(option) => option.name}
          onChange={handleSelectMagazines}
          ListboxProps={{
            style: { height: 150, overflow: "auto" },
            onScroll: (event) => {
              const listboxNode = event.currentTarget;
              if (
                listboxNode.scrollTop + listboxNode.clientHeight ===
                listboxNode.scrollHeight
              ) {
                setPage(page + 1);
              }
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              onChange={(e) => setSearchWord(e.target.value)}
              placeholder={t("dashboard.statistics.search_magazine")}
              InputProps={{
                ...params.InputProps,
                type: "search",
              }}
            />
          )}
          //   renderOption={(props, option) => <li {...props}>{option.name}</li>}
          //   sx={{ width: '500px' }}
        />
        {/* <MultiSelect
          label="Magazines"
          options={magazinesArray}
          onSelect={handleSelectMagazines}
        /> */}
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            gap: 10,
            marginTop: 10,
          }}
        >
          <Button
            color="inherit"
            variant="contained"
            onClick={handleResetFilters}
            sx={{ textTransform: "none" }}
          >
            {t("dashboard.statistics.reset")}
          </Button>
          <Button
            type="submit"
            color="success"
            variant="contained"
            onClick={handleFilterApply}
            sx={{ textTransform: "none" }}
          >
            {t("dashboard.statistics.apply")}
          </Button>
        </div>
      </Menu>
    </>
  );
};
