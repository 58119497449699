import { useEffect, useState } from 'react';

export const useFilePreview = (file: File | string | undefined | null) => {
  const [image, setImage] = useState<string | undefined | null>();

  // Effects
  useEffect(() => {
    if (file && typeof file !== 'string') {
      const newUrl = URL.createObjectURL(file);
      if (newUrl !== image) setImage(newUrl);
    } else setImage(file);
  }, [file]);

  // Handlers
  const onSetImage = (value: string | undefined | null) => {
    setImage(value);
  };

  return { image, onSetImage };
};
