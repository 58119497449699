import { create } from 'zustand';
import { UserModel } from '../interfaces/models/User.model';

interface AuthenticationStore {
  authenticatedUser: UserModel | null;
  isAuthenticated: boolean;
  setAuthenticatedUser: (
    user: UserModel | null,
    isAuthenticated: boolean
  ) => void;
  removeAuthenticatedUser: () => void;
}

export const useAuthenticationStore = create<AuthenticationStore>((set) => ({
  authenticatedUser: null,
  isAuthenticated: false,
  setAuthenticatedUser: (authenticatedUser, isAuthenticated) =>
    set(() => ({ authenticatedUser, isAuthenticated })),
  removeAuthenticatedUser: () =>
    set(() => ({
      authenticatedUser: null,
      isAuthenticated: false,
    })),
}));
