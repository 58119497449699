import { useState } from "react";
import "./_Magazine.scss";
import { useParams } from "react-router-dom";
import LoadingStatus from "../LoadingStatus/LoadingStatus";
import NoResults from "../NoResults/NoResults";
import { useGetAllMagazineArticlesQuery } from "../../../react-query/hooks/queriesHooks/Magazine";
import Article from "../../molecules/Magazine/Article";
import MuiModal from "../../../modals/MuiModal";
import AddArticleInformations from "../../../modals/dashboard/AddArticleInformations";
import { useUserClaimStore } from "../../../zustand-store/UserClaim.store";
import { useTranslation } from "react-i18next";

const Articles = ({ index }: { index: number }) => {
  const { t } = useTranslation();
  const { canRoleCreate } = useUserClaimStore();
  const { magazineId } = useParams();
  const { data: { data: articles } = {}, isLoading } =
    useGetAllMagazineArticlesQuery(Number(magazineId), {
      enabled: index === 1,
    });
  const [modalShow, setModalShow] = useState(false);
  const handleModalShow = () => setModalShow(true);
  if (isLoading) {
    <LoadingStatus />;
  }
  return (
    <>
      <div>
        <div className="tab-header">
          <div className="tab-header-title">
            <h3>{t("dashboard.magazine.articles.content_informations")}</h3>
            <p>{t("dashboard.magazine.articles.need_one_article")}</p>
          </div>
          {canRoleCreate && (
            <button className="tab-header-button" onClick={handleModalShow}>
              {`+ ${t("dashboard.magazine.articles.add_article")}`}
            </button>
          )}
        </div>
        {articles?.length === 0 ? (
          <NoResults />
        ) : (
          <div className="tab-body">
            {articles?.map((article) => {
              return (
                <Article
                  key={article?.id}
                  articleId={article?.id}
                  articleTitle={article?.title}
                  articleTags={article?.tags}
                />
              );
            })}
          </div>
        )}
      </div>
      <MuiModal
        title={t("dashboard.magazine.articles.modals.article_infos")}
        description={t("dashboard.reusable.complete_fields")}
        modalContent={<AddArticleInformations setModalShow={setModalShow} />}
        modalShow={modalShow}
        setModalShow={setModalShow}
      />
    </>
  );
};

export default Articles;
