import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { ResponseModel } from "../../../interfaces/models/Response.model";
import { basicParamsTypes } from "../../../interfaces/models/basicParams.model";
import { SubscriberResponseModel } from "../../../interfaces/models/newsletterModels/Subscriber.model";
import { getAllNewsletterSubscribers } from "../../queries/NewsLetter";

export const useAllNewsletterSubscribersQuery = (
  userNumber: number,
  getAllNewsletterSubscribersParams: basicParamsTypes,
  options?: Omit<
    UseQueryOptions<ResponseModel<SubscriberResponseModel[]>>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<ResponseModel<SubscriberResponseModel[]>> => {
  return useQuery(
    [
      "getAllUsers",
      getAllNewsletterSubscribersParams.pageNumber,
      getAllNewsletterSubscribersParams.searchWord,
      userNumber,
    ],
    () =>
      getAllNewsletterSubscribers(
        userNumber,
        getAllNewsletterSubscribersParams
      ),
    options
  );
};
export const useGetAllNewsletterSubscribersForExelQuery = (
  userNumber: number,
  getAllNewsletterSubscribersParams: basicParamsTypes,
  options?: Omit<
    UseQueryOptions<ResponseModel<SubscriberResponseModel[]>>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<ResponseModel<SubscriberResponseModel[]>> => {
  return useQuery(
    ["getAllUsers"],
    () =>
      getAllNewsletterSubscribers(
        userNumber,
        getAllNewsletterSubscribersParams
      ),
    options
  );
};
