import Pagination from "@mui/material/Pagination/Pagination";
import {FC} from "react";
import "./_muiPagination.scss";

interface muiPaginationProps {
    pagesNumber: number | undefined;
    page?: number;
    setPage?: (page: number) => void;
    handleRef?: () => void;
}

const MuiPagination: FC<muiPaginationProps> = ({
                                                   pagesNumber,
                                                   page,
                                                   setPage,
                                                   handleRef,
                                               }) => {
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        !!setPage && setPage(value);
        if (handleRef) {
            handleRef();
        }
    };
    return (
        <div className="muiPagination">
            <Pagination
                page={page}
                count={pagesNumber}
                variant="outlined"
                shape="rounded"
                hidePrevButton
                hideNextButton
                onChange={handleChange}
            />
        </div>
    );
};
export default MuiPagination;
