import SearchIcon from "@mui/icons-material/Search";
import {useState} from "react";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import {useGetAllFoldersQuery} from "../../react-query/hooks/queriesHooks/Folder";
import Button from "../../components/atoms/Button/Button.tsx";
import LoadingStatus from "../../components/organisms/LoadingStatus/LoadingStatus";
import Folder from "../../components/molecules/ListsItems/Folder";
import MuiPagination from "../../components/molecules/MuiPagination/MuiPagination";
import NoResults from "../../components/organisms/NoResults/NoResults";
import AddFolder from "../../modals/dashboard/AddFolder";
import MuiModal from "../../modals/MuiModal";
import {useUserClaimStore} from "../../zustand-store/UserClaim.store";
import {t} from "i18next";
import DataLayout from "../../components/atoms/DataLayout/DataLayout";
import { Box } from "@mui/material";

const FilesFolders = () => {
  const [modalShow, setModalShow] = useState(false);
  const [page, setPage] = useState(1);
  const [searchWord, setSearchWord] = useState<string>("");
  const handleModalShow = () => setModalShow(true);
  const { canRoleCreate, canRoleEdit, canRoleDelete } = useUserClaimStore();
  const { data: { data: folders, metaData } = {}, isLoading } =
    useGetAllFoldersQuery({
      pageNumber: String(page),
      searchWord: searchWord,
    });

  return (
    <>
      <DataLayout
        title={t("dashboard.reusable.folders")}
        options={
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            gap={2}
          >
            <TextField
              id="outlined-basic"
              label={t("dashboard.folders.search_folder")}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    style={{
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    <SearchIcon
                      style={{
                        padding: 0,
                        margin: 0,
                      }}
                      sx={{ color: "#a1a7b3" }}
                    />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                setSearchWord(e.target.value);
                setPage(1);
              }}
              variant="outlined"
              color="success"
            />
            <Button
              show={canRoleCreate}
              onClick={handleModalShow}
              content={`+ ${t("dashboard.folders.add_folder")}`}
              styleType="first"
              type="button"
            />
          </Box>
        }
      >
        {isLoading ? (
          <LoadingStatus />
        ) : (
          <>
            {metaData?.totalItemCount !== 0 ? (
              <Box
                position="relative"
                className="pagesStyle-tables scroll-group"
                minHeight={580}
                padding={2}
                boxShadow="0 0 1px rgba(0, 0, 0, 0.15)"
              >
                <Box
                  overflow="auto"
                  className="scroll-group-hover:no-scrollbar"
                >
                  <table>
                    <thead>
                      <tr className="headerItems no-hover">
                        <th className="firstItem">
                          {t("dashboard.folders.folder_name")}
                        </th>
                        <th
                          className={`${
                            !(canRoleEdit || canRoleDelete) && "lastItem"
                          }`}
                        >
                          {t("dashboard.folders.creation_date")}
                        </th>
                        {(canRoleEdit || canRoleDelete) && (
                          <th className="lastItem">
                            {t("dashboard.reusable.action")}
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {folders?.map((folder) => {
                        return (
                          <Folder
                            key={folder.id}
                            id={folder.id}
                            name={folder.name}
                            insertedDate={folder.insertedDate}
                            folderFileCount={folder.folderFileCount}
                          />
                        );
                      })}
                    </tbody>
                  </table>
                </Box>
                <Box position="absolute" bottom={0} padding={2} right={0}>
                  <MuiPagination
                    pagesNumber={metaData?.pageCount}
                    page={page}
                    setPage={setPage}
                  />
                </Box>
              </Box>
            ) : (
              <NoResults />
            )}
          </>
        )}
      </DataLayout>
      <MuiModal
        title={`${t("dashboard.folders.add_folder")}`}
        description={`${t("dashboard.folders.modals.write_the_name")}`}
        modalContent={<AddFolder setModalShow={setModalShow} />}
        modalShow={modalShow}
        setModalShow={setModalShow}
      />
    </>
  );
};
export default FilesFolders;
