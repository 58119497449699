import MenuItem from '@mui/material/MenuItem';
import QuizIcon from '@mui/icons-material/Quiz';
import { FC, useState } from 'react';
import MuiModal from '../../../modals/MuiModal';
import AddQuiz from '../../../modals/dashboard/AddQuiz';
import { useTranslation } from 'react-i18next';

interface AddQuizMenuItemProps {
  articleId: number;
  pageId: number;
  setAnchorEl: (value: null | HTMLElement) => void;
}

const AddQuizMenuItem: FC<AddQuizMenuItemProps> = ({
  articleId,
  pageId,
  setAnchorEl,
}) => {
  const { t } = useTranslation();
  const [modalShow, setModalShow] = useState(false);
  const handleModalShow = () => setModalShow(true);
  return (
    <div>
      <MenuItem
        onClick={handleModalShow}
        sx={{
          color: '#CCC',
          minWidth: '200px',
          '&:hover': {
            color: '#707070',
          },
        }}
      >
        <QuizIcon sx={{ marginRight: '10px' }} />
        {t('dashboard.magazine.pages.modals.add_quiz')}
      </MenuItem>
      <MuiModal
        setAnchorEl={setAnchorEl}
        title={t('dashboard.magazine.pages.modals.add_quiz')}
        description={t(
          'dashboard.magazine.pages.modals.quiz_will_show_on_that_page'
        )}
        modalContent={<AddQuiz setModalShow={setModalShow} pageId={pageId} />}
        modalShow={modalShow}
        setModalShow={setModalShow}
      />
    </div>
  );
};
export default AddQuizMenuItem;
