import { basicParamsTypes } from "../../interfaces/models/basicParams.model";
import axios from "../../initializations/axios";
import { sizeOfItemsPerPage } from "../../static/sizeOfItemsPerPage";

export const getAllClaimTypes = async () => {
  const { data } = await axios.get("Admin/GetAllClaimTypes");
  return data;
};
export const getAllRoles = async (searchWord: string) => {
  const { data } = await axios.get("Admin/GetAllRoles", {
    params: { searchWord: searchWord },
  });
  return data;
};
export const getRoleById = async (roleId: string) => {
  const { data } = await axios.get(`Admin/GetRoleById/${roleId}`);
  return data;
};
export const getAllUsers = async (getAllUsersParams: basicParamsTypes) => {
  const { data } = await axios.get("Admin/GetAllUsers", {
    params: {
      searchWord: getAllUsersParams.searchWord,
      pageSize: sizeOfItemsPerPage,
      pageNumber: getAllUsersParams.pageNumber,
    },
  });
  return data;
};
