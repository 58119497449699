import { joiResolver } from "@hookform/resolvers/joi";
import TextField from "@mui/material/TextField";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../../../components/atoms/Button/Button.tsx";
import StatusState from "../../../components/molecules/StatusState/StatusState";
import { CreateMagazineArticlePageInputModel } from "../../../interfaces/models/magazineModels/CreateMagazineArticlePage.model";
import { EditMagazineArticlePageTitleInputModel } from "../../../interfaces/models/magazineModels/EditMagazineArticlePageTitle.model";
import {
  usePostCreateMagazineArticlePage,
  usePutEditMagazineArticlePageTitle,
} from "../../../react-query/hooks/mutationHooks/Magazine";
import { EditMagazineArticlePageTitleSchema } from "../../../schemas/magazineSchemas/EditMagazineArticlePageTitle.schema";
import { closeModalWithDelay } from "../../../utils/closeModalWithDelay";
import { useGetTemplates } from "../../../react-query/hooks/queriesHooks/Magazine";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";

interface AddPageTitleProp {
  update?: boolean;
  articleId: number;
  pageId?: number;
  pageTitle?: string;
  pageNumber?: number;
  pagePath?: string;
  modalShow: boolean;
  setModalShow: (modalshow: boolean) => void;
}
const AddPageTitle: FC<AddPageTitleProp> = ({
  articleId,
  pageId,
  update,
  pageTitle,
  pageNumber,
  modalShow,
  pagePath,
  setModalShow,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<EditMagazineArticlePageTitleInputModel>({
    resolver: joiResolver(EditMagazineArticlePageTitleSchema),
    defaultValues: {
      title: pageTitle,
      // pagePath: pagePath,
    },
  });
  const {
    mutate: createArticlePage,
    isLoading: isPostLoading,
    isSuccess: isPostSuccess,
    reset: ResetPost,
  } = usePostCreateMagazineArticlePage(articleId);
  const {
    mutate: updateArticlePageTitle,
    isLoading: isPutLoading,
    isSuccess: isPutSuccess,
    reset: ResetEdit,
  } = usePutEditMagazineArticlePageTitle(pageId!);
  const [searchWord, setSearchWord] = useState<string>("");
  const { data: { data: templates } = {} } = useGetTemplates(searchWord);
  const [templateId, setTemplateId] = useState<number | null>(null);
  const handleAddPageTitleSubmit = (
    addPageTitlePayload: CreateMagazineArticlePageInputModel
  ) => {
    createArticlePage(
      {
        ...addPageTitlePayload,
        pageNumber: pageNumber! + 1,
        templateId: templateId,
      },
      {
        onSuccess: () => {
          closeModalWithDelay(setModalShow);
          reset();
          ResetEdit();
          ResetPost();
        },
      }
    );
  };
  const handleUpdatePageTitleSubmit = (
    updatePageTitlePayload: EditMagazineArticlePageTitleInputModel
  ) => {
    updateArticlePageTitle(
      { ...updatePageTitlePayload },
      {
        onSuccess: () => {
          closeModalWithDelay(setModalShow);
          reset();
          ResetEdit();
          ResetPost();
        },
      }
    );
  };
  const handleCancel = () => {
    reset();
    ResetEdit();
    ResetPost();
    setModalShow(false);
  };
  const theme = useTheme();
  return (
    <Modal open={modalShow} onClose={handleCancel} closeAfterTransition>
      <Fade in={modalShow}>
        <Box
          sx={{
            position: "absolute" as const,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{
              bgcolor: "white",
              padding: "20px 20px",
              borderRadius: "10px",
              minWidth: "370px",
              maxHeight: "calc(100vh - 50px)",
              [theme.breakpoints.up("sm")]: {
                padding: "25px 20px ",
                minWidth: "450px",
              },
              [theme.breakpoints.up("md")]: {
                padding: "30px 25px ",
                minWidth: "500px",
              },
              [theme.breakpoints.up("lg")]: {
                padding: "35px 30px ",
                minWidth: "780px",
              },
            }}
          >
            <form
              className="addPageTitle"
              onSubmit={handleSubmit(
                update ? handleUpdatePageTitleSubmit : handleAddPageTitleSubmit
              )}
            >
              <div className="muiModal-header">
                <h3>Page Title</h3>
                <p>Give a title to the new page created</p>
              </div>
              <div className="addMagazinePage">
                <TextField
                  className="muiModal-content-inputs-input"
                  fullWidth
                  id="outlined-basic"
                  label="Title"
                  color="success"
                  variant="outlined"
                  error={!!errors?.title}
                  helperText={errors.title?.message}
                  {...register("title")}
                />
                {!update && (
                  <div className="addMagazinePage-content">
                    <div className="addMagazinePage-content-header">
                      <p>Template</p>
                      <TextField
                        sx={{
                          maxWidth: "250px",
                        }}
                        fullWidth
                        id="outlined-basic"
                        label="Search"
                        color="success"
                        variant="outlined"
                        onChange={(e) => setSearchWord(e.target.value)}
                      />
                    </div>
                    <div className="addMagazinePage-content-templates">
                      <div
                        className={`templateItem ${
                          templateId === null ? "selected" : ""
                        }`}
                        onClick={() => setTemplateId(null)}
                      >
                        <div className="templateItem blankItem">
                          <InsertDriveFileOutlinedIcon
                            sx={{
                              fontSize: "49px",
                              color: "#B7CEC1",
                            }}
                          />
                        </div>
                        <p>Blank</p>
                      </div>
                      {templates?.map((template) => {
                        return (
                          <div
                            key={template.id}
                            className={`templateItem ${
                              templateId === template.id ? "selected" : ""
                            }`}
                            onClick={() => setTemplateId(template.id)}
                          >
                            <iframe
                              title={template.title}
                              src={template.filePath}
                            />
                            <p>{template.title}</p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
              <div className="muiModal-content-status">
                {isPostLoading && (
                  <StatusState
                    statusText="Adding Page..."
                    statusType="loading"
                  />
                )}
                {isPostSuccess && (
                  <StatusState
                    statusText="Page has been added successfully!"
                    statusType="success"
                  />
                )}
                {isPutLoading && (
                  <StatusState
                    statusText="Updating Page..."
                    statusType="loading"
                  />
                )}
                {isPutSuccess && (
                  <StatusState
                    statusText="Page has been updated successfully!"
                    statusType="success"
                  />
                )}
              </div>
              {!isPostLoading &&
                !isPostSuccess &&
                !isPutLoading &&
                !isPutSuccess && (
                  <div className="muiModal-content-modalbuttons">
                    <Button
                      onClick={handleCancel}
                      content="Cancel"
                      styleType="third"
                      type="button"
                    />
                    <Button content="Save" styleType="second" type="submit" />
                  </div>
                )}
            </form>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};
export default AddPageTitle;
