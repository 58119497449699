import "./_Statistics.scss"
import {useEffect, useState} from "react";
import {Autocomplete, Badge, Box, Paper, SelectChangeEvent, TextField, Typography,} from "@mui/material";
import Chart from "react-apexcharts";
import Select from "../../components/molecules/Select/Select";
import {useGetAllMagazinesQuery} from "../../react-query/hooks/queriesHooks/Magazine";
import {useGetMagazineStatisticsByIdQuery} from "../../react-query/hooks/queriesHooks/Statistics";
import {
    useGetMagazineQuizzesQuery,
    useGetMagazineQuizzesStatisticsQuery,
} from "../../react-query/hooks/queriesHooks/Quiz";
import LoadingStatus from "../../components/organisms/LoadingStatus/LoadingStatus";
import {useTranslation} from "react-i18next";

export const ReadingByArticles = () => {
    const {t} = useTranslation();
    //local state
    const [magazineId, setMagazineId] = useState<number | null>(null);
    const [quizId, setQuizId] = useState<number | null>(null);

    const [searchWord, setSearchWord] = useState<string>("");
    const [page, setPage] = useState<number>(1);

    //react-query
    const {
        data,
        refetch: refetchMagazineStatisticsById,
        isLoading: isLoadingMagazinesStatistics,
    } = useGetMagazineStatisticsByIdQuery(magazineId!);

    const {data: quizzes = [], refetch: refetchQuizzes} =
        useGetMagazineQuizzesQuery(magazineId! ?? data?.id);

    const {
        data: quizStatistics,
        refetch: refetchQuizStatistics,
        isFetching: isLoadingQuizStatistics,
    } = useGetMagazineQuizzesStatisticsQuery(magazineId! ?? data?.id, quizId!, {
        enabled: false,
    });

    const {data: {data: Magazines, metaData} = {}, isLoading} =
        useGetAllMagazinesQuery({pageNumber: String(page), searchWord});

    //handlers
    const handleSelect = (event: any, value: any) => {
        setMagazineId(value.id);
    };
    const handleSelectQuiz = (event: SelectChangeEvent<any>) => {
        setQuizId(event.target.value);
        refetchQuizStatistics();
    };

    //constants
    const magazinesArray =
        Magazines?.map((magazine) => ({
            id: magazine.id,
            name: magazine.title,
        })) ?? [];

    const mostReadedMagazineChart: any = data?.articleReadStatistics?.map(
        (magazine) => ({
            x: magazine.title,
            y: magazine.readCount,
        })
    );

    const defaultMagazine = {id: data?.id, name: data?.title || ""};

    const selectQuizzes = quizzes.map((quiz) => ({
        id: quiz.quizId,
        name: quiz.question,
    }));

    //effects
    useEffect(() => {
        if (magazineId || data?.id) {
            refetchMagazineStatisticsById();
            refetchQuizzes();
        }
    }, [magazineId, data?.id]);

    useEffect(() => {
        if (quizId) {
            refetchQuizStatistics();
        }
    }, [quizId]);

    if (isLoadingMagazinesStatistics) return <LoadingStatus/>;

    return (
        <Box display="flex" flexDirection="column">
            <Box mt={2}>
                <Autocomplete
                    id="disable-clearable"
                    defaultValue={defaultMagazine}
                    options={magazinesArray}
                    getOptionLabel={(option) => option.name}
                    onChange={handleSelect}
                    sx={{alignContent: "end", width: 320}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            onChange={(e) => setSearchWord(e.target.value)}
                            label={t("dashboard.statistics.search_magazine")}
                            InputProps={{
                                ...params.InputProps,
                                type: "search",
                                style: {
                                    paddingRight: 50,
                                    paddingTop: 5,
                                    paddingBottom: 5,
                                },
                                className: 'read-mag-box'
                            }}
                            className="disable-clear-icon"
                        />
                    )}
                />
            </Box>
            <Box display="flex" gap={4} mt={2} paddingBottom={4} className="flex-wrapper ">
                <Box component={Paper} p={2} boxShadow="0 0 1px rgba(0, 0, 0, 0.15)" className="w-1/2">
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography fontSize="14px" fontWeight="bold">
                            {t("dashboard.statistics.reading_by_articles")}
                        </Typography>
                        <Typography fontSize="12px">
                            <Badge variant="dot" color="warning" sx={{mr: 1}}/>{" "}
                            {t("dashboard.statistics.number_of_readings")}
                        </Typography>
                    </Box>
                    <Chart
                        type="bar"
                        height={250}
                        series={[
                            {
                                data: mostReadedMagazineChart,
                            },
                        ]}
                        options={{
                            colors: ["#FF832C"],
                            dataLabels: {
                                enabled: true,
                            },
                            noData: {text: `${t("dashboard.statistics.no_data")}`},
                            plotOptions: {
                                bar: {
                                    borderRadius: 4,
                                    horizontal: true,
                                },
                            },
                        }}
                    />
                </Box>
                <Box
                    component={Paper}
                    display="flex"
                    flexDirection="column"
                    p={2}
                    boxShadow="0 0 1px rgba(0, 0, 0, 0.15)"
                    className="w-1/2"
                >
                    <Typography fontSize="14px" fontWeight="bold" mb={2}>
                        {t("dashboard.statistics.quiz_results")}
                    </Typography>

                    <Select
                        options={selectQuizzes}
                        placeholder="Select a quiz"
                        onChange={handleSelectQuiz}
                        defaultValue={data?.quizStatistics?.quizId}
                    />
                    {isLoadingQuizStatistics ? (
                        <LoadingStatus/>
                    ) : (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 20,
                                justifyContent: "center",
                                marginTop: 20,
                            }}
                        >
                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                <p
                                    style={{fontSize: "12px", color: "rgba(125, 135, 156, 1)"}}
                                >
                                    {t("dashboard.statistics.right_answers")}
                                </p>
                                <p style={{color: "rgba(125, 135, 156, 1)"}}>
                                    {quizStatistics?.rightAnswers ??
                                        data?.quizStatistics?.rightAnswers ??
                                        "-"}
                                </p>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                <p
                                    style={{fontSize: "12px", color: "rgba(125, 135, 156, 1)"}}
                                >
                                    {t("dashboard.statistics.wrong_answers")}
                                </p>
                                <p style={{color: "rgba(125, 135, 156, 1)"}}>
                                    {quizStatistics?.wrongAnswers ??
                                        data?.quizStatistics?.wrongAnswers ??
                                        "-"}
                                </p>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                <p
                                    style={{fontSize: "12px", color: "rgba(125, 135, 156, 1)"}}
                                >
                                    {t("dashboard.statistics.total_answers")}
                                </p>
                                <p style={{color: "rgba(125, 135, 156, 1)"}}>
                                    {quizStatistics?.totalAnswers ??
                                        data?.quizStatistics?.totalAnswers ??
                                        "-"}
                                </p>
                            </div>
                        </div>
                    )}
                </Box>
            </Box>
        </Box>
    );
};
