import {NavLink} from "react-router-dom";
import {FC} from "react";
import {MagzineResponseModel} from "../../../interfaces/models/magazineModels/MagazineResponse.model";
import {useTranslation} from "react-i18next";

const MagazineHomeLinkCard: FC<MagzineResponseModel> = ({
                                                            id,
                                                            insertedDate,
                                                            cover,
                                                            editorialNumber,
                                                            description,
                                                            tags,
                                                            title,
                                                            hasQuizes,
                                                            magazinePath,
                                                        }) => {
    const {t} = useTranslation();

    return (
        <>
            {magazinePath !== null ? (
                <div className="card">
                    <img src={cover} alt="magazine cover sample"/>
                    <div className="card-content">
                        <h5>{title}</h5>
                        <p>{description}</p>
                        <NavLink to="/">{t("public.home.section1.read_magazine")}</NavLink>
                    </div>
                </div>
            ) : (
                <div className="card">
                    <img src={cover} alt={`${title} magazine cover`}/>
                    <div className="card-content">
                        <div className="card-h-d">
                            <h5>{title.trim()}</h5>
                            <p>{description.trim()}</p>
                        </div>
                        <NavLink to={`magazines/${id}`}>
                            {t("public.home.section1.read_magazine")}
                        </NavLink>
                    </div>
                </div>
            )}
        </>
    );
};

export default MagazineHomeLinkCard;