import "./_Magazine.scss";
import { useGetReadMagazineById } from "../../react-query/hooks/queriesHooks/Magazine";
import {  useParams } from "react-router-dom";
import questionMark from "../../assets/icons/questionMark.png";
import { useEffect, useState } from "react";
import { PageResponseModel } from "../../interfaces/models/magazineModels/PageResponse.model";
import UserQuiz from "../../modals/dashboard/UserQuiz/UserQuiz";
import LoadingStatus from "../../components/organisms/LoadingStatus/LoadingStatus";
import BestSwiper from "../../components/molecules/BestSwiper/BestSwiper";
import IFrame, { useFrame } from "@uiw/react-iframe";

const Magazine = () => {
  const { magazineId } = useParams();
  const [selectedPage, setSelectedPage] = useState<number>(0);
  const [allPages, setAllPages] = useState<PageResponseModel[]>();
  const [hasPageQuiz, setHasPageQuiz] = useState<boolean>(false);
  const [isPopUpQuiz, setIsPopUpQuiz] = useState<boolean>(false);
  const [quizModalShow, setQuizModalShow] = useState<boolean>(false);
  const [quizId, setQuizId] = useState<number>();

  const { data: { data: magazine } = {}, isLoading } = useGetReadMagazineById(
    Number(magazineId)
  );

  const createPagesArray = () => {
    const pagesArray: PageResponseModel[] =
      magazine?.article
        ?.map((article) => {
          return article.pages;
        })
        ?.flat() ?? [];
    setAllPages(pagesArray);
    setQuizId(pagesArray[selectedPage]?.quiz?.quizId);
    setHasPageQuiz(pagesArray[selectedPage]?.quiz !== null);
    setIsPopUpQuiz(!!pagesArray[selectedPage]?.quiz?.autoShowQuiz);
    setQuizModalShow(!!pagesArray[selectedPage]?.quiz?.autoShowQuiz);
  };

  const handleQuizShow = () => {
    setQuizModalShow(true);
  };

  useEffect(() => {
    createPagesArray();
  }, [selectedPage, magazine]);

  const { document: iframeDocument } = useFrame();

  useEffect(() => {
    if (iframeDocument) {
      const link = iframeDocument.createElement("link");
      link.href =
        "https://fonts.googleapis.com/css?family=Advent+Pro|Amatic+SC|Architects+Daughter|Bangers|Bebas+Neue|Bungee|Cabin+Sketch|Carter+One|Concert+One|Courier+Prime|Exo+2|Handlee|Instrument+Sans|Kalam|Lato|Lilita+One|Luckiest+Guy|Madimi+One|Montserrat|Open+Sans|Oswald|Passion+One|Patrick+Hand|Permanent+Marker|Poppins|PT+Sans|PT+Sans+Caption|Roboto|Roboto+Condensed|Russo+One|Sacramento|Secular+One|Sen|Shadows+Into+Light|Special+Elite|Staatliches|Titan+One|Varela+Round&display=swap";
      link.rel = "stylesheet";
      iframeDocument.head.appendChild(link);
    }
  }, [iframeDocument]);

  if (isLoading) return <LoadingStatus />;

  return (
    <div className="magazine">
      <div className="iframe-container">
        <IFrame
          src={allPages && allPages[selectedPage]?.pagePath}
          title={allPages && allPages[selectedPage]?.pagePath}
          style={{
            width: '100%',
            border: '0',
            height: '100vh',
          }}
        />
      </div>
      <BestSwiper
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
        allPages={allPages}
      />
      {!isPopUpQuiz && hasPageQuiz && (
        <button
          className="quizIcon interactive-button ghost-button interactive-cursor"
          onClick={handleQuizShow}
        >
          <img src={questionMark} alt="Questionmark icon"/>
        </button>
      )}
      <UserQuiz
        setModalShow={setQuizModalShow}
        modalShow={quizId !== undefined ? quizModalShow : false}
        quizId={quizId!}
        magazineId={Number(magazineId)}
      />

    </div>
  );
};
export default Magazine;
