import { AnswerQuizInputModel } from '../../interfaces/models/quizModels/AnswerQuiz.model';
import { CreateQuizInputModel } from '../../interfaces/models/quizModels/CreateQuiz.model';
import { EditQuizInputModel } from '../../interfaces/models/quizModels/EditQuiz.model';
import axios from '../../initializations/axios';

//-----POST MUTATION-----
export const postCreateQuiz = async (
  createQuizPayload: CreateQuizInputModel
): Promise<any> => {
  const { data } = await axios.post(`Quiz/CreateQuiz`, createQuizPayload);
  return data;
};
export const postAnswerQuiz = async (
  quizId: number,
  answerQuizPayload: AnswerQuizInputModel
): Promise<any> => {
  const { data } = await axios.post(
    `Quiz/AnswerQuiz/${quizId}`,
    answerQuizPayload
  );
  return data;
};
//-----PUT MUTATION-----
export const putEditQuiz = async (
  quizId: number,
  editQuizPayload: EditQuizInputModel
): Promise<any> => {
  const { data } = await axios.put(`Quiz/EditQuiz/${quizId}`, editQuizPayload);
  return data;
};
//-----DELETE MUTATION-----
export const deleteDeleteQuiz = async (quizId: number): Promise<any> => {
  const { data } = await axios.delete(`Quiz/DeleteQuiz/${quizId}`);
  return data;
};
