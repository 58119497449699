import {useEffect, useRef, useState} from 'react';
import "./_Home.scss";
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import Header from "../../partials/Header/Header";
import PatroMagazines from "../../components/organisms/PatroMagazines/PatroMagazines";
import Footer from "../../partials/Footer/Footer";
import {useGetTwoMostViewedMagazines} from "../../react-query/hooks/queriesHooks/Magazine";
import MagazineHomeLinkCard from "../../components/molecules/MagazineHomeLinkCard/MagazineHomeLinkCard";
import useDraggableScroll from '../../hooks/useDraggableScroll';

const Home = () => {
    const {t} = useTranslation();
    const magsRef = useRef<HTMLDivElement | null>(null);
    const photoArray = [0, 1, 2];
    const [page, setPage] = useState<number>(1);
    const [currentImage, setCurrentImage] = useState(0);
    const {data: {data: TopViewedMagazines} = {}} = useGetTwoMostViewedMagazines();

    const cardsRef = useRef<HTMLDivElement>(null);
    useDraggableScroll(cardsRef);

    const handleMagsRef = () => magsRef.current?.scrollIntoView({
        behavior: 'smooth'
    })


    useEffect(() => {
        const intervalId = setInterval(() => {
            if (currentImage === photoArray.length - 1) {
                setCurrentImage(0);
            } else {
                setCurrentImage(currentImage + 1);
            }
        }, 8000);

        return () => clearInterval(intervalId);
    }, [currentImage]);

    return (
        <>
            <Header/>
            <div>
                <div className={`homeSection1 slideBg-${currentImage}`}>
                    <div className="homeSection1-content ">
                        <div className="part1 userPagesScreen">
                            <div className="homeSection1-content-part1">
                                <h1>
                                    {t("public.home.section1.title_1")}
                                    <span style={{color: "#a4c408"}}>
                    {` «${t("public.home.section1.lepatro")}»`}
                                     </span>
                                </h1>
                                <p>{t("public.home.section1.body_1")}</p>
                            </div>
                        </div>
                        <div className="part2">
                            <div className="homeSection1-content-part2">
                                <h4>{t("public.home.section1.top_one")}</h4>
                                <div className="cards" ref={cardsRef}>
                                    {TopViewedMagazines?.map((magazine) => (
                                        <MagazineHomeLinkCard
                                            key={magazine.id}
                                            id={magazine.id}
                                            cover={magazine.cover}
                                            description={magazine.description}
                                            editorialNumber={magazine.editorialNumber}
                                            tags={magazine.tags}
                                            insertedDate={magazine.insertedDate}
                                            title={magazine.title}
                                            isPublished={magazine.isPublished}
                                            magazinePath={magazine.magazinePath}
                                            hasQuizes={magazine.hasQuizes}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="lg"/>
                </div>
                <div className="homeSection2 userPagesScreen" ref={magsRef}>
                    <div className="homeSection2-header">
                        <h3>{t("public.home.section2.our_magazines")}</h3>
                        <NavLink to="magazines">
                            {t("public.home.section2.see_more")}
                        </NavLink>
                    </div>
                    <PatroMagazines
                        isInDashboard={false}
                        pageNumber={String(page)}
                        searchWord=""
                        page={page}
                        setPage={setPage}
                        handleMags={handleMagsRef}
                    />
                </div>
            </div>
            <Footer/>
        </>
    );
};

export default Home;
