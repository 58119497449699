import { basicParamsTypes } from "../../interfaces/models/basicParams.model";
import axios from "../../initializations/axios";
import { sizeOfItemsPerPage } from "../../static/sizeOfItemsPerPage";

export const getMagazineBasicInformationById = async (magazineId: number) => {
  const { data } = await axios.get(
    `Magazine/getMagazineBasicInformationById/${magazineId}`
  );
  return data;
};
export const getAllMagazines = async (
  getAllMagazinesParams?: basicParamsTypes
) => {
  if (getAllMagazinesParams?.searchWord === "")
    delete getAllMagazinesParams?.searchWord;
  const { data } = await axios.get("Magazine/GetAllMagazines", {
    params: {
      searchWord: getAllMagazinesParams?.searchWord,
      pageSize: sizeOfItemsPerPage,
      pageNumber: getAllMagazinesParams?.pageNumber,
    },
  });
  return data;
};
export const getAllPublishedMagazines = async (
  getAllMagazinesParams?: basicParamsTypes
) => {
  if (getAllMagazinesParams?.searchWord === "")
    delete getAllMagazinesParams?.searchWord;
  const { data } = await axios.get("Magazine/GetAllPublishedMagazines", {
    params: {
      searchWord: getAllMagazinesParams?.searchWord,
      pageSize: sizeOfItemsPerPage,
      pageNumber: getAllMagazinesParams?.pageNumber,
    },
  });
  return data;
};
export const getReadMagazine = async (magazineId: number) => {
  const { data } = await axios.get(`Magazine/ReadMagazine/${magazineId}`);
  return data;
};

export const getAllMagazineArticles = async (magazineId: number) => {
  const { data } = await axios.get(
    `magazine/getAllMagazineArticles/${magazineId}`
  );
  return data;
};

export const getAllMagazineArticlesPages = async (
  magazineArticleId: number
) => {
  const { data } = await axios.get(
    `Magazine/GetAllMagazineArticlePages/${magazineArticleId}`
  );
  return data;
};

export const getTwoMostViewedMagazine = async () => {
  const { data } = await axios.get("Magazine/GetTwoMostViewedMagazines");
  return data;
};

export const getTemplates = async (searchWord: string) => {
  const { data } = await axios.get("Template", {
    params: {
      searchWord: searchWord,
    },
  });
  return data;
};
export const getTemplate = async (templateId: number) => {
  const { data } = await axios.get(`Template/${templateId}`);
  return data;
};

// export const getMagazineArticlesPageById = async (
//   magazineArticlePageId: number
// ) => {
//   const { data } = await axios.get(
//     `magazine/getMagazineArticlePageById/${magazineArticlePageId}`
//   );
//   return data;
// };

export const getMagazineArticlesPageById = async (
  magazineArticlePageId: number
) => {
  const { data } = await axios.get(
    `Magazine/GetMagazineArticlePageByIdForEdit/${magazineArticlePageId}`
  );
  return data;
};
