import "./_Files.scss";
import SearchIcon from "@mui/icons-material/Search";
import {useState} from "react";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import {useParams} from "react-router-dom";
import {useGetAllFolderFilesQuery} from "../../react-query/hooks/queriesHooks/Folder";
import ServerErrorStatus from "../../components/organisms/ServerErrorStatus/ServerErrorStatus";
import {isServerError} from "../../utils/isServerError";
import Button from "../../components/atoms/Button/Button.tsx";
import LoadingStatus from "../../components/organisms/LoadingStatus/LoadingStatus";
import MuiPagination from "../../components/molecules/MuiPagination/MuiPagination";
import NoResults from "../../components/organisms/NoResults/NoResults";
import MuiModal from "../../modals/MuiModal";
import AddFile from "../../modals/dashboard/AddFile";
import File from "../../components/molecules/ListsItems/File";
import {useUserClaimStore} from "../../zustand-store/UserClaim.store";
import {useTranslation} from "react-i18next";
import DataLayout from "../../components/atoms/DataLayout/DataLayout";
import {Box} from "@mui/material";

const Files = () => {
  const {t} = useTranslation();
  const {folderId} = useParams();
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [searchWord, setSearchWord] = useState<string>("");
  const handleModalShow = () => setModalShow(true);
  const [page, setPage] = useState(1);
  const {canRoleCreate, canRoleEdit, canRoleDelete} = useUserClaimStore();

  const {data: {data: files, status, metaData} = {}, isLoading} =
    useGetAllFolderFilesQuery(Number(folderId), {
      pageNumber: String(page),
      searchWord: searchWord,
    });

  if (isServerError(status)) return <ServerErrorStatus/>;
  return (
    <>
      <DataLayout title={t("dashboard.files.files")} options={
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" gap={2}>
          <TextField
            id="outlined-basic"
            label={t("dashboard.files.search_file")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" style={{
                  padding: 0,
                  margin: 0,
                }}>
                  <SearchIcon sx={{color: "#a1a7b3"}}/>
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setSearchWord(e.target.value);
              setPage(1);
            }}
            variant="outlined"
            color="success"
          />
          <Button
            show={canRoleCreate}
            onClick={handleModalShow}
            content={`+ ${t("dashboard.files.add_file")}`}
            styleType="first"
            type="button"
          />
        </Box>
      }>
        {isLoading && <LoadingStatus/>}
        {metaData?.totalItemCount !== 0 ? (
          <>
            <Box position="relative" className="pagesStyle-tables scroll-group" minHeight={530} padding={2}
                 boxShadow="0 0 1px rgba(0, 0, 0, 0.15)">
              <Box overflow="auto" className="scroll-group-hover:no-scrollbar">
                <table>
                  <thead>
                  <tr className="no-hover">
                    <th className="firstItem">
                      {t("dashboard.reusable.file_name")}
                    </th>
                    <th> {t("dashboard.reusable.date")}</th>
                    <th
                      className={`${
                        !(canRoleEdit || canRoleDelete) ? "lastItem" : ""
                      }`}
                    >
                      {t("dashboard.reusable.file_type")}
                    </th>
                    {(canRoleEdit || canRoleDelete) && (
                      <th className="lastItem">
                        {t("dashboard.reusable.action")}
                      </th>
                    )}
                  </tr>
                  </thead>
                  <tbody>
                  {files?.map((file) => {
                    return (
                      <File
                        key={file.id}
                        folderId={file.folderId}
                        fileId={file.id}
                        fileName={file.name}
                        filePath={file.filePath}
                        date={file.insertedDate}
                      />
                    );
                  })}
                  </tbody>
                </table>
              </Box>
              <Box position="absolute" bottom={0} padding={2} right={0}>
                <MuiPagination
                  pagesNumber={metaData?.pageCount}
                  page={page}
                  setPage={setPage}
                />
              </Box>
            </Box>
          </>
        ) : (
          <NoResults/>
        )}
      </DataLayout>
      <MuiModal
        title={t("dashboard.files.add_file")}
        description={t("dashboard.files.modals.write_name_and_upload")}
        modalContent={
          <AddFile folderId={Number(folderId)} setModalShow={setModalShow}/>
        }
        modalShow={modalShow}
        setModalShow={setModalShow}
      />
    </>
  );
};
export default Files;
