import { FC } from "react";
import Button from "../../components/atoms/Button/Button.tsx";
import StatusState from "../../components/molecules/StatusState/StatusState";
import { useTranslation } from "react-i18next";

interface DeleteProps {
  isLoading?: boolean;
  isSuccess?: boolean;
  deleteFunction: () => void;
  setModalShow: (modalshow: boolean) => void;
}
const Delete: FC<DeleteProps> = ({
  isLoading,
  isSuccess,
  deleteFunction,
  setModalShow,
}) => {
  const { t } = useTranslation();
  const handleCancel = () => {
    setModalShow(false);
  };
  return (
    <div className="muiModal-content">
      <div className="muiModal-content-status">
        {isLoading && (
          <StatusState statusText="Deleting..." statusType="loading" />
        )}
      </div>
      {!isLoading && !isSuccess && (
        <div className="muiModal-content-modalbuttons">
          <Button
            onClick={handleCancel}
            content={t("dashboard.reusable.cancel")}
            styleType="fifth"
            type="button"
          />
          <Button
            onClick={deleteFunction}
            content={t("dashboard.reusable.delete")}
            styleType="sixth"
            type="button"
          />
        </div>
      )}
    </div>
  );
};
export default Delete;
