import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import App from "./App";
import { Suspense } from "react";
import "./styles/main.scss";
import LoadingStatus from "./components/organisms/LoadingStatus/LoadingStatus";
import { I18nextProvider } from "react-i18next";
import i18n from "./initializations/i18n/config";

const queryClient = new QueryClient();
ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<LoadingStatus />}>
      <QueryClientProvider client={queryClient}>
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />
      </QueryClientProvider>
    </Suspense>
  </React.StrictMode>,

  document.getElementById("root")
);
