import {useEffect} from 'react';

/**
 * @param containerRef
 * This hook is used specifically used for scrolling like in a carousel but with mouse grab
 */
const useDraggableScroll = (containerRef: React.RefObject<HTMLDivElement>) => {
    useEffect(() => {
        const container = containerRef.current;
        if (!container) return;

        let isDragging = false;
        let startX = 0;
        let scrollLeft = 0;

        const handleMouseDown = (e: MouseEvent) => {
            e.preventDefault();
            isDragging = true;
            startX = e.pageX - container.offsetLeft;
            scrollLeft = container.scrollLeft;
            container.style.cursor = 'grabbing';
            container.classList.add('dragging');
        };

        const handleMouseLeave = () => {
            if (isDragging) {
                isDragging = false;
                container.style.cursor = 'default';
                container.classList.remove('dragging');
            }
        };

        const handleMouseUp = () => {
            if (isDragging) {
                isDragging = false;
                container.style.cursor = 'default';
                container.classList.remove('dragging');
            }
        };

        const handleMouseMove = (e: MouseEvent) => {
            if (!isDragging) return;
            e.preventDefault();
            const x = e.pageX - container.offsetLeft;
            const walk = (x - startX);
            container.scrollLeft = scrollLeft - walk;
        };

        container.addEventListener('mousedown', handleMouseDown);
        container.addEventListener('mouseleave', handleMouseLeave);
        container.addEventListener('mouseup', handleMouseUp);
        container.addEventListener('mousemove', handleMouseMove);

        return () => {
            container.removeEventListener('mousedown', handleMouseDown);
            container.removeEventListener('mouseleave', handleMouseLeave);
            container.removeEventListener('mouseup', handleMouseUp);
            container.removeEventListener('mousemove', handleMouseMove);
        };
    }, [containerRef]);
};

export default useDraggableScroll;
