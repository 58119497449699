import {Badge, Box, Paper, Typography} from "@mui/material";
import "./_Dashboard.scss";
import Chart from "react-apexcharts";
import {useGetDashboardDataQuery} from "../../react-query/hooks/queriesHooks/Statistics";
import LoadingStatus from "../../components/organisms/LoadingStatus/LoadingStatus";
import {useTranslation} from "react-i18next";

const Dashboard = () => {
  const {t} = useTranslation();
  //react-query
  const {data, isLoading} = useGetDashboardDataQuery();

  //constants
  const mostReadedMagazineChart: any = data?.mostReadMagazines.map(
    (magazine) => ({
      x: magazine.magazineTitle,
      y: magazine.readCount,
    })
  );

  const readAllMagazineStatistics =
    data?.latestMagazineOverview?.readStatistics;
  const quizStatistics = data?.latestMagazineOverview?.quizStatistics;

  if (isLoading) return <LoadingStatus/>;

  return (
    <Box display="flex" flexDirection="column" gap={12} mt={2.5}>
      <Box>
        <div style={{display: "flex", alignItems: "baseline"}}>
          <h2 className="pagesStyle-title">{t("dashboard.home.overview")}</h2>
          <p style={{fontSize: "12px", marginLeft: "5px"}}>
            ({t("dashboard.home.latest_magazine")} - {data?.title})
          </p>
        </div>
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          gap={2}
          height="100%"
        >
          <Box
            component={Paper}
            width="100%"
            boxShadow="0 0 1px rgba(0, 0, 0, 0.15)"
          >
            <Box display="flex" alignItems="center" gap={2} padding={0.5}>
              {readAllMagazineStatistics && (
                <Chart
                  type="radialBar"
                  width="100px"
                  height={150}
                  series={[
                    readAllMagazineStatistics.percentageOfReadToTheEnd ?? 0,
                  ]}
                  options={{
                    colors: ["#FBB627"],
                    dataLabels: {
                      enabled: false,
                    },
                    plotOptions: {
                      radialBar: {
                        hollow: {size: "60%"},
                        dataLabels: {
                          name: {show: false},
                          value: {offsetY: 5},
                        },
                      },
                    },
                    responsive: [
                      {
                        breakpoint: 768,
                        options: {
                          chart: {height: 120, width: "auto"},
                          plotOptions: {
                            radialBar: {
                              hollow: {size: "50%"},
                              dataLabels: {
                                value: {fontSize: "10px"},
                              },
                            },
                          },
                        },
                      },
                    ],
                  }}
                />
              )}
              <Box>
                <Typography
                  fontSize="12px"
                  sx={{color: "rgba(112, 112, 112, 1)"}}
                >
                  {t("dashboard.home.read_all_magazine")}
                </Typography>
                <Typography
                  fontSize="12px"
                  sx={{color: "rgba(112, 112, 112, 1)"}}
                >
                  {readAllMagazineStatistics?.readToTheEnd ?? "-"}/
                  {readAllMagazineStatistics?.totalViews ?? "-"}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            component={Paper}
            width="100%"
            boxShadow="0 0 1px rgba(0, 0, 0, 0.15)"
          >
            <Box display="flex" alignItems="center" gap={2} padding={0.5}>
              {quizStatistics && (
                <Chart
                  type="radialBar"
                  width="100px"
                  height={150}
                  series={[quizStatistics?.percentageOfCorrectAnswers ?? 0]}
                  options={{
                    colors: ["#FBB627"],
                    dataLabels: {
                      enabled: false,
                    },
                    plotOptions: {
                      radialBar: {
                        hollow: {size: "60%"},
                        dataLabels: {
                          name: {show: false},
                          value: {offsetY: 5},
                        },
                      },
                    },
                    responsive: [
                      {
                        breakpoint: 768,
                        options: {
                          chart: {height: 120, width: "auto"},
                          plotOptions: {
                            radialBar: {
                              hollow: {size: "50%"},
                              dataLabels: {
                                value: {fontSize: "10px"},
                              },
                            },
                          },
                        },
                      },
                    ],
                  }}
                />
              )}
              <Box>
                <Typography
                  fontSize="12px"
                  sx={{color: "rgba(112, 112, 112, 1)"}}
                >
                  {t("dashboard.home.right_answers_on_quizz")}
                </Typography>
                <Typography
                  fontSize="12px"
                  sx={{color: "rgba(112, 112, 112, 1)"}}
                >
                  {quizStatistics?.rightAnswers ?? "-"}/
                  {quizStatistics?.totalAnswers ?? "-"}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* Most readed magazine */}
        <Box mt={2}>
          <h2 className="pagesStyle-title">
            {t("dashboard.home.most_readed_magazine")}
          </h2>
          <Box
            component={Paper}
            p={2.5}
            boxShadow="0 0 1px rgba(0, 0, 0, 0.15)"
          >
            <Typography fontSize="12px" textAlign="end" mb={2}>
              <Badge variant="dot" color="warning" sx={{mr: 1}}/>
              {t("dashboard.home.number_of_readings")}
            </Typography>
            <Chart
              type="bar"
              height={200}
              series={[
                {
                  name: "Reading times",
                  data: mostReadedMagazineChart,
                },
              ]}
              options={{
                colors: ["#FF832C"],
                dataLabels: {
                  enabled: true,
                },
                plotOptions: {
                  bar: {
                    borderRadius: 4,
                    horizontal: true,
                  },
                },
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
