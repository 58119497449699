// import "./_Newsletters.scss";
import SearchIcon from "@mui/icons-material/Search";
import {useState} from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import {
    useAllNewsletterSubscribersQuery,
    useGetAllNewsletterSubscribersForExelQuery,
} from "../../react-query/hooks/queriesHooks/NewsLetter";
import Button from "../../components/atoms/Button/Button.tsx";
import Subscriber from "../../components/molecules/ListsItems/Subscriber";
import MuiPagination from "../../components/molecules/MuiPagination/MuiPagination";
import NoResults from "../../components/organisms/NoResults/NoResults";
import {convertDateFormat} from "../../utils/covertDateFormat";
import {CSVLink} from "react-csv";
import {useTranslation} from "react-i18next";
import {Box} from "@mui/material";
import DataLayout from "../../components/atoms/DataLayout/DataLayout";

const Newsletters = () => {
    const {t} = useTranslation();
    const [page, setPage] = useState(1);
    const [searchWord, setSearchWord] = useState<string>("");
    const {data: {data: subscribers, metaData} = {}, isLoading} =
        useAllNewsletterSubscribersQuery(6, {
            pageNumber: String(page),
            searchWord: searchWord,
        });

    const {data: {data: allSubscribers} = {}} =
        useGetAllNewsletterSubscribersForExelQuery(9999999999, {
            pageNumber: "1",
            searchWord: "",
        });

    const csvData = [
        ["Full Name", "Email", "Date"],
        ...(allSubscribers?.map(({fullName, email, date}) => {
            return [fullName, email, convertDateFormat(date).date];
        }) || []),
    ];
    return (
        <DataLayout title={t("dashboard.newsletters.list_of_newsletters")} options={
            <>
                <TextField
                    id="outlined-basic"
                    label={t("dashboard.newsletters.search_newsletters")}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon sx={{color: "#a1a7b3"}}/>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => {
                        setSearchWord(e.target.value);
                        setPage(1);
                    }}
                    variant="outlined"
                    color="success"
                />
                <CSVLink data={csvData} filename="list-of-newsletters">
                    <Button
                        content={t("dashboard.newsletters.export_list")}
                        styleType="second"
                        type="button"
                    />
                </CSVLink>
            </>
        }>
            {metaData?.totalItemCount !== 0 ? (
                <Box position="relative" className="pagesStyle-tables scroll-group" minHeight={465} padding={2}
                     boxShadow="0 0 1px rgba(0, 0, 0, 0.15)">
                    <Box overflow="auto" className="scroll-group-hover:no-scrollbar">
                        <table>
                            <thead>
                            <tr className="no-hover">
                                <th className="firstItem no-hover">
                                    {t("dashboard.users.name_of_users")}
                                </th>
                                <th>{t("reusable.email")}</th>
                                <th className="lastItem no-hover">
                                    {t("dashboard.reusable.date")}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {subscribers?.map((subscriber, index) => {
                                return (
                                    <Subscriber
                                        key={index}
                                        fullName={subscriber.fullName}
                                        email={subscriber.email}
                                        date={subscriber.date}
                                    />
                                );
                            })}
                            </tbody>
                        </table>
                    </Box>
                    <Box position="absolute" bottom={0} padding={2} right={0}>
                        <MuiPagination
                            pagesNumber={metaData?.pageCount}
                            page={page}
                            setPage={setPage}
                        />
                    </Box>
                </Box>
            ) : (
                <NoResults/>
            )}
        </DataLayout>
    );
};
export default Newsletters;
