import "./_ListItems.scss";
import { FC } from "react";
import { SubscriberResponseModel } from "../../../interfaces/models/newsletterModels/Subscriber.model";
import { convertDateFormat } from "../../../utils/covertDateFormat";

const Subscriber: FC<SubscriberResponseModel> = ({ fullName, email, date }) => {
  return (
    <tr className="cursor-default">
      <td className="firstItem">
        <p className="capitalize">{fullName}</p>
      </td>
      <td>
        <p>{email}</p>
      </td>
      <td
        style={{
          paddingRight: 8,
          textAlign: "right",
        }}
      >
        <p>{convertDateFormat(date).date}</p>
      </td>
    </tr>
  );
};

export default Subscriber;
