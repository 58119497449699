import "./_LoginPasswordOrganisms.scss";
import TextField from "@mui/material/TextField";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { useEffect } from "react";
import { useErrorsStore } from "../../../zustand-store/Errors.store";
import { useGeneralStore } from "../../../zustand-store/General.store";
import { SignInInputModel } from "../../../interfaces/models/accountModels/SignIn.model";
import { SignInSchema } from "../../../schemas/accountSchemas/SignIn.schema";
import { usePostSignIn } from "../../../react-query/hooks/mutationHooks/Account";
import StatusState from "../../molecules/StatusState/StatusState";
import Button from "../../atoms/Button/Button.tsx";
import { useTranslation } from "react-i18next";

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { errors: serverResponseErrors, hookName } = useErrorsStore();
  const { email } = useGeneralStore();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInInputModel>({
    resolver: joiResolver(SignInSchema(t)),
    defaultValues: {
      email: email,
    },
  });
  const {
    mutateAsync: signIn,
    data: { isSuccess: signIsSuccess } = {},
    isLoading,
    isSuccess,
  } = usePostSignIn();

  const handleSignInSubmit = (signInPayload: SignInInputModel) => {
    signIn(signInPayload);
  };

  useEffect(() => {
    if (signIsSuccess) {
      navigate("/dashboard", { replace: true });
    }
  }, [isSuccess]);
  return (
    <form
      className="loginPasswordOrganisms"
      onSubmit={handleSubmit(handleSignInSubmit)}
    >
      <div className="loginPasswordOrganisms-header">
        <h1>{t("public.login.title")}</h1>
        <p>{t("public.login.subtitle")}</p>
      </div>
      <div className="loginPasswordOrganisms-inputs">
        <TextField
          fullWidth
          className="loginPasswordOrganisms-inputs-input"
          id="outlined-basic"
          label={t("reusable.email")}
          color="success"
          variant="outlined"
          error={!!errors.email}
          helperText={errors.email?.message}
          {...register("email")}
        />
        <TextField
          fullWidth
          id="outlined-basic"
          className="loginPasswordOrganisms-inputs-input"
          label={t("reusable.password")}
          color="success"
          type="password"
          variant="outlined"
          error={!!errors.password}
          helperText={errors.password?.message}
          {...register("password")}
        />
      </div>
      {
        <div className="loginPasswordOrganisms-buttons">
          {isLoading ? (
            <div className="firstButton">
              <StatusState
                statusType="loading"
                statusText={t("reusable.loading")}
              />
            </div>
          ) : (
            <>
              <Button
                className="firstButton"
                content={t("public.login.login")}
                styleType="second"
                type="submit"
                fulllWidth
              />
              {serverResponseErrors !== undefined &&
                hookName === "usePostSignIn" && (
                  <div className="firstButton">
                    {serverResponseErrors.map((error) => {
                      return (
                        <StatusState
                          statusType="error"
                          statusText={error.message}
                        />
                      );
                    })}
                  </div>
                )}
            </>
          )}
          <button type="button" className="lastButton">
            <Link to="/change-password">
              {t("public.login.forgot_password")}
            </Link>
          </button>
        </div>
      }
    </form>
  );
};
export default Login;
