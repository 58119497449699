import { BasicResponseModel } from "../../interfaces/models/BasicResponse.model";
import { AddMagazineArticlePageFileInputModel } from "../../interfaces/models/magazineModels/AddMagazineArticlePageFile.model";
import { CreateMagazineArticleInputModel } from "../../interfaces/models/magazineModels/CreateMagazineArticle.model";
import { CreateMagazineArticlePageInputModel } from "../../interfaces/models/magazineModels/CreateMagazineArticlePage.model";
import { EditMagazineInputModel } from "../../interfaces/models/magazineModels/EditMagazine.model";
import { EditMagazineArticlePageInputModel } from "../../interfaces/models/magazineModels/EditMagazineArticlePage.model";
import { MagzineResponseModel } from "../../interfaces/models/magazineModels/MagazineResponse.model";
import axios from "../../initializations/axios";

//-----POST MUTATION-----
export const postCreateMagazine = async (
  createMagazinePayload: FormData
): Promise<BasicResponseModel<MagzineResponseModel>> => {
  const { data } = await axios.post(
    "Magazine/CreateMagazine",
    createMagazinePayload,
    { headers: { "Content-Type": "multipart/form-data" } }
  );
  return data;
};
export const postAddEditMagazineCover = async (
  magazineId: number,
  createMagazinePayload: FormData
): Promise<any> => {
  const { data } = await axios.post(
    `Magazine/AddEditMagazineCover/${magazineId}`,
    createMagazinePayload,
    { headers: { "Content-Type": "multipart/form-data" } }
  );
  return data;
};
export const postCreateMagazineArticle = async (
  magazineId: number,
  createMagazineArticlePayload: CreateMagazineArticleInputModel
): Promise<any> => {
  const { data } = await axios.post(
    `Magazine/CreateMagazineArticle/${magazineId}`,
    createMagazineArticlePayload
  );
  return data;
};
export const postCreateMagazineArticlePage = async (
  magazineArticleId: number,
  createMagazineArticlePagePayload: CreateMagazineArticlePageInputModel
): Promise<any> => {
  const { data } = await axios.post(
    `Magazine/CreateMagazineArticlePage/${magazineArticleId}`,
    createMagazineArticlePagePayload
  );
  return data;
};
export const postAddMagazineArticlePageFile = async (
  magazineArticlePageId: number,
  createMagazineArticlePagePayload: AddMagazineArticlePageFileInputModel
): Promise<any> => {
  const { data } = await axios.post(
    `Magazine/AddMagazineArticlePageFile/${magazineArticlePageId}`,
    createMagazineArticlePagePayload
  );
  return data;
};
//-----PUT MUTATION-----
export const putEditMagazine = async (
  magazineId: number,
  editMagazinePayload: EditMagazineInputModel
): Promise<any> => {
  const { data } = await axios.put(
    `Magazine/EditMagazine/${magazineId}`,
    editMagazinePayload,
    { headers: { "Content-Type": "multipart/form-data" } }
  );
  return data;
};
export const putPublishMagazine = async (magazineId: number): Promise<any> => {
  const { data } = await axios.put(`Magazine/PublishMagazine/${magazineId}`);
  return data;
};
export const putUnPublishMagazine = async (
  magazineId: number
): Promise<any> => {
  const { data } = await axios.put(`Magazine/UnPublishMagazine/${magazineId}`);
  return data;
};
export const putEditMagazineArticle = async (
  magazineArticleId: number,
  editMagazineArticlePayload: CreateMagazineArticleInputModel
): Promise<any> => {
  const { data } = await axios.put(
    `Magazine/EditMagazineArticle/${magazineArticleId}`,
    editMagazineArticlePayload
  );
  return data;
};
export const putEditMagazineArticlePage = async (
  magazineArticlePageId: number,
  editMagazineArticlePagePayload: EditMagazineArticlePageInputModel
): Promise<any> => {
  const { data } = await axios.put(
    `Magazine/EditMagazineArticlePage/${magazineArticlePageId}`,
    editMagazineArticlePagePayload,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
  return data;
};
export const putEditMagazineArticlePageTitle = async (
  magazineArticlePageId: number,
  editMagazineArticlePageTitlePayload: { title: string }
): Promise<any> => {
  const { data } = await axios.put(
    `Magazine/EditMagazineArticlePageTitle/${magazineArticlePageId}`,
    editMagazineArticlePageTitlePayload
  );
  return data;
};
//-----DELETE MUTATION-----
export const deleteDeleteMagazine = async (
  magazineId: number
): Promise<any> => {
  const { data } = await axios.delete(`Magazine/deleteMagazine/${magazineId}`);
  return data;
};
export const deleteDeleteMagazineCover = async (
  magazineId: number
): Promise<any> => {
  const { data } = await axios.delete(
    `Magazine/deleteMagazineCover/${magazineId}`
  );
  return data;
};
export const deleteDeleteMagazineArticle = async (
  magazineId: number
): Promise<any> => {
  const { data } = await axios.delete(
    `Magazine/deleteMagazineArticle/${magazineId}`
  );
  return data;
};
export const deleteDeleteMagazineArticlePageFile = async (
  magazinePageFileId: number
): Promise<any> => {
  const { data } = await axios.delete(
    `Magazine/deleteMagazineArticlePageFile/${magazinePageFileId}`
  );
  return data;
};
export const deleteDeleteMagazineArticlePage = async (
  magazineArticlePageId: number
): Promise<any> => {
  const { data } = await axios.delete(
    `Magazine/deleteMagazineArticlePage/${magazineArticlePageId}`
  );
  return data;
};
export const deleteTemplate = async (templateId: number) => {
  const { data } = await axios.delete(`Template/${templateId}`);
  return data;
};
