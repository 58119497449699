import { useEffect, useState } from 'react';

export const useImagePreview = (file: File | string | null) => {
  const [image, setImage] = useState<string | null>(null);

  // effects
  useEffect(() => {
    if (file && typeof file !== 'string') {
      const newUrl = URL.createObjectURL(file);
      if (newUrl !== image) setImage(newUrl);
    } else setImage(file);
  }, [file]);

  // handlers
  const onSetImage = (value: string | null) => setImage(value);

  return { image, onSetImage };
};
