import DeleteIcon from "@mui/icons-material/Delete";
import MenuItem from "@mui/material/MenuItem";
import { FC, useState } from "react";
import Delete from "../../../modals/dashboard/Delete";
import MuiModal from "../../../modals/MuiModal";
import { useDeleteMagazineArticlePage } from "../../../react-query/hooks/mutationHooks/Magazine";
import { useTranslation } from "react-i18next";

interface DeletePageProps {
  pageId?: number;
  setAnchorEl: (value: null | HTMLElement) => void;
}
const DeletePage: FC<DeletePageProps> = ({ pageId, setAnchorEl }) => {
  const [modalShow, setModalShow] = useState(false);
  const { t } = useTranslation();
  const handleModalShow = () => setModalShow(true);
  const {
    mutate: deletePage,
    isLoading,
    isSuccess,
  } = useDeleteMagazineArticlePage();
  const handleDeletePage = () => {
    deletePage(pageId!);
    setAnchorEl(null);
  };
  return (
    <>
      <MenuItem
        onClick={handleModalShow}
        sx={{
          color: "#CCC",
          minWidth: "200px",
          "&:hover": {
            color: "#707070",
          },
        }}
      >
        <DeleteIcon sx={{ marginRight: "10px" }} />
        {t("dashboard.magazine.pages.modals.delete_page")}
      </MenuItem>
      <MuiModal
        title={t("dashboard.magazine.pages.modals.delete_page")}
        description={t("dashboard.magazine.pages.modals.are_you_sure_page")}
        modalContent={
          <Delete
            isLoading={isLoading}
            isSuccess={isSuccess}
            deleteFunction={handleDeletePage}
            setModalShow={setModalShow}
          />
        }
        setAnchorEl={setAnchorEl}
        modalShow={modalShow}
        setModalShow={setModalShow}
      />
    </>
  );
};
export default DeletePage;
