/* eslint-disable no-param-reassign */
import axios from "axios";
import {API_URL} from "../config/app";
import {readAuthenticationToken} from "../utils/storage/authentication";

const instance = axios.create({
    baseURL: API_URL,
    headers: {
        "Content-Type": "application/json",
    },
});

instance.interceptors.request.use(
    (config: any) => {
        if (config?.headers != null) {
            const jwtToken = readAuthenticationToken();
            if (jwtToken) {
                instance.defaults.headers.common.Authorization = `Bearer ${jwtToken}`;
            }
        }

        return config;
    },
    (error) => Promise.reject(error)
);

export default instance;
