import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./en.json";
import fr from "./fr.json";

const englishTranslations = en;
const frenchTranslations = fr;

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      debug: true,
      lng: "fr",
      fallbackLng: "fr",
      saveMissing: true,
      react: {
        useSuspense: true,
      },
      interpolation: {
        escapeValue: false,
      },
      resources: {
        en: { translation: englishTranslations },
        fr: { translation: frenchTranslations },
      },
    },
    () => {}
  );

export default i18n;
