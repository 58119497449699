import {Box} from '@mui/material';
import {NumberOfReadings} from './NumberOfReadings';
import {ReadingByArticles} from './ReadingByArticles';
import './_Statistics.scss';

const Statistics = () => {
    return (
        <Box paddingBottom={2} paddingTop={2}>
            <NumberOfReadings/>
            <ReadingByArticles/>
        </Box>
    );
};
export default Statistics;
