import {
  MagazineReadingStatisticsModel,
  MagazineReadingStatisticsParms,
} from './../../../interfaces/models/statistics/Dashboard.model';
import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { getMagazineReadingStatistics } from '../../mutations/Statistics';

export const useGetMagazineReadingStatisticsMutation = (
  params: MagazineReadingStatisticsParms,
  options?: Omit<
    UseQueryOptions<MagazineReadingStatisticsModel[]>,
    'queryKey' | 'queryFn'
  >
): UseQueryResult<MagazineReadingStatisticsModel[]> =>
  useQuery(
    ['getMagazineReadingStatistics'],
    () => getMagazineReadingStatistics(params),
    options
  );
