import {ChangeEvent, FC} from "react";
import {Box, IconButton, Tooltip, Typography} from "@mui/material";
import {Clear} from "@mui/icons-material";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import {useFilePreview} from "../../../hooks/useFilePreview";
import {fileName} from "../../../utils/formaters/fileName";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import {useTranslation} from "react-i18next";

interface IGenericDocumentUpload {
    title?: string;
    selectedFile: File | string | null;
    error?: boolean;
    helperText?: string;
    canDelete?: boolean;
    onSelect: (file: File | null) => void;
    deleteLoading?: boolean;
    updateLoading?: boolean;
}

export const GenericDocumentUpload: FC<IGenericDocumentUpload> = ({
                                                                      title,
                                                                      onSelect,
                                                                      selectedFile,
                                                                      helperText,
                                                                      deleteLoading,
                                                                      canDelete,
                                                                  }) => {
    const {t} = useTranslation();
    const {image: file, onSetImage: onSetFile} = useFilePreview(selectedFile);

    // handlers
    const handleFileSelect = ({target}: ChangeEvent<HTMLInputElement>) => {
        const {files} = target;
        if (files && files.length > 0) onSelect(files[0]);
    };

    const handleDeleteFile = () => {
        onSetFile(null);
        onSelect(null);
    };

    return (
        <>
            <Box
                display="flex"
                border={file ? "1px solid #3141D5" : "1px solid #EBEBEB"}
                borderRadius="10px"
                p={2}
                flexDirection="column"
            >
                <Typography fontSize="12px" sx={{color: "#727272"}}>
                    {title}
                </Typography>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    position="relative"
                >
                    <IconButton component="label">
                        <Box
                            borderRadius="50%"
                            width={60}
                            height={60}
                            sx={{
                                background: file ? "#3141D5" : "#1976D2;",
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <input
                                hidden
                                accept="application/pdf"
                                type="file"
                                onChange={handleFileSelect}
                            />
                            <UploadFileOutlinedIcon sx={{color: "white"}}/>
                        </Box>
                    </IconButton>
                    <Typography fontSize="12px" fontWeight="bold">
                        {t("dashboard.reusable.click_to_upload")}
                    </Typography>
                    <Typography
                        fontSize="12px"
                        textAlign="center"
                        sx={{color: "#8D8D8D"}}
                    >
                        {file ? fileName(file) : "PDF"}
                    </Typography>
                    {canDelete && (
                        <Box
                            sx={{
                                position: "absolute",
                                top: -30,
                                right: -20,
                                color: "#979bad",
                                boxShadow: "0 0 10px #808080",
                                border: "1px solid white",
                                borderRadius: "50%",
                                backgroundColor: "white",
                                transform: "translate(25%, 25%)",
                                cursor: "pointer",
                            }}
                        >
                            {/* {deleteLoading ? (
                <LoadingIcon />
              ) : ( */}
                            <IconButton
                                color="default"
                                aria-label="upload picture"
                                component="label"
                                size="small"
                                onClick={handleDeleteFile}
                            >
                                <Clear sx={{width: 15, height: 15}}/>
                            </IconButton>
                            {/* )} */}
                        </Box>
                    )}
                    {file && (
                        <a href={file} download>
                            <Tooltip title="Download file" arrow placement="bottom">
                                <IconButton>
                                    <DownloadRoundedIcon/>
                                </IconButton>
                            </Tooltip>
                        </a>
                    )}
                </Box>
            </Box>

            {helperText && (
                <Typography textAlign="center" fontSize="12px" sx={{color: "red"}}>
                    {helperText}
                </Typography>
            )}
        </>
    );
};
