import React, { PropsWithChildren } from "react";
import GrapesJS from "grapesjs";
import { Box, Button } from "@mui/material";
import StatusState from "../../components/molecules/StatusState/StatusState";
import MediaFilesModal from "../../modals/dashboard/MediaFiles/MediaFiles";

export interface GrapesjsReactProps {
  id: HTMLElement["id"];
  onInit?(editor?: GrapesJS.Editor): void;
  onChange?: (html: string, css: string) => void;
  onSave?: (html: string, css: string) => void;
  onDestroy?(): void;
  isLoading?: boolean;
}

const fonts = [
  { id: "Advent Pro", name: "Advent Pro" },
  { id: "Amatic SC", name: "Amatic SC" },
  { id: "Architects Daughter", name: "Architects Daughter" },
  { id: "Bangers", name: "Bangers" },
  { id: "Bebas Neue", name: "Bebas Neue" },
  { id: "Bungee", name: "Bungee" },
  { id: "Cabin Sketch", name: "Cabin Sketch" },
  { id: "Carter One", name: "Carter One" },
  { id: "Concert One", name: "Concert One" },
  { id: "Courier Prime", name: "Courier Prime" },
  { id: "Exo 2", name: "Exo 2" },
  { id: "Handlee", name: "Handlee" },
  { id: "Instrument Sans", name: "Instrument Sans" },
  { id: "Kalam", name: "Kalam" },
  { id: "Lato", name: "Lato" },
  { id: "Lilita One", name: "Lilita One" },
  { id: "Luckiest Guy", name: "Luckiest Guy" },
  { id: "Madimi One", name: "Madimi One" },
  { id: "Montserrat", name: "Montserrat" },
  { id: "Open Sans", name: "Open Sans" },
  { id: "Oswald", name: "Oswald" },
  { id: "Passion One", name: "Passion One" },
  { id: "Patrick Hand", name: "Patrick Hand" },
  { id: "Permanent Marker", name: "Permanent Marker" },
  { id: "Poppins", name: "Poppins" },
  { id: "PT Sans", name: "PT Sans" },
  { id: "PT Sans Caption", name: "PT Sans Caption" },
  { id: "Roboto", name: "Roboto" },
  { id: "Roboto Condensed", name: "Roboto Condensed" },
  { id: "Russo One", name: "Russo One" },
  { id: "Sacramento", name: "Sacramento" },
  { id: "Secular One", name: "Secular One" },
  { id: "Sen", name: "Sen" },
  { id: "Shadows Into Light", name: "Shadows Into Light" },
  { id: "Special Elite", name: "Special Elite" },
  { id: "Staatliches", name: "Staatliches" },
  { id: "Titan One", name: "Titan One" },
  { id: "Varela Round", name: "Varela Round" },
  { id: "Arial, Helvetica, sans-serif", name: "Arial" },
  { id: "Arial Black, Gadget, sans-serif", name: "Arial Black" },
  { id: "Brush Script MT, sans-serif", name: "Brush Script MT" },
  { id: "Comic Sans MS, cursive, sans-serif", name: "Comic Sans MS" },
  { id: "Courier New, Courier, monospace", name: "Courier New" },
  { id: "Georgia, serif", name: "Georgia" },
  { id: "Helvetica, sans-serif", name: "Helvetica" },
  { id: "Impact, Charcoal, sans-serif", name: "Impact" },
  { id: "Lucida Sans Unicode, Lucida Grande, sans-serif", name: "Lucida Sans Unicode" },
  { id: "Tahoma, Geneva, sans-serif", name: "Tahoma" },
  { id: "Times New Roman, Times, serif", name: "Times New Roman" },
  { id: "Trebuchet MS, Helvetica, sans-serif", name: "Trebuchet MS" },
  { id: "Verdana, Geneva, sans-serif", name: "Verdana" },
];

export function WebBuilder(
  props: PropsWithChildren<GrapesjsReactProps> &
    Parameters<typeof GrapesJS.init>[0]
) {
  const {
    id,
    onInit,
    onDestroy,
    children,
    onChange,
    onSave,
    isLoading,
    ...options
  } = props;

  const [editor, setEditor] = React.useState<GrapesJS.Editor>();
  const [openMediaFiles, setOpenMediaFiles] = React.useState(false);

  React.useEffect(() => {
    const selector = `#${id}`;
    if (!editor) {
      const editor = GrapesJS.init({
        container: selector,
        fromElement: !!children,
        ...options,
      });
      setEditor(editor);

      const link = document.createElement("link");
      link.href =
        "https://fonts.googleapis.com/css?family=Advent+Pro|Amatic+SC|Architects+Daughter|Bangers|Bebas+Neue|Bungee|Cabin+Sketch|Carter+One|Concert+One|Courier+Prime|Exo+2|Handlee|Instrument+Sans|Kalam|Lato|Lilita+One|Luckiest+Guy|Madimi+One|Montserrat|Open+Sans|Oswald|Passion+One|Patrick+Hand|Permanent+Marker|Poppins|PT+Sans|PT+Sans+Caption|Roboto|Roboto+Condensed|Russo+One|Sacramento|Secular+One|Sen|Shadows+Into+Light|Special+Elite|Staatliches|Titan+One|Varela+Round&display=swap";
      link.rel = "stylesheet";
      document.head.appendChild(link);

      const style = document.createElement("style");
      style.innerHTML = `
        body, .gjs-frame {
          font-family: 'Open Sans', sans-serif;
        }
      `;
      document.head.appendChild(style);

      editor.on("load", () => {
        const iframeHead = editor.Canvas.getDocument().head;
        const iframeLink = link.cloneNode(true) as HTMLLinkElement;
        const iframeStyle = style.cloneNode(true) as HTMLStyleElement;
        iframeHead.appendChild(iframeLink);
        iframeHead.appendChild(iframeStyle);
      });

      const styleManager = editor.StyleManager;
      const fontProperty = styleManager.getProperty(
        "typography",
        "font-family"
      );
      fontProperty.setOptions(fonts);

      if (typeof onInit === "function") {
        onInit(editor);
      }
    }
  }, [children, editor, id, onInit, options]);

  React.useEffect(() => {
    return function cleanup() {
      if (editor) {
        if (typeof onDestroy === "function") {
          onDestroy();
        }
        // const index: number = GrapesJS.editors.findIndex(
        //   (e) => e.Config.container === editor.Config.container,
        // );
        // if (typeof index === 'number' && index >= 0) {
        //   GrapesJS.editors.splice(index, 1);
        // }
        editor.destroy();
      }
    };
  }, [editor, onDestroy]);

  const handleTrigger = async () => {
    if (editor) {
      const css = editor.getCss() || "";
      const importRule = `
        @import url('https://fonts.googleapis.com/css?family=Advent+Pro|Amatic+SC|Architects+Daughter|Bangers|Bebas+Neue|Bungee|Cabin+Sketch|Carter+One|Concert+One|Courier+Prime|Exo+2|Handlee|Instrument+Sans|Kalam|Lato|Lilita+One|Luckiest+Guy|Madimi+One|Montserrat|Open+Sans|Oswald|Passion+One|Patrick+Hand|Permanent+Marker|Poppins|PT+Sans|PT+Sans+Caption|Roboto|Roboto+Condensed|Russo+One|Sacramento|Secular+One|Sen|Shadows+Into+Light|Special+Elite|Staatliches|Titan+One|Varela+Round&display=swap');
      `;
      const updatedCss = `${importRule}\n${css}`;
      onSave && onSave(editor.getHtml() || "", updatedCss);
    }
  };

  const handleOpenMediaFiles = () => {
    setOpenMediaFiles((openMediaFiles) => !openMediaFiles);
  };

  return (
    <>
      {isLoading && (
        <StatusState statusType="loading" statusText="Saving changes..." />
      )}

      <Box display="flex" justifyContent="space-between">
        <Box textAlign="end" p={2}>
          <Button
            variant="contained"
            color="success"
            onClick={handleOpenMediaFiles}
            sx={{ color: " white" }}
          >
            Media files
          </Button>
        </Box>

        <MediaFilesModal
          modalShow={openMediaFiles}
          setModalShow={handleOpenMediaFiles}
        />
        {!isLoading && (
          <Box textAlign="end" p={2}>
            <Button
              variant="contained"
              color="success"
              onClick={handleTrigger}
              sx={{ color: " white" }}
            >
              Save
            </Button>
          </Box>
        )}
      </Box>
      <div id={id}>{children}</div>
    </>
  );
}

export default WebBuilder;
