import Joi from "joi";
import { TFunction } from "react-i18next";

export const ContactUsSchema = (t: TFunction) =>
  Joi.object({
    firstName: Joi.string()
      .required()
      .messages({ "string.empty": t("reusable.required") }),
    lastName: Joi.string()
      .required()
      .messages({ "string.empty": t("reusable.required") }),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .messages({
        "string.email": t("reusable.email_must_be_valid"),
        "string.empty": t("reusable.required"),
      }),
    phoneNumber: Joi.string().allow(""),
    message: Joi.string()
      .min(1)
      .max(500)
      .required()
      .messages({
        "string.empty": t("reusable.required"),
      }),
  });
