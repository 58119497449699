import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { BasicResponseModel } from '../../../interfaces/models/BasicResponse.model';
import { QuizStatsticsModel } from '../../../interfaces/models/statistics/Dashboard.model';
import { QuizResponseModel } from '../../../schemas/quizSchemas/Quiz.schema';
import {
  getMagazinesQuizzes,
  getMagazinesQuizzStatistics,
  getQuizById,
} from '../../queries/Quiz';

export const useGetQuize = (
  quizId: number,
  options?: Omit<
    UseQueryOptions<BasicResponseModel<QuizResponseModel>>,
    'queryKey' | 'queryFn'
  >
): UseQueryResult<BasicResponseModel<QuizResponseModel>> => {
  return useQuery(['getQuizById'], () => getQuizById(quizId), options);
};

export const useGetMagazineQuizzesQuery = (
  magazineId: number,
  options?: Omit<
    UseQueryOptions<{ quizId: number; question: string }[]>,
    'queryKey' | 'queryFn'
  >
): UseQueryResult<{ quizId: number; question: string }[]> => {
  return useQuery(
    ['magazine-quizzes', magazineId],
    () => getMagazinesQuizzes(magazineId),
    options
  );
};

export const useGetMagazineQuizzesStatisticsQuery = (
  magazineId: number,
  quizId: number,
  options?: Omit<UseQueryOptions<QuizStatsticsModel>, 'queryKey' | 'queryFn'>
): UseQueryResult<QuizStatsticsModel> => {
  return useQuery(
    ['magazine-quizzes-statistics', magazineId, quizId],
    () => getMagazinesQuizzStatistics(magazineId, quizId),
    options
  );
};
