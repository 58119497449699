import { create } from 'zustand';

interface UserClaimStore {
  userClaims: { [key: string]: string[] } | null;
  setUserClaims: (userClaims: { [key: string]: string[] }) => void;
  allowedRoutes: string[] | null;
  setAllowedRoutes: (allowedRoutes: string[]) => void;
  canRoleRead: boolean;
  setCanRoleRead: (canRoleRead: boolean) => void;
  canRoleEdit: boolean;
  setCanRoleEdit: (canRoleEdit: boolean) => void;
  canRoleDelete: boolean;
  setCanRoleDelete: (canRoleDelete: boolean) => void;
  canRoleCreate: boolean;
  setCanRoleCreate: (canRoleCreate: boolean) => void;
}

export const useUserClaimStore = create<UserClaimStore>((set) => ({
  userClaims: null,
  setUserClaims: (userClaims) => set(() => ({ userClaims })),
  allowedRoutes: null,
  setAllowedRoutes: (allowedRoutes) => set(() => ({ allowedRoutes })),
  canRoleRead: false,
  setCanRoleRead: (canRoleRead) => set(() => ({ canRoleRead })),
  canRoleEdit: false,
  setCanRoleEdit: (canRoleEdit) => set(() => ({ canRoleEdit })),
  canRoleDelete: false,
  setCanRoleDelete: (canRoleDelete) => set(() => ({ canRoleDelete })),
  canRoleCreate: false,
  setCanRoleCreate: (canRoleCreate) => set(() => ({ canRoleCreate })),
}));
