import { joiResolver } from "@hookform/resolvers/joi";
import { Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { EditMagazineInputModel } from "../../../interfaces/models/magazineModels/EditMagazine.model";
import { usePutEditMagazine } from "../../../react-query/hooks/mutationHooks/Magazine";
import { EditMagazineSchema } from "../../../schemas/magazineSchemas/EditMagazine.schema";
import { useUserClaimStore } from "../../../zustand-store/UserClaim.store";
import Button from "../../atoms/Button/Button.tsx";
import { GenericDocumentUpload } from "../../molecules/GenericDocumentUpload/GenericDocumentUpload";
import StatusState from "../../molecules/StatusState/StatusState";
import "./_Magazine.scss";
import { useTranslation } from "react-i18next";

interface GeneralProps {
  title?: string;
  description?: string;
  editorialNumber?: string;
  magazinePath?: string | null;
  hasQuizes?: boolean;
}
const General: FC<GeneralProps> = ({
  title,
  description,
  editorialNumber,
  magazinePath,
  hasQuizes,
}) => {
  const { t } = useTranslation();
  const { canRoleEdit } = useUserClaimStore();
  const { magazineId } = useParams();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<EditMagazineInputModel>({
    resolver: joiResolver(EditMagazineSchema),
  });
  const {
    mutate: putEditMagazine,
    isLoading: isPutMagazineLoading,
    isSuccess,
    reset: resetPutMagazineHookStates,
  } = usePutEditMagazine(Number(magazineId));

  const handleUpdateGeneralMagazineInfoSubmit = (
    data: EditMagazineInputModel
  ) => {
    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("description", data.description);
    formData.append("editorialNumber", data.editorialNumber);

    if (data.magazinePdf && typeof data.magazinePdf !== "string")
      formData.append("magazinePdf", data.magazinePdf);
    putEditMagazine(formData as unknown as EditMagazineInputModel, {
      onSuccess: () => {
        setTimeout(() => {
          resetPutMagazineHookStates();
        }, 2000);
      },
    });
  };

  /**
   * handlers
   */
  const handleSelectPdfFile = async (file: File | null) => {
    setValue("magazinePdf", file, { shouldValidate: true });
  };

  const handleCancel = () => {
    reset();
  };

  useEffect(() => {
    reset({
      description,
      editorialNumber,
      title,
    });
  }, [magazineId]);

  return (
    <form onSubmit={handleSubmit(handleUpdateGeneralMagazineInfoSubmit)}>
      <div className="tab-header">
        <div className="tab-header-title">
          <h3>{t("dashboard.reusable.basic_info")}</h3>
          <p>{t("dashboard.reusable.info_about_magazine")}</p>
        </div>
      </div>
      <div className="tab-body">
        <TextField
          fullWidth
          id="outlined-basic"
          className="loginPasswordOrganisms-inputs-input"
          label={t("dashboard.reusable.magazine_title")}
          color="success"
          variant="outlined"
          error={!!errors.title}
          helperText={errors.title?.message}
          {...register("title")}
          disabled={!canRoleEdit}
        />
        <TextField
          fullWidth
          id="outlined-basic"
          className="loginPasswordOrganisms-inputs-input"
          label={t("dashboard.reusable.description")}
          inputProps={{ maxLength: 495 }}
          multiline
          rows={8}
          color="success"
          variant="outlined"
          error={!!errors.description}
          helperText={errors.description?.message}
          {...register("description")}
          disabled={!canRoleEdit}
        />
        {/* {!hasQuizes && (
          <TextField
            fullWidth
            id="outlined-basic"
            className="loginPasswordOrganisms-inputs-input"
            label="Magazine Link"
            inputProps={{ maxLength: 500 }}
            rows={8}
            color="success"
            variant="outlined"
            error={!!errors.magazinePath}
            helperText={errors.magazinePath?.message}
            {...register("magazinePath")}
            disabled={!canRoleEdit}
          />
        )} */}
        <TextField
          id="outlined-basic"
          className="loginPasswordOrganisms-inputs-input"
          label={t("dashboard.reusable.editorial_number")}
          color="success"
          variant="outlined"
          error={!!errors.editorialNumber}
          helperText={errors.editorialNumber?.message}
          {...register("editorialNumber")}
          disabled={!canRoleEdit}
        />
        {magazinePath && (
          <>
            <Typography fontSize="12px" sx={{ color: "#9A9A9A" }}>
              {t("dashboard.magazines.modals.upload_you_pfd_magazine")}*
            </Typography>
            <GenericDocumentUpload
              onSelect={handleSelectPdfFile}
              selectedFile={watch("magazinePdf")}
              canDelete={!!watch("magazinePdf")}
              helperText={errors.magazinePdf?.message}
            />
          </>
        )}
      </div>

      {isPutMagazineLoading ? (
        <StatusState statusText={t("reusable.loading")} statusType="loading" />
      ) : (
        <div className="tab-buttons">
          {canRoleEdit && (
            <>
              <Button
                content={t("dashboard.reusable.cancel")}
                styleType="third"
                type="button"
                onClick={handleCancel}
              />
              <Button
                content={t("dashboard.reusable.save")}
                styleType="second "
                type="submit"
              />
            </>
          )}
        </div>
      )}
      {isSuccess && (
        <div className="tab-status">
          <StatusState
            statusText={`${t(
              "dashboard.magazine.general.success_post_status"
            )}!`}
            statusType="success"
          />
        </div>
      )}
    </form>
  );
};

export default General;
