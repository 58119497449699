import { create } from 'zustand';

interface ErrorsStore {
  hasServerError: boolean;
  setHasServerError: (hasServerError: boolean) => void;
  hookName: string | undefined;
  setHookName: (hookName: string) => void;
  errors: { code: string; message: string }[] | undefined;
  setErrors: (erros: { code: string; message: string }[]) => void;
  reset: () => void;
}

export const useErrorsStore = create<ErrorsStore>((set) => ({
  hookName: undefined,
  setHookName: (hookName) => set(() => ({ hookName })),
  hasServerError: false,
  setHasServerError: (hasServerError) => set(() => ({ hasServerError })),
  errors: undefined,
  setErrors: (errors) => set(() => ({ errors })),
  reset: () =>
    set(() => ({
      errors: undefined,
      hasServerError: false,
      hookName: undefined,
    })),
}));
