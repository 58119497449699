import "./_Roles.scss";
import SearchIcon from "@mui/icons-material/Search";
import {useGetAllRolesQuery} from "../../react-query/hooks/queriesHooks/Admin";
import {useState} from "react";
import TextField from "@mui/material/TextField/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import LoadingStatus from "../../components/organisms/LoadingStatus/LoadingStatus";
import Button from "../../components/atoms/Button/Button.tsx";
import NoResults from "../../components/organisms/NoResults/NoResults";
import AddRole from "../../modals/dashboard/AddRole";
import Role from "../../components/molecules/ListsItems/Role";
import MuiModal from "../../modals/MuiModal";
import {useUserClaimStore} from "../../zustand-store/UserClaim.store";
import {useTranslation} from "react-i18next";
import DataLayout from "../../components/atoms/DataLayout/DataLayout";
import {Box} from "@mui/material";

const Roles = () => {
    const {t} = useTranslation();
    const {canRoleCreate, canRoleDelete} = useUserClaimStore();
    const [modalShow, setModalShow] = useState(false);
    const [searchWord, setSearchWord] = useState<string>("");
    const handleModalShow = () => setModalShow(true);
    const {data: {data} = {}, isLoading} = useGetAllRolesQuery(searchWord);

    return (
        <>
            <DataLayout title={t("dashboard.reusable.roles")} options={
                <Box display="flex" justifyContent="space-between" width="100%">
                    <TextField
                        id="outlined-basic"
                        label={t("dashboard.authorisations.roles.search_role")}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start" style={{
                                    padding: 0,
                                    margin: 0,
                                }}>
                                    <SearchIcon sx={{color: "#a1a7b3"}}/>
                                </InputAdornment>
                            ),
                        }}
                        onChange={(e) => {
                            setSearchWord(e.target.value);
                        }}
                        variant="outlined"
                        color="success"
                    />
                    <Button
                        show={canRoleCreate}
                        onClick={handleModalShow}
                        content={`+ ${t("dashboard.authorisations.roles.add_role")}`}
                        styleType="second"
                        type="button"
                    />
                </Box>
            }>
                {isLoading ? (
                    <LoadingStatus/>
                ) : (
                    <>
                        {data !== undefined && data?.length > 0 ? (
                            <Box position="relative" className="pagesStyle-tables scroll-group" minHeight={400}
                                 padding={2}
                                 boxShadow="0 0 1px rgba(0, 0, 0, 0.15)">
                                <Box overflow="auto" className="scroll-group-hover:no-scrollbar">
                                    <table>
                                        <thead>
                                        <tr className="no-hover">
                                            <th className="middleItem">
                                                {t("dashboard.authorisations.reusable.function")}
                                            </th>
                                            {canRoleDelete && (
                                                <th className="lastItem">
                                                    {t("dashboard.reusable.actions")}
                                                </th>
                                            )}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {data?.map((role) => {
                                            return (
                                                <Role
                                                    key={role.roleId}
                                                    roleClaims={role.roleClaims}
                                                    roleId={role.roleId}
                                                    name={role.name}
                                                />
                                            );
                                        })}
                                        </tbody>
                                    </table>
                                </Box>
                            </Box>
                        ) : (
                            <NoResults/>
                        )}
                    </>
                )}
            </DataLayout>
            <MuiModal
                title={t("dashboard.authorisations.roles.add_role")}
                description={t("dashboard.authorisations.roles.modals.write_name")}
                modalContent={<AddRole setModalShow={setModalShow}/>}
                modalShow={modalShow}
                setModalShow={setModalShow}
            />
        </>
    );
};
export default Roles;
