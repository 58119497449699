import {useMutation, useQueryClient} from "@tanstack/react-query";
import {AddRoleClaimInputModel} from "../../../interfaces/models/adminModels/AddRoleClaim.model";
import {CreateRoleInputModel} from "../../../interfaces/models/adminModels/CreateRole.model";
import {CreateUserInputModel} from "../../../interfaces/models/adminModels/CreateUser.model";
import {
  deleteDeleteRole,
  deleteDeleteUser,
  deleteRemoveRoleClaim,
  postAddRoleClaim,
  postCreateRole,
  postCreateUser,
  putEditUserProfile,
} from "../../mutations/Admin";
import {useErrorsStore} from "../../../zustand-store/Errors.store";
import {MyAxiosErrorResponse} from "../../../interfaces/models/AxiosErrorResponse";
import {ErrorResponse} from "../../../interfaces/models/ErrorResponse.model";
import {isServerError} from "../../../utils/isServerError";

//-----POST mutationsHooks-----
export const usePostRole = () => {
    const queryClient = useQueryClient();
    return useMutation(
        (postCreateRolePayload: CreateRoleInputModel) =>
            postCreateRole(postCreateRolePayload),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["getAllRoles"]);
            },
        }
    );
};
export const usePostCreateUser = () => {
    const {setErrors, setHasServerError, setHookName} = useErrorsStore();
    const queryClient = useQueryClient();
    return useMutation(
        ["updateUser"],
        (postCreateUserPayload: CreateUserInputModel) =>
            postCreateUser(postCreateUserPayload),
        {
            onSuccess: () => queryClient.invalidateQueries(["getAllUsers"]),
            onError: (response: unknown) => {
                const responseOnError = response as MyAxiosErrorResponse<ErrorResponse>;
                setHookName("updateUser");
                setErrors(responseOnError.response.data.errors);
                setHasServerError(!!isServerError(responseOnError.response.status));
            },
        }
    );
};
export const useAddRoleClaim = () => {
    const queryClient = useQueryClient();
    return useMutation(
        (postAddRoleClaimPayload: AddRoleClaimInputModel) =>
            postAddRoleClaim(postAddRoleClaimPayload),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["getRole"]);
            },
        }
    );
};
//-----PUT mutationsHooks-----
export const usePutCreateUser = (userId: string) => {
    const queryClient = useQueryClient();
    return useMutation(
        ["createUser"],
        (putEditMyProfilePayload: CreateUserInputModel) =>
            putEditUserProfile(userId, putEditMyProfilePayload),
        {
            onSuccess: () => queryClient.invalidateQueries(["getAllUsers"]),
        }
    );
};
//-----DELETE mutationsHooks-----
export const useDeleteRole = () => {
    const queryClient = useQueryClient();
    return useMutation((roleId: string) => deleteDeleteRole(roleId), {
        onSuccess: () => queryClient.invalidateQueries(["getAllRoles"]),
    });
};
export const useDeleteUser = () => {
    const queryClient = useQueryClient();
    return useMutation((userId: string) => deleteDeleteUser(userId), {
        onSuccess: () => queryClient.invalidateQueries(["getAllUsers"]),
    });
};
export const useDeleteRoleClaim = () => {
    const queryClient = useQueryClient();
    return useMutation(
        (roleClaimId: number) => deleteRemoveRoleClaim(roleClaimId),
        {
            onSuccess: () => queryClient.invalidateQueries(["getRole"]),
        }
    );
};
