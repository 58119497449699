import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import "../../_MuiModal.scss";
import "./_UserQuiz.scss";
import userQuizIcon from "../../../assets/images/userQuizIcon.png";
import Button from "../../../components/atoms/Button/Button.tsx";
import StatusState from "../../../components/molecules/StatusState/StatusState";
import {useGetQuize} from "../../../react-query/hooks/queriesHooks/Quiz";
import {useAnswerQuiz} from "../../../react-query/hooks/mutationHooks/Quiz";
import {AnswerQuizInputModel} from "../../../interfaces/models/quizModels/AnswerQuiz.model";
import {AnswerQuizSchema} from "../../../schemas/quizSchemas/AnswerQuiz.schema";
import {useForm} from "react-hook-form";
import {joiResolver} from "@hookform/resolvers/joi";
import {ChangeEvent, useEffect, useState} from "react";
import Confetti from "react-confetti";
import {useTranslation} from "react-i18next";

export default function UserQuiz({
                                     modalShow,
                                     setModalShow,
                                     quizId,
                                     magazineId,
                                 }: {
    quizId: number;
    magazineId: number;
    modalShow: boolean;
    setModalShow: (modalShow: boolean) => void;
}) {
    const {t} = useTranslation();
    const {handleSubmit} = useForm<AnswerQuizInputModel>({
        resolver: joiResolver(AnswerQuizSchema),
    });
    const {
        data: {data: quiz} = {},
        isLoading: isGettingQuizLoading,
        refetch,
    } = useGetQuize(quizId, {enabled: quizId !== undefined});
    const {
        mutate: answerQuiz,
        data: {data: answerQuizResponse} = {},
        isLoading: isAnswerQuizLoading,
        isSuccess: isAnswerQuizSuccess,
        reset,
    } = useAnswerQuiz(quizId);
    const [answeredOptions, setAnsweredOptions] = useState<number[]>([]);
    const handleAnswerQuiz = () => {
        answerQuiz({options: answeredOptions, magazineId});
    };
    const handleCloseModal = () => {
        setModalShow(false);
        setAnsweredOptions([]);
        reset();
    };
    const handleSelectOptions = (
        e: ChangeEvent<HTMLInputElement>,
        id: number
    ) => {
        if (e.target.checked) {
            setAnsweredOptions([...answeredOptions, id]);
        } else {
            setAnsweredOptions(answeredOptions.filter((option) => option !== id));
        }
    };

    useEffect(() => {
        quizId !== undefined && refetch();
    }, [quizId]);
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={modalShow}
            onClose={handleCloseModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            sx={{zIndex: "10003"}}
        >
            <Fade in={modalShow}>
                <Box>
                    <div className="muiModal">
                        <form
                            className="userQuiz"
                            onSubmit={handleSubmit(handleAnswerQuiz)}
                        >
                            <div className="userQuiz-header">
                                <div className="title">
                                    <h4>{t("public.quize.questions_for_a_champion")}</h4>
                                    <p>{quiz?.question}</p>
                                </div>
                                <img src={userQuizIcon} alt="User quiz icon"/>
                            </div>
                            {!isAnswerQuizSuccess && (
                                <div className="userQuiz-body">
                                    {quiz?.options?.map((option) => {
                                        return (
                                            <div className="input" key={option.id}>
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        onChange={(e) => handleSelectOptions(e, option.id)}
                                                        disabled={!!answerQuizResponse}
                                                    />
                                                    <span>{option.option}</span>
                                                </label>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                            {isAnswerQuizSuccess && (
                                <div className="userQuiz-body">
                                    {answerQuizResponse?.answers?.map((answer) => {
                                        return (
                                            <div
                                                className={`input ${answer.isCorrect}`}
                                                key={answer.optionId}
                                            >
                                                <label>
                                                    <span>{answer.option}</span>
                                                </label>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                            {isAnswerQuizLoading && (
                                <StatusState
                                    statusText={t("reusable.loading")}
                                    statusType="loading"
                                />
                            )}
                            {isAnswerQuizSuccess && answerQuizResponse?.youWereCorrect && (
                                <StatusState
                                    statusText={t("public.quize.congratulations")}
                                    statusType="success"
                                />
                            )}

                            {!isAnswerQuizLoading && !isAnswerQuizSuccess && (
                                <div className="userQuiz-footer">
                                    <Button
                                        disable={answeredOptions.length === 0}
                                        content={t("public.quize.done")}
                                        styleType="second"
                                        type="submit"
                                        fulllWidth
                                    />
                                    <Button
                                        onClick={handleCloseModal}
                                        content={t("public.quize.later")}
                                        styleType="third"
                                        type="button"
                                        fulllWidth
                                    />
                                </div>
                            )}
                            {isAnswerQuizSuccess && !answerQuizResponse?.youWereCorrect && (
                                <div className="userQuiz-footer" style={{marginTop: "10px"}}>
                                    <Button
                                        onClick={() => {
                                            reset();
                                            setAnsweredOptions([]);
                                        }}
                                        content={t("public.quize.try-again")}
                                        styleType="second"
                                        type="button"
                                        fulllWidth
                                    />
                                </div>
                            )}
                            {isAnswerQuizSuccess && !answerQuizResponse?.youWereCorrect && (
                                <StatusState
                                    statusText={t("public.quize.sorry")}
                                    statusType="error"
                                />
                            )}
                        </form>
                    </div>
                    {isAnswerQuizSuccess && answerQuizResponse?.youWereCorrect && (
                        <Confetti numberOfPieces={150} width={600}/>
                    )}
                </Box>
            </Fade>
        </Modal>
    );
}
