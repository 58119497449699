import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { SubscribeInputModel } from "../../../interfaces/models/newsletterModels/Subscribe.model";
import { FollowUsSchema } from "../../../schemas/newsletterSchemas/FollowUs.schema";
import { usePostSubscribe } from "../../../react-query/hooks/mutationHooks/NewsLetter";
import Button from "../../atoms/Button/Button.tsx";
import StatusState from "../../molecules/StatusState/StatusState";
import { useTranslation } from "react-i18next";

const Follow = () => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SubscribeInputModel>({
    resolver: joiResolver(FollowUsSchema(t)),
  });
  const { mutate: subscribe, isLoading, isSuccess } = usePostSubscribe();
  const handleFollowSubmit = (subscribePayload: SubscribeInputModel) => {
    subscribe(subscribePayload);
  };

  return (
    <form
      className="contactFollowOrganisms"
      onSubmit={handleSubmit(handleFollowSubmit)}
    >
      <div className="firstInputs">
        <TextField
          fullWidth
          id="outlined-basic"
          label={t("reusable.name")}
          color="success"
          variant="outlined"
          error={!!errors.firstName}
          helperText={errors.firstName?.message}
          {...register("firstName")}
        />
        <TextField
          fullWidth
          id="outlined-basic"
          label={t("reusable.last_name")}
          color="success"
          variant="outlined"
          error={!!errors.lastName}
          helperText={errors.lastName?.message}
          {...register("lastName")}
        />
      </div>
      <TextField
        fullWidth
        id="outlined-basic"
        label={t("reusable.email")}
        color="success"
        type="email"
        variant="outlined"
        error={!!errors.email}
        helperText={errors.email?.message}
        {...register("email")}
      />
      {!isLoading && (
        <div className="contactFollowOrganisms-buttons">
          <Button
            content={t("public.subscribe.subscribe")}
            styleType="second"
            type="submit"
            fulllWidth
            disable={isSuccess}
          />
        </div>
      )}
      <div className="contactFollowOrganisms-status">
        {isLoading && (
          <StatusState
            statusText={t("reusable.loading")}
            statusType="loading"
          />
        )}
        {isSuccess && (
          <StatusState
            statusText={t("public.subscribe.success_status")}
            statusType="success"
          />
        )}
      </div>
    </form>
  );
};
export default Follow;
