import Joi from 'joi';

export const ChangePasswordSchema = Joi.object({
  currentPassword: Joi.string()
    .required()
    .messages({ 'string.empty': 'Required!' }),
  newPassword: Joi.string()
    .required()
    .regex(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    )
    .messages({
      'string.empty': 'Required!',
      'string.pattern.base':
        'Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character',
    }),
  confirmNewPassword: Joi.string()
    .required()
    .valid(Joi.ref('newPassword'))
    .messages({
      'any.required': 'Required!',
      'string.pattern.base': 'Must have at least 8 characters',
      'any.only': 'Confirm password must be the same as new password',
    }),
});
