import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AddFolderInputModel } from "../../../interfaces/models/folderModels/AddFolder.model";
import { EditFolderInputModel } from "../../../interfaces/models/folderModels/EditFolder.model";
import { EditFolderFileInputModel } from "../../../interfaces/models/folderModels/EditFolderFile.model";
import {
  deleteDeleteFolder,
  deleteDeleteFolderFile,
  postAddFolderFile,
  postCreateFolder,
  putEditFolder,
  putEditFolderFile,
} from "../../mutations/Folder";

//-----POST mutationsHooks-----
export const usePostCreateFolders = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (postCreateFolderPayload: AddFolderInputModel) =>
      postCreateFolder(postCreateFolderPayload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["getAllFolders"]);
      },
    }
  );
};
export const usePostAddFolderFile = (FolderId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (postAddFolderFilePayload: FormData) =>
      postAddFolderFile(FolderId, postAddFolderFilePayload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["getAllFiles"]);
      },
    }
  );
};
//-----PUT mutationsHooks-----
export const usePutFolder = (folderId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (editFolderPayload: EditFolderInputModel) =>
      putEditFolder(folderId, editFolderPayload),
    {
      onSuccess: () => queryClient.invalidateQueries(["getAllFolders"]),
    }
  );
};
export const usePutFolderFile = (FileId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (putEditFolderFilePayload: EditFolderFileInputModel) =>
      putEditFolderFile(FileId, putEditFolderFilePayload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["getAllFiles"]);
      },
    }
  );
};
//-----DELETE mutationsHooks----
export const useDeleteFolder = () => {
  const queryClient = useQueryClient();
  return useMutation((folderId: number) => deleteDeleteFolder(folderId), {
    onSuccess: () => queryClient.invalidateQueries(["getAllFolders"]),
  });
};
export const useDeleteFolderFile = () => {
  const queryClient = useQueryClient();
  return useMutation((folderId: number) => deleteDeleteFolderFile(folderId), {
    onSuccess: () => queryClient.invalidateQueries(["getAllFiles"]),
  });
};
