import { AddRoleClaimInputModel } from "../../interfaces/models/adminModels/AddRoleClaim.model";
import { CreateUserInputModel } from "../../interfaces/models/adminModels/CreateUser.model";
import axios from "../../initializations/axios";

//-----POST MUTATION-----
export const postCreateUser = async (
  createUserPayload: CreateUserInputModel
): Promise<any> => {
  const { data } = await axios.post("Admin/CreateUser", createUserPayload);
  return data;
};
export const postCreateRole = async (createRolePayload: {
  roleName: string;
}): Promise<any> => {
  const { data } = await axios.post("Admin/CreateRole", createRolePayload);
  return data;
};
export const postAddRoleClaim = async (
  addRoleClaimPayload: AddRoleClaimInputModel
): Promise<any> => {
  const { data } = await axios.post("Admin/AddRoleClaim", addRoleClaimPayload);
  return data;
};
//-----PUT MUTATION-----
export const putEditUserProfile = async (
  userId: string,
  editUserProfilePayload: CreateUserInputModel
): Promise<any> => {
  const { data } = await axios.put(
    `Admin/EditUserProfile/${userId}`,
    editUserProfilePayload
  );
  return data;
};
//-----DELETE MUTATION-----
export const deleteRemoveRoleClaim = async (
  roleClaimId: number
): Promise<any> => {
  const { data } = await axios.delete(`Admin/RemoveRoleClaim/${roleClaimId}`);
  return data;
};
export const deleteDeleteRole = async (roleId: string): Promise<any> => {
  const { data } = await axios.delete(`Admin/DeleteRole/${roleId}`);
  return data;
};
export const deleteDeleteUser = async (userId: string): Promise<any> => {
  const { data } = await axios.delete(`Admin/DeleteUser/${userId}`);
  return data;
};
