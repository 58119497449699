import Switch from "@mui/material/Switch";
import { FC, useEffect, useState } from "react";
import { ClaimTypeResponseModel } from "../../../interfaces/models/adminModels/ClaimTypeResponse.model";
import { ClaimValueResponse } from "../../../interfaces/models/adminModels/ClaimValueResponse.model";
import {
  useAddRoleClaim,
  useDeleteRoleClaim,
} from "../../../react-query/hooks/mutationHooks/Admin";
import { getClaimId } from "../../../utils/claimsManagment/getClaimId";
import { hasThisClaimType } from "../../../utils/claimsManagment/hasThisClaimType";
import { useUserClaimStore } from "../../../zustand-store/UserClaim.store";
import "./_ListItems.scss";

interface PermissionProps extends ClaimTypeResponseModel {
  claimValue: ClaimValueResponse[] | undefined;
  roleId: string;
}

const Permissions: FC<PermissionProps> = ({
  claimType,
  claimTypeId,
  roleId,
  claimValue,
}) => {
  const { canRoleEdit } = useUserClaimStore();
  const { mutate: addRoleClaim } = useAddRoleClaim();
  const { mutate: deleteRoleClaim } = useDeleteRoleClaim();
  const [read, setRead] = useState<boolean | undefined>(false);
  const [add, setAdd] = useState<boolean | undefined>(false);
  const [edit, setEdit] = useState<boolean | undefined>(false);
  const [deleteClaim, setDeleteClaim] = useState<boolean | undefined>(false);

  function handleReadSwitchChange() {
    setRead(!read);
    handleSwitchChange("read", getClaimId(claimValue, "read")?.claimValueId);
  }

  function handleAddSwitchChange() {
    setAdd(!add);
    handleSwitchChange("add", getClaimId(claimValue, "add")?.claimValueId);
  }

  function handleEditSwitchChange() {
    setEdit(!edit);
    handleSwitchChange("edit", getClaimId(claimValue, "edit")?.claimValueId);
  }

  function handleDeleteSwitchChange() {
    setDeleteClaim(!deleteClaim);
    handleSwitchChange(
      "delete",
      getClaimId(claimValue, "delete")?.claimValueId
    );
  }

  const handleSwitchChange = (value: string, claimId: number | undefined) => {
    if (hasThisClaimType(claimValue, value)) {
      if (claimId !== undefined) {
        deleteRoleClaim(claimId);
      }
    } else {
      addRoleClaim({
        roleId: roleId,
        claimType: claimType,
        claimValue: value,
      });
    }
  };
  useEffect(() => {
    setRead(false);
    setAdd(false);
    setEdit(false);
    setDeleteClaim(false);
    if (claimValue) {
      setRead(hasThisClaimType(claimValue, "read"));
      setAdd(hasThisClaimType(claimValue, "add"));
      setEdit(hasThisClaimType(claimValue, "edit"));
      setDeleteClaim(hasThisClaimType(claimValue, "delete"));
    }
  }, [claimValue]);
  return (
    <tr className="cursor-default">
      <td
        className="firstItem"
        style={{
          paddingLeft: 8,
        }}
      >
        <p>{claimType}</p>
      </td>
      <td>
        <Switch
          id={String(getClaimId(claimValue, "read")?.claimValueId)}
          checked={read}
          color="warning"
          onClick={handleReadSwitchChange}
          inputProps={{ "aria-label": "controlled" }}
          disabled={!canRoleEdit}
        />
      </td>
      <td>
        <Switch
          id={String(getClaimId(claimValue, "add")?.claimValueId)}
          checked={add}
          color="warning"
          inputProps={{ "aria-label": "controlled" }}
          onClick={handleAddSwitchChange}
          disabled={!canRoleEdit}
        />
      </td>
      <td>
        <Switch
          id={String(getClaimId(claimValue, "edit")?.claimValueId)}
          checked={edit}
          color="warning"
          inputProps={{ "aria-label": "controlled" }}
          onClick={handleEditSwitchChange}
          disabled={!canRoleEdit}
        />
      </td>
      <td>
        <Switch
          id={String(getClaimId(claimValue, "delete")?.claimValueId)}
          checked={deleteClaim}
          color="warning"
          inputProps={{ "aria-label": "controlled" }}
          onClick={handleDeleteSwitchChange}
          disabled={!canRoleEdit}
        />
      </td>
    </tr>
  );
};
export default Permissions;
