import TextField from "@mui/material/TextField";
import { MouseEvent, useState } from "react";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { FileModel } from "../../interfaces/models/File.model";
import Button from "../../components/atoms/Button/Button.tsx";
import { AddMediaFileSchema } from "../../schemas/mediaFileSchemas/AddMediaFile.schema";
import { useModalStore } from "../../zustand-store/Modal.store";
import { usePostAddMediaFile } from "../../react-query/hooks/mutationHooks/MediaFile";
import { AddMediaFileInputModel } from "../../interfaces/models/mediaFile/AddMediaFile.model";
import StatusState from "../../components/molecules/StatusState/StatusState";
import { closeModalWithDelay } from "../../utils/closeModalWithDelay";
import { useTranslation } from "react-i18next";

const AddMediaFile = () => {
  const { t } = useTranslation();
  const { setModalShow } = useModalStore();
  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm<AddMediaFileInputModel>({
    resolver: joiResolver(AddMediaFileSchema),
  });

  const { mutate: addMediaFile, isLoading, isSuccess } = usePostAddMediaFile();

  const [fileParameters, setFileParameters] = useState<FileModel>();

  const handleAddMediaFile = (addMediaFilePayload: AddMediaFileInputModel) => {
    const addFileFormData: any = new FormData();
    addFileFormData.append("FileName", addMediaFilePayload.FileName);
    addFileFormData.append("File", addMediaFilePayload.File);
    addMediaFile(addFileFormData, {
      onSuccess: () => {
        closeModalWithDelay(setModalShow);
      },
    });
  };

  const handleCancel = () => {
    reset();
    setModalShow(false);
    setFileParameters(undefined);
    clearErrors();
  };
  const handleRemoveFile = () => {
    setFileParameters(undefined);
    clearErrors();
  };

  return (
    <form
      className="muiModal-content"
      onSubmit={handleSubmit(handleAddMediaFile)}
    >
      <div className="muiModal-content-inputs">
        <TextField
          className="muiModal-content-inputs-input"
          fullWidth
          id="outlined-basic"
          label={t("dashboard.files.title")}
          color="success"
          variant="outlined"
          error={!!errors?.FileName}
          helperText={errors?.FileName?.message}
          {...register("FileName")}
        />
        <div
          className={`muiModal-content-inputs-input addFile ${
            !!errors?.File ? "errorNoFile" : ""
          }`}
          style={{
            padding: 0,
          }}
        >
          <label
            className="interactive-cursor w-full h-full"
            style={{
              paddingTop: 15,
              paddingBottom: 15,
            }}
          >
            <input
              type="file"
              onChange={(e) => {
                setValue("File", e.target.files![0], { shouldValidate: true });
                setFileParameters({
                  size: e.target.files![0].size,
                  name: e.target.files![0].name,
                });
              }}
              onClick={(event: MouseEvent<HTMLInputElement>) => {
                (event.target as HTMLInputElement).value = "";
              }}
            />
            <div className="uploadMagazineCover">
              <div className="noFile">
                <UploadFileIcon
                  sx={{
                    color: errors?.File ? "rgb(200, 0 , 0)" : "#1976D2",
                    fontSize: "40px",
                    marginBottom: "10px",
                  }}
                />
                <h4>
                  {!fileParameters && (
                    <span>{t("dashboard.reusable.click_to_upload")}</span>
                  )}
                  {`${
                    !!fileParameters ? " if you want to change the file" : ""
                  }`}
                </h4>
                <p>
                  {t("dashboard.reusable.allowed_file_types")} <br />{" "}
                  <b>{t("dashboard.media_files.modals.images")}</b>: jpeg, jpg,
                  png, gif
                  <br /> <b>{t("dashboard.media_files.modals.videos")}</b>: mp4,
                  webm, ogg
                </p>
                {!!errors?.File && (
                  <p className="pError">{errors.File?.message}</p>
                )}
              </div>
            </div>
          </label>
        </div>
      </div>
      {!!fileParameters && !errors?.File && (
        <div className="fileUpload">
          <InsertDriveFileIcon
            sx={{
              color: "#1976D2",
              fontSize: "35px",
            }}
          />
          <div className="fileUpload-info">
            <p>
              {fileParameters?.name}
              {fileParameters?.size !== undefined && (
                <span>{` (${(fileParameters?.size / 1024).toFixed(
                  1
                )} kb)`}</span>
              )}
            </p>
          </div>
          <CloseIcon
            sx={{
              color: "#1976D2",
              fontSize: "18px",
            }}
            onClick={handleRemoveFile}
          />
        </div>
      )}
      <div className="muiModal-content-status">
        {isLoading && (
          <StatusState
            statusText={t("reusable.loading")}
            statusType="loading"
          />
        )}
        {isSuccess && (
          <StatusState
            statusText={t("dashboard.reusable.success_post_file_status")}
            statusType="success"
          />
        )}
      </div>
      {!isLoading && !isSuccess && (
        <div className="muiModal-content-modalbuttons">
          <Button
            onClick={handleCancel}
            content={t("dashboard.reusable.cancel")}
            styleType="third"
            type="button"
          />
          <Button
            content={t("dashboard.reusable.save")}
            styleType="second"
            type="submit"
          />
        </div>
      )}
    </form>
  );
};
export default AddMediaFile;
