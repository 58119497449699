import defaultProfilPic from "../../../assets/images/defaultProfilePic.png";
import CancelIcon from "@mui/icons-material/Cancel";
import TextField from "@mui/material/TextField";
import {useForm} from "react-hook-form";
import {joiResolver} from "@hookform/resolvers/joi";
import {useQueryClient} from "@tanstack/react-query";
import {useAuthenticationStore} from "../../../zustand-store/Authentication.store";
import {EditMyProfileInputModel} from "../../../interfaces/models/accountModels/EditMyProfile.model";
import {EditMyProfileSchema} from "../../../schemas/accountSchemas/EditMyProfile.schema";
import {useImagePreview} from "../../../hooks/useImagePreview";
import {useDeleteProfilePicture, usePutEditMyProfile,} from "../../../react-query/hooks/mutationHooks/Account";
import StatusState from "../../molecules/StatusState/StatusState";
import Button from "../../atoms/Button/Button.tsx";
import {useTranslation} from "react-i18next";

const ProfilDetails = () => {
  const { t } = useTranslation();
  const { authenticatedUser } = useAuthenticationStore();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<EditMyProfileInputModel>({
    resolver: joiResolver(EditMyProfileSchema),
    defaultValues: {
      Email: authenticatedUser?.email,
      FirstName: authenticatedUser?.firstName,
      LastName: authenticatedUser?.lastName,
    },
  });

  const queryClient = useQueryClient();
  const { image } = useImagePreview(getValues("ProfilePicture"));
  const { mutate: deleteProfilePicture } = useDeleteProfilePicture();
  const handleDeleteProfilePicture = () => {
    deleteProfilePicture(undefined, {
      onSuccess: () => {
        queryClient.fetchQuery(["myProfile"]);
        reset();
      },
    });
  };

  const {
    mutate: editMyProfile,
    isLoading: isEditMyProfileLoading,
    isSuccess: isEditMyProfileSucces,
    reset: resetEditMyProfileRQhook,
  } = usePutEditMyProfile();
  const handleEditMyProfileSubmit = (
    editMyProfilePayload: EditMyProfileInputModel
  ) => {
    const editMyProfileFormData = new FormData();
    editMyProfileFormData.append("FirstName", editMyProfilePayload.FirstName);
    editMyProfileFormData.append("LastName", editMyProfilePayload.LastName);
    editMyProfileFormData.append("Email", editMyProfilePayload.Email);
    editMyProfileFormData.append(
      "ProfilePicture",
      editMyProfilePayload.ProfilePicture
    );
    editMyProfile(editMyProfileFormData, {
      onSuccess: () => {
        queryClient.fetchQuery(["myProfile"]);
        setTimeout(() => {
          resetEditMyProfileRQhook();
        }, 3000);
      },
    });
  };

  const handleDiscard = () => {
    reset();
  };

  return (
    <form
      className="settingsOrganismsStyle"
      onSubmit={handleSubmit(handleEditMyProfileSubmit)}
    >
      <h3 className="settingsOrganismsStyle-title">
        {t("dashboard.settings.profil_details")}
      </h3>
      <div className="profilDetails">
        {
          <>
            <label className="interactive-cursor">
              <input
                type="file"
                onChange={(e) => {
                  setValue("ProfilePicture", e.target.files![0], {
                    shouldValidate: true,
                  });
                }}
              />
              {!authenticatedUser?.profilePhoto && (
                <div className="profilPicInput-noPhoto">
                  {!!errors.ProfilePicture ? (
                    <img src={defaultProfilPic} alt="Default profile" />
                  ) : (
                    <img src={image ?? defaultProfilPic} alt="User profile" />
                  )}
                </div>
              )}
            </label>
            {!authenticatedUser?.profilePhoto && (
              <p className="profilPicInput-allowedType">
                {`${t("dashboard.reusable.allowed_file_types")}: png, jpg.`}
              </p>
            )}
            {!!errors.ProfilePicture && (
              <p className="profilPicInput-error">
                {errors.ProfilePicture?.message}
              </p>
            )}
          </>
        }
        {!!authenticatedUser?.profilePhoto && (
          <div className="profilPicInput-style">
            <img src={authenticatedUser?.profilePhoto} alt="User profile" />
            <button
              type="button"
              className="icon ghost-button"
              onClick={handleDeleteProfilePicture}
            >
              <CancelIcon
                sx={{
                  color: "white",
                  fontSize: "25px",
                  backgroundColor: "#979BAD",
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                  borderRadius: "500px",
                  border: "none",
                }}
              />
            </button>
          </div>
        )}
      </div>
      <div className="settingsOrganismsStyle-inputs">
        <TextField
          fullWidth
          className="input"
          id="outlined-basic"
          label={t("reusable.name")}
          type="text"
          color="success"
          variant="outlined"
          error={!!errors?.FirstName}
          helperText={errors?.FirstName?.message}
          {...register("FirstName")}
        />
        <TextField
          fullWidth
          className="input"
          id="outlined-basic"
          label={t("reusable.last_name")}
          type="text"
          color="success"
          variant="outlined"
          error={!!errors?.LastName}
          helperText={errors?.LastName?.message}
          {...register("LastName")}
        />
        <TextField
          fullWidth
          id="outlined-basic"
          className="input"
          label={t("reusable.email")}
          type="email"
          color="success"
          disabled
          defaultValue={authenticatedUser?.email}
          variant="outlined"
        />
      </div>
      {isEditMyProfileLoading ? (
        <div className="settingsOrganismsStyle-status">
          <StatusState
            statusText={t("reusable.loading")}
            statusType="loading"
          />
        </div>
      ) : (
        <>
          {isEditMyProfileSucces ? (
            <div className="settingsOrganismsStyle-status">
              <StatusState
                statusText={t(
                  "dashboard.settings.success_update_profile_status"
                )}
                statusType="success"
              />
            </div>
          ) : (
            <div className="settingsOrganismsStyle-buttons">
              <Button
                onClick={handleDiscard}
                content={t("dashboard.reusable.cancel")}
                styleType="third"
                type="button"
              />
              <Button
                content={t("dashboard.reusable.save")}
                styleType="second"
                type="submit"
                disable={isEditMyProfileSucces}
              />
            </div>
          )}
        </>
      )}
    </form>
  );
};
export default ProfilDetails;
