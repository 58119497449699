import { forwardRef } from 'react';
import {
  Select as MUISelect,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import { red } from '@mui/material/colors';
import { SelectProps } from './Select.props';

const Select = forwardRef<HTMLSelectElement, SelectProps>(
  ({ options, errorMessage, ...rest }, ref) => {
    // destructures
    const { error, label } = rest;

    return (
      <FormControl fullWidth size="small">
        <InputLabel id={`${label}-label`} error={error}>
          {label}
        </InputLabel>
        <MUISelect
          id={`${label}-select`}
          labelId={`${label}-label`}
          inputRef={ref}
          {...rest}
        >
          {options?.map(({ id, name }) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
        </MUISelect>
        {errorMessage && (
          <FormHelperText sx={{ color: red[800] }}>
            {errorMessage}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
);

Select.displayName = 'Select';
export default Select;
