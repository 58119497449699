import {FC} from "react";
import "./_Button.scss";

interface ButtonProps {
    show?: boolean;
    disable?: boolean;
    fulllWidth?: boolean;
    className?: string;
    content: string;
    styleType?: string;
    onClick?: React.MouseEventHandler;
    type: "button" | "submit" | "reset" | undefined;
}

const Button: FC<ButtonProps> = ({
                                     show,
                                     disable,
                                     content,
                                     styleType,
                                     type,
                                     fulllWidth,
                                     onClick,
                                     className,
                                 }) => (
    <button
        hidden={show === undefined ? false : !show}
        className={`${className} customizedButton customizedButton-${styleType} ${
            fulllWidth ? "customizedButton-fullWidth" : ""
        } ${disable ? `customizedButton-${styleType}-disableText` : ""}`}
        onClick={onClick}
        type={type}
        disabled={disable}
    >
        {content}
    </button>
)

export default Button;
