import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { FC, useState } from "react";
import Menu from "@mui/material/Menu";
import DeleteIcon from "@mui/icons-material/Delete";
import { useGetAllMagazineArticlePagesQuery } from "../../../react-query/hooks/queriesHooks/Magazine";
import MuiModal from "../../../modals/MuiModal";
import Delete from "../../../modals/dashboard/Delete";
import EditPage from "../../atoms/PageAtoms/EditPage";
import AddQuizMenuItem from "../../atoms/PageAtoms/AddQuizMenuItem";
import AddPageTitle from "../../../modals/dashboard/AddPageTitle/AddPageTitle";
import DeletePage from "../../atoms/PageAtoms/DeletePage";
import { useNavigate } from "react-router-dom";
import { useDeleteQuiz } from "../../../react-query/hooks/mutationHooks/Quiz";
import { closeModalWithDelay } from "../../../utils/closeModalWithDelay";
import { useUserClaimStore } from "../../../zustand-store/UserClaim.store";
import { useTranslation } from "react-i18next";

interface PageProp {
  articleTitle: string;
  articleId: number;
}
interface PageStateProp {
  pageId: number;
  pageTitle: string;
  pagePath: string;
  hasQuiz: boolean;
  quizId: number | null;
}
const Page: FC<PageProp> = ({ articleId, articleTitle }) => {
  const { t } = useTranslation();
  const { canRoleCreate, canRoleEdit, canRoleDelete } = useUserClaimStore();
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState<string | false>(false);
  const [pageState, setPageState] = useState<PageStateProp>();
  const [quizId, setQuizId] = useState<number | null>();
  const { data: { data: articlePages } = {} } =
    useGetAllMagazineArticlePagesQuery(articleId, {
      enabled: !!expanded,
    });
  //accordion
  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  //addNewPageModal
  const [addNewPageModalShow, setAddNewPageModalShow] = useState(false);
  const handleAddNewPageModalShow = () => {
    setAddNewPageModalShow(true);
  };

  //dropdownMenu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleDropDownMenuIconClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    pageState: PageStateProp
  ) => {
    setPageState(pageState);
    setAnchorEl(event.currentTarget);
  };
  const handleDropDownMenuClose = () => {
    setAnchorEl(null);
  };
  //deleteQuiz
  const [deleteQuizModalShow, setDeleteQuizModalShow] =
    useState<boolean>(false);
  const { mutate: deleteQuiz, isLoading, isSuccess, reset } = useDeleteQuiz();
  const handleDeleteQuiz = () => {
    deleteQuiz(quizId!, {
      onSuccess: () => {
        closeModalWithDelay(setDeleteQuizModalShow);
        reset();
      },
    });
  };

  return (
    <div className="page">
      <Accordion
        expanded={expanded === `panel-${articleId}`}
        onChange={handleAccordionChange(`panel-${articleId}`)}
        key={articleId}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              sx={!expanded ? { marginRight: "15px" } : { marginLeft: "15px" }}
            />
          }
          sx={{
            border: "1px solid #D9D8D9",
            marginBottom: "10px",
            borderRadius: "5px",
            fontWeight: "300",
          }}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography
            sx={{
              padding: "8px 15px",
            }}
          >
            {articleTitle}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="article-pages">
            <ul>
              {articlePages?.map((page, index) => {
                return (
                  <li key={index}>
                    <div>
                      <p
                        onClick={() =>
                          navigate(`/page-editor/articlePage/${page.id}`)
                        }
                        className="pageListItem"
                      >{`${page?.pageNumber}. ${page?.title}`}</p>
                      {page?.quizId !== null && (
                        <>
                          <div className="page-quiz">
                            <p>{t("dashboard.magazine.pages.quiz")}</p>
                            {canRoleDelete && (
                              <DeleteIcon
                                sx={{
                                  fontSize: "20px",
                                  color: "#FF832C",
                                  "&:hover": {
                                    color: "red",
                                  },
                                }}
                                onClick={() => {
                                  setDeleteQuizModalShow(true);
                                  setQuizId(page?.quizId);
                                }}
                              />
                            )}
                          </div>
                          <MuiModal
                            title={t(
                              "dashboard.magazine.pages.modals.delete_quiz"
                            )}
                            description={t(
                              "dashboard.magazine.pages.modals.are_you_sure_quiz"
                            )}
                            modalContent={
                              <Delete
                                isLoading={isLoading}
                                isSuccess={isSuccess}
                                deleteFunction={handleDeleteQuiz}
                                setModalShow={setDeleteQuizModalShow}
                              />
                            }
                            setAnchorEl={setAnchorEl}
                            modalShow={deleteQuizModalShow}
                            setModalShow={setDeleteQuizModalShow}
                          />
                        </>
                      )}
                    </div>
                    <button
                      type="button"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={(event) =>
                        handleDropDownMenuIconClick(event, {
                          pageId: page?.id,
                          pageTitle: page?.title,
                          hasQuiz: page?.quizId !== null,
                          quizId: page?.quizId,
                          pagePath: page?.pagePath,
                        })
                      }
                      style={{
                        border: "none",
                        backgroundColor: "transparent",
                        padding: "0",
                      }}
                    >
                      <MoreHorizIcon className="muiIcon" />
                    </button>
                    <Menu
                      id="demo-positioned-menu"
                      aria-labelledby="demo-positioned-button"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleDropDownMenuClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      sx={{
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.16) !important",
                      }}
                    >
                      {canRoleEdit && (
                        <EditPage
                          pageTitle={pageState?.pageTitle}
                          pagePath={pageState?.pagePath}
                          articleId={articleId}
                          pageId={pageState?.pageId}
                          setAnchorEl={setAnchorEl}
                        />
                      )}
                      {!pageState?.hasQuiz && canRoleCreate && (
                        <AddQuizMenuItem
                          articleId={articleId}
                          pageId={pageState?.pageId!}
                          setAnchorEl={setAnchorEl}
                        />
                      )}
                      {/* {pageState?.hasQuiz && (
                        <EditQuizMenuItem setAnchorEl={setAnchorEl} />
                      )} */}
                      {canRoleDelete && (
                        <DeletePage
                          pageId={pageState?.pageId}
                          setAnchorEl={setAnchorEl}
                        />
                      )}
                    </Menu>
                  </li>
                );
              })}
            </ul>
            {canRoleCreate && (
              <button
                type="button"
                className="article-pages-button"
                onClick={handleAddNewPageModalShow}
              >
                {t("dashboard.magazine.pages.modals.add_new_page")}
              </button>
            )}
          </div>
          <AddPageTitle
            pageNumber={articlePages?.length}
            articleId={articleId}
            modalShow={addNewPageModalShow}
            setModalShow={setAddNewPageModalShow}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
export default Page;
