import Joi from "joi";
import { validateFile } from "../../utils/fileValidation";

export const AddFolderFileSchema = Joi.object({
  FileName: Joi.string().required().messages({ "string.empty": "Required!" }),
  File: Joi.alternatives()
    .try(
      Joi.custom(
        (value, helpers) =>
          validateFile(
            value,
            5,
            [
              "application/pdf",
              "application/msword",
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              "application/vnd.ms-word.document.macroEnabled.12",
              "application/vnd.ms-excel.sheet.macroEnabled.12",
              "application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              "application/vnd.ms-excel",
              "application/vnd.ms-powerpoint",
              "application/vnd.openxmlformats-officedocument.presentationml.presentation",
              "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
            ],
            helpers
          ),
        "file-validate"
      )
    )
    .messages({
      "any.fileSize": "The maximum file size that can be uploaded is 5 MB.",
      "any.fileType": "This Type is not allowed!",
    })
    .required()
    .messages({ "any.required": "Required!" }),
});
