import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import "./_LoginPasswordOrganisms.scss";
import OtpField from "react-otp-field";
import {useForm} from "react-hook-form";
import {joiResolver} from "@hookform/resolvers/joi";
import {useNavigate} from "react-router-dom";
import {useGeneralStore} from "../../../zustand-store/General.store";
import {ResetPasswordInputModel} from "../../../interfaces/models/accountModels/ResetPassword.model";
import {ResetPasswordSchema} from "../../../schemas/accountSchemas/ResetPassword.schema";
import {usePostResetPassword} from "../../../react-query/hooks/mutationHooks/Account";
import {useErrorsStore} from "../../../zustand-store/Errors.store";
import StatusState from "../../molecules/StatusState/StatusState";
import Button from "../../atoms/Button/Button.tsx";
import {useTranslation} from "react-i18next";

const ChangePassword = () => {
    const {t} = useTranslation();
    const [confrimationCodeValue, setConfrimationCodeValue] = useState("");
    const {errors: serverResponseErrors, hookName} = useErrorsStore();
    const {email} = useGeneralStore();
    const {
        register,
        handleSubmit,
        setValue,
        formState: {errors},
    } = useForm<ResetPasswordInputModel>({
        resolver: joiResolver(ResetPasswordSchema(t)),
        defaultValues: {
            email: email,
        },
    });
    const navigate = useNavigate();
    const {
        mutate: resetPassword,
        isLoading,
        isSuccess,
        reset,
    } = usePostResetPassword();
    const handleResetPassword = (
        resetPasswordPayload: ResetPasswordInputModel
    ) => {
        resetPassword(resetPasswordPayload, {
            onSuccess: () => {
                setTimeout(() => {
                    reset();
                    navigate("/login");
                }, 2000);
            },
        });
    };

    useEffect(() => {
        if (confrimationCodeValue !== "") {
            setValue("resetPasswordCode", confrimationCodeValue, {
                shouldValidate: true,
            });
        }
    }, [confrimationCodeValue]);

    return (
        <form
            className="loginPasswordOrganisms"
            onSubmit={handleSubmit(handleResetPassword)}
        >
            <div className="loginPasswordOrganisms-header">
                <h1>{t("public.change_password.part2.title")}</h1>
                <p>{t("public.change_password.part2.subtitle")}</p>
            </div>
            <div className="loginPasswordOrganisms-inputs">
                <TextField
                    fullWidth
                    className="loginPasswordOrganisms-inputs-input"
                    id="outlined-basic"
                    label="Email Address"
                    color="success"
                    variant="outlined"
                    error={!!errors?.email}
                    helperText={errors?.email?.message}
                    {...register("email")}
                />
                <OtpField
                    value={confrimationCodeValue}
                    onChange={setConfrimationCodeValue}
                    numInputs={6}
                    isTypeNumber
                    inputProps={{
                        disabled: false,
                    }}
                    autoFocus={true}
                    classNames={`otp-field ${
                        !!errors?.resetPasswordCode ? "verificationCodeError" : ""
                    }`}
                />
                {!!errors?.resetPasswordCode && (
                    <p className="confirmationCodeErrorText">
                        {errors?.resetPasswordCode?.message}
                    </p>
                )}
                <TextField
                    fullWidth
                    className="loginPasswordOrganisms-inputs-input"
                    id="outlined-basic"
                    label="Password"
                    color="success"
                    type="password"
                    variant="outlined"
                    error={!!errors?.newPassword}
                    helperText={errors?.newPassword?.message}
                    {...register("newPassword")}
                />
                <TextField
                    fullWidth
                    className="loginPasswordOrganisms-inputs-input"
                    id="outlined-basic"
                    label="Password Confirmation"
                    color="success"
                    type="password"
                    variant="outlined"
                    error={!!errors?.confirmNewPassword}
                    helperText={errors?.confirmNewPassword?.message}
                    {...register("confirmNewPassword")}
                />
            </div>
            {isLoading && (
                <div className="loginPasswordOrganisms-buttons">
                    <StatusState statusType="loading" statusText="Please wait..."/>
                </div>
            )}
            {isSuccess && (
                <div className="loginPasswordOrganisms-buttons">
                    <StatusState
                        statusType="success"
                        statusText={t("public.change_password.part2.success_status")}
                    />
                </div>
            )}
            {!isLoading && !isSuccess && (
                <div className="loginPasswordOrganisms-buttons">
                    <Button
                        className="firstButton"
                        content={t("public.change_password.part2.reset_password")}
                        styleType="second"
                        type="submit"
                        fulllWidth
                    />
                    {serverResponseErrors !== undefined &&
                        hookName === "usePostResetPassword" && (
                            <div className="firstButton">
                                {serverResponseErrors.map((error) => {
                                    return (
                                        <StatusState
                                            statusType="error"
                                            statusText={error.message}
                                        />
                                    );
                                })}
                            </div>
                        )}
                    <button type="button" className="lastButton">
                        {t("public.change_password.part2.did_not_recive_code")}
                    </button>
                </div>
            )}
        </form>
    );
};

export default ChangePassword;
