import "./_ListItems.scss";
import folderIcon from "../../../assets/icons/folderIcon.png";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {FC, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useDeleteFolder} from "../../../react-query/hooks/mutationHooks/Folder";
import MuiModal from "../../../modals/MuiModal";
import Delete from "../../../modals/dashboard/Delete";
import AddFolder from "../../../modals/dashboard/AddFolder";
import {FoldersResponseModel} from "../../../interfaces/models/folderModels/FolderResponse.model";
import {convertDateFormat} from "../../../utils/covertDateFormat";
import {useUserClaimStore} from "../../../zustand-store/UserClaim.store";
import {useTranslation} from "react-i18next";

const Folder: FC<FoldersResponseModel> = ({
                                              id,
                                              name,
                                              folderFileCount,
                                              insertedDate,
                                          }) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {canRoleEdit, canRoleDelete} = useUserClaimStore();
    //deleteFolder
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const handleDeleteModalShow = () => setDeleteModalShow(true);
    const {mutate: deleteFolder, isLoading, isSuccess} = useDeleteFolder();
    const handleDeleteFolder = () => {
        deleteFolder(id);
    };

    //editFolder
    const [editModalShow, setEditModalShow] = useState(false);
    const handleEditModalShow = () => setEditModalShow(true);

  return (
    <tr className="listItem">
      <td onClick={() => navigate(`${id}`)} className="firstItem">
        <div className="listItem-filesNumber">
          <img src={folderIcon} alt="folder icon" />
          <div className="listItem-filesNumber-content">
            <h4>{name}</h4>
            <p style={{ textTransform: "lowercase" }}>{`${folderFileCount} ${t(
              "dashboard.files.files"
            )}`}</p>
          </div>
        </div>
      </td>
      <td
        className={`${!(canRoleEdit || canRoleDelete) ? "lastItem" : ""}`}
        onClick={() => navigate(`${id}`)}
      >
        <p>{convertDateFormat(insertedDate).date}</p>
      </td>
      {(canRoleDelete || canRoleEdit) && (
        <td className="lastItem actionsIcons">
          <div className="listItem-icons tables-item">
            {canRoleEdit && (
              <button
                type="button"
                onClick={handleEditModalShow}
                className="ghost-button cursor-pointer"
              >
                <EditIcon className="muiIcon" />
              </button>
            )}
            {canRoleDelete && (
              <button
                type="button"
                className="ghost-button cursor-pointer"
                onClick={handleDeleteModalShow}
              >
                <DeleteIcon className="muiIcon" />
              </button>
            )}
          </div>
        </td>
      )}
      <MuiModal
        title={t("dashboard.folders.modals.delete_folder")}
        description={t("dashboard.folders.modals.are_you_sure")}
        modalContent={
          <Delete
            isLoading={isLoading}
            isSuccess={isSuccess}
            deleteFunction={handleDeleteFolder}
            setModalShow={setDeleteModalShow}
          />
        }
        modalShow={deleteModalShow}
        setModalShow={setDeleteModalShow}
      />
      <MuiModal
        title={t("dashboard.folders.modals.update_folder")}
        description={t("dashboard.folders.modals.write_new_name")}
        modalContent={
          <AddFolder
            update
            folderId={id}
            folderTitle={name}
            setModalShow={setEditModalShow}
          />
        }
        modalShow={editModalShow}
        setModalShow={setEditModalShow}
      />
    </tr>
  );
};

export default Folder;
