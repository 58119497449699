import {joiResolver} from "@hookform/resolvers/joi";
import {ChangeEvent, FC, KeyboardEvent, useState} from "react";
import {useForm} from "react-hook-form";
import Button from "../../components/atoms/Button/Button.tsx";
import {CreateQuizInputModel} from "../../interfaces/models/quizModels/CreateQuiz.model";
import {CreateQuizSchema} from "../../schemas/quizSchemas/CreateQuiz.schema";
import CancelIcon from "@mui/icons-material/Cancel";
import {useCreateQuiz} from "../../react-query/hooks/mutationHooks/Quiz";
import StatusState from "../../components/molecules/StatusState/StatusState";
import {closeModalWithDelay} from "../../utils/closeModalWithDelay";
import {useTranslation} from "react-i18next";

interface AddQuizProp {
    pageId: number;
    setModalShow: (modalshow: boolean) => void;
}

const AddQuiz: FC<AddQuizProp> = ({pageId, setModalShow}) => {
    const {t} = useTranslation();
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        reset,
        formState: {errors},
    } = useForm<CreateQuizInputModel>({
        resolver: joiResolver(CreateQuizSchema),
        defaultValues: {
            articlePageId: pageId,
        },
    });
    const [correctOption, setCorrectOption] = useState<string>("");
    const [inCorrectOption, setInCorrectOption] = useState<string>("");
    const handleCorrectOptionInputFieldChanges = (
        e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
        setCorrectOption(e.target.value);
    };
    const handleInCorrectOptionInputFieldChanges = (
        e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
        setInCorrectOption(e.target.value);
    };

    const handleCorrectOptionInputFieldKeyDown = (
        e: KeyboardEvent<HTMLDivElement>
    ) => {
        if (e.key === "Enter") {
            e.preventDefault();
            handleAddCorrectOption(correctOption);
        }
    };
    const handleInCorrectOptionInputFieldKeyDown = (
        e: KeyboardEvent<HTMLDivElement>
    ) => {
        if (e.key === "Enter") {
            e.preventDefault();
            handleAddInCorrectOption(inCorrectOption);
        }
    };
    const handleAddCorrectOption = (option: string | undefined) => {
        if (option !== undefined) {
            if (
                option !== "" &&
                !watch("options")?.includes({option: option, isCorrect: true})
            ) {
                setValue("options", [
                    ...(watch("options") || []),
                    {option: correctOption ?? "", isCorrect: true},
                ]);
                setCorrectOption("");
            }
            if (watch("options")?.includes({option: option, isCorrect: true})) {
                setCorrectOption("");
            }
        }
    };
    const handleAddInCorrectOption = (option: string | undefined) => {
        if (option !== undefined) {
            if (
                option !== "" &&
                !watch("options")?.includes({option: option, isCorrect: false})
            ) {
                setValue("options", [
                    ...(watch("options") || []),
                    {option: inCorrectOption ?? "", isCorrect: false},
                ]);
                setInCorrectOption("");
            }
            if (watch("options")?.includes({option: option, isCorrect: true})) {
                setInCorrectOption("");
            }
        }
    };
    const handleRemoveOption = (optionToRemove: string) => {
        setValue(
            "options",
            watch("options")?.filter((option) => option.option !== optionToRemove)
        );
    };
    const handleCancel = () => {
        reset();
        setModalShow(false);
    };

    const {mutate: createMagine, isLoading, isSuccess} = useCreateQuiz();
    const handleCreateQuiz = (createQuizPayload: CreateQuizInputModel) => {
        createMagine(createQuizPayload, {
            onSuccess: () => {
                closeModalWithDelay(setModalShow);
            },
        });
    };
    return (
        <form
            className="muiModal-content"
            onSubmit={handleSubmit(handleCreateQuiz)}
        >
            <div className="muiModal-content-inputs">
                <div className="muiModal-content-inputs-input quizTypes">
                    <div>
                        <input
                            type="radio"
                            value="false"
                            {...register("popUpQuiz")}
                            id="button-quiz"
                        />
                        <label htmlFor="button-quiz">
                            {t("dashboard.magazine.pages.modals.button_quiz")}
                        </label>
                    </div>
                    <div>
                        <input
                            type="radio"
                            value="true"
                            defaultChecked
                            {...register("popUpQuiz")}
                            id="pop-quiz"
                        />
                        <label htmlFor="pop-quiz">
                            {" "}
                            {t("dashboard.magazine.pages.modals.popup_quiz")}
                        </label>
                    </div>
                </div>
                <p>{!!errors.popUpQuiz && errors.popUpQuiz?.message}</p>
                <input
                    type="text"
                    placeholder={t("dashboard.magazine.pages.modals.question")}
                    className="muiModal-content-inputs-input quizInput"
                    {...register("question")}
                />
                <div className="muiModal-content-inputs-input addChoise">
                    <p>{t("dashboard.magazine.pages.modals.right_answers")}</p>
                    <div className="addChoise-options">
                        {watch("options")?.map((option, index) => {
                            return (
                                !!option.isCorrect && (
                                    <div key={index} className="addChoise-options-option">
                                        <p>{option.option}</p>
                                        <CancelIcon
                                            className="icon"
                                            sx={{
                                                color: "rgba(0, 0, 0, 0.23)",
                                                fontSize: "18px",
                                                backgroundColor: "transparent",
                                                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                                                borderRadius: "500px",
                                                border: "none",
                                            }}
                                            onClick={() => handleRemoveOption(option.option)}
                                        />
                                    </div>
                                )
                            );
                        })}
                    </div>
                    <input
                        type="text"
                        className="muiModal-content-inputs-input quizInput"
                        placeholder={t("dashboard.magazine.pages.modals.add_choise")}
                        value={correctOption}
                        onChange={handleCorrectOptionInputFieldChanges}
                        onKeyDown={handleCorrectOptionInputFieldKeyDown}
                        style={{marginTop: "15px"}}
                    />
                </div>
                <div className="muiModal-content-inputs-input addChoise">
                    <p>{t("dashboard.magazine.pages.modals.wrong_answers")}</p>
                    <div className="addChoise-options">
                        {watch("options")?.map((option, index) => {
                            return (
                                !option.isCorrect && (
                                    <div key={index} className="addChoise-options-option">
                                        <p>{option.option}</p>
                                        <CancelIcon
                                            className="icon"
                                            sx={{
                                                color: "rgba(0, 0, 0, 0.23)",
                                                fontSize: "18px",
                                                backgroundColor: "transparent",
                                                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                                                borderRadius: "500px",
                                                border: "none",
                                            }}
                                            onClick={() => handleRemoveOption(option.option)}
                                        />
                                    </div>
                                )
                            );
                        })}
                    </div>
                    <input
                        type="text"
                        onChange={handleInCorrectOptionInputFieldChanges}
                        onKeyDown={handleInCorrectOptionInputFieldKeyDown}
                        style={{marginTop: "15px"}}
                        placeholder={t("dashboard.magazine.pages.modals.add_choise")}
                        className="muiModal-content-inputs-input quizInput"
                    />
                </div>
                <p className="pError">{!!errors.options && errors.options?.message}</p>
            </div>
            <div className="muiModal-content-status">
                {isLoading && (
                    <StatusState
                        statusText={t("reusable.loading")}
                        statusType="loading"
                    />
                )}
                {isSuccess && (
                    <StatusState
                        statusText={t(
                            "dashboard.magazine.pages.modals.success_quiz_post_status"
                        )}
                        statusType="success"
                    />
                )}
            </div>
            {!isSuccess && !isLoading && (
                <div className="muiModal-content-modalbuttons">
                    <Button
                        onClick={handleCancel}
                        content={t("dashboard.reusable.cancel")}
                        styleType="third"
                        type="button"
                    />
                    <Button
                        content={t("dashboard.reusable.save")}
                        styleType="second"
                        type="submit"
                    />
                </div>
            )}
        </form>
    );
};
export default AddQuiz;
