import {FC, MouseEvent, SyntheticEvent, useState} from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {useDeleteMediaFile} from "../../../react-query/hooks/mutationHooks/MediaFile";
import EditIcon from "@mui/icons-material/Edit";
import Delete from "../../../modals/dashboard/Delete";
import EditMediaFile from "../../../modals/dashboard/EditMediaFile";
import {useModalStore} from "../../../zustand-store/Modal.store";
import Snackbar from "@mui/material/Snackbar";
import MediaFilePreviewModal from "../../../modals/dashboard/MediaFilePreview/MediaFileItemPreview";
import {useUserClaimStore} from "../../../zustand-store/UserClaim.store";
import {useTranslation} from "react-i18next";
import {Box} from "@mui/material";
import {ImageNotSupported} from "@mui/icons-material";

interface MediaFileItemProps {
    fileId: number;
    isVideo: boolean;
    isImage: boolean;
    filePath: string;
    fileName: string;
    uploader: string;
    videoType?: string;
}

const MediaFileItem: FC<MediaFileItemProps> = ({
                                                   fileId,
                                                   isVideo,
                                                   isImage,
                                                   filePath,
                                                   fileName,
                                                   uploader,
                                                   videoType,
                                               }) => {
    const {t} = useTranslation();
    const [badImage, setBadImage] = useState<JSX.Element | undefined>();
    const [statusImage, setStatusImage] = useState(false);
    const {canRoleEdit, canRoleDelete} = useUserClaimStore();
    //filePreview modal
    const [filePreviewModalShow, setFilePreviewModalShow] =
        useState<boolean>(false);
    const handleMediaFileItemClick = () => {
        setFilePreviewModalShow(true);
    };

    //copy  to clipboard
    const [open, setOpen] = useState(false);
    const handleClickCopyUrl = (
        e: MouseEvent<SVGSVGElement, globalThis.MouseEvent>
    ) => {
        e.stopPropagation();
        navigator.clipboard.writeText(filePath);
        setOpen(true);
    };
    const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    //edit File
    const {setDescription, setTitle, setModalContent, setModalShow} =
        useModalStore();
    const handleEditFileTitle = (
        e: MouseEvent<SVGSVGElement, globalThis.MouseEvent>
    ) => {
        e.stopPropagation();
        setModalShow(true);
        setTitle("Update File");
        setDescription("Write new name of file");
        setModalContent(<EditMediaFile mediaFileId={fileId} fileName={fileName}/>);
    };

    //delete file
    const {mutate: deleteFile, isLoading, isSuccess} = useDeleteMediaFile();
    const handleDeleteFile = () => {
        deleteFile(fileId, {
            onSuccess: () => {
                setModalShow(false);
            },
        });
    };
    const handleDeleteFileModalShow = (
        e: MouseEvent<SVGSVGElement, globalThis.MouseEvent>
    ) => {
        e.stopPropagation();
        setModalShow(true);
        setTitle(t("dashboard.reusable.delete_file"));
        setDescription(t("dashboard.reusable.are_you_sure_file"));
        setModalContent(
            <Delete deleteFunction={handleDeleteFile} setModalShow={setModalShow}/>
        );
    };

    return (
        <>
            <Box bgcolor="white" position='relative' borderRadius={2} height={290} overflow="hidden" onClick={handleMediaFileItemClick}
                 className="shadow-sm cursor-pointer hover:shadow-md transition-all duration-300 hover:-translate-y-1 delay-100">
                {!statusImage &&
                    <Box minHeight={220} maxHeight={220} bgcolor="#e2e2e2"
                         className="mediaFileItem-content" overflow="hidden">
                        {!statusImage && <img src={filePath} alt={fileName}
                                              onError={() => setStatusImage(true)}/>}
                        {isVideo && (
                            <video controls autoPlay={false}>
                                <source src={filePath} type={`video/${videoType}`}/>
                            </video>
                        )}
                    </Box>}
                {statusImage &&
                    <Box bgcolor="#e2e2e2" color="#a5a5a5" minHeight={220} maxHeight={220}
                         display="flex"
                         justifyContent="center"
                         alignItems="center"
                         flexDirection="column" gap={2}>
                        <ImageNotSupported/>
                        <span className="broken-image-text">{t("dashboard.media_files.broken_image")}</span>
                    </Box>}
                <Box bgcolor="white" className="mediaFileItem-info" zIndex={20}>
                    <div className="part1">
                        <p>
                            <span>{t("dashboard.reusable.file_name")}:</span>
                            {fileName}
                        </p>
                        <p>
                            <span>{t("dashboard.media_files.created_by")}:</span>
                            {uploader}
                        </p>
                    </div>
                    <div>
                        {canRoleEdit && (
                            <EditIcon
                                className="muiIcon"
                                sx={{fontSize: "25px"}}
                                onClick={(e) => handleEditFileTitle(e)}
                            />
                        )}
                        {canRoleDelete && (
                            <DeleteIcon
                                className="muiIcon"
                                sx={{fontSize: "25px"}}
                                onClick={(e) => handleDeleteFileModalShow(e)}
                            />
                        )}
                    </div>
                </Box>
                <div className="mediaFileItem-copyIcon">
                    <ContentCopyIcon
                        sx={{
                            color: "rgba(255, 255, 255, 0.5)",
                            "&:hover": {color: "white"},
                            fontSize: "20px",
                        }}
                        onClick={(e) => handleClickCopyUrl(e)}
                    />
                </div>
            </Box>
            <Snackbar
                open={open}
                autoHideDuration={1500}
                onClose={handleClose}
                message={t("dashboard.media_files.modals.file_path_copied")}
            />
            <MediaFilePreviewModal
                modalShow={filePreviewModalShow}
                setModalShow={setFilePreviewModalShow}
                filePath={filePath}
                isVideo={isVideo}
                isImage={isImage}
                videoType={videoType}
                fileName={fileName}
            />
        </>
    );
};
export default MediaFileItem;
