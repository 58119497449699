import { ClaimValueResponse } from "../../interfaces/models/adminModels/ClaimValueResponse.model";

export function hasThisClaimType(
  claimValues: ClaimValueResponse[] | undefined,
  claimValue: string
) {
  if (claimValues !== undefined) {
    return claimValues.some((value) => value.claimValue === claimValue);
  }
}
