import { joiResolver } from "@hookform/resolvers/joi";
import TextField from "@mui/material/TextField";
import { FC, ReactElement } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ForgotPasswordInputModel } from "../../../interfaces/models/accountModels/ForgotPassword.model";
import { usePostForgotPassword } from "../../../react-query/hooks/mutationHooks/Account";
import { ForgotPasswordSchema } from "../../../schemas/accountSchemas/ForgotPassword.schema";
import { useGeneralStore } from "../../../zustand-store/General.store";
import Button from "../../atoms/Button/Button.tsx";
import StatusState from "../../molecules/StatusState/StatusState";
import "./_LoginPasswordOrganisms.scss";

interface ForgotPasswordProps {
  setStep: (step: number) => void;
}
const ForgotPassword: FC<ForgotPasswordProps> = ({ setStep }): ReactElement => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<ForgotPasswordInputModel>({
    resolver: joiResolver(ForgotPasswordSchema(t)),
  });
  const { mutate: forgotPassword, isLoading } = usePostForgotPassword();
  const { setEmail } = useGeneralStore();
  const handleForgotPasswordSubmit = (
    forgotPasswordPayload: ForgotPasswordInputModel
  ) => {
    forgotPassword(forgotPasswordPayload, {
      onSettled: () => {
        setEmail(getValues("email"));
        setStep(2);
      },
    });
  };
  return (
    <form
      className="loginPasswordOrganisms"
      onSubmit={handleSubmit(handleForgotPasswordSubmit)}
    >
      <div className="loginPasswordOrganisms-header">
        <h1>{t("public.change_password.part1.title")}</h1>
        <p>{t("public.change_password.part1.subtitle")}</p>
      </div>
      <div className="loginPasswordOrganisms-inputs">
        <TextField
          fullWidth
          className="loginPasswordOrganisms-inputs-input"
          id="outlined-basic"
          label={t("reusable.email")}
          color="success"
          variant="outlined"
          error={!!errors.email}
          helperText={errors.email?.message}
          {...register("email")}
        />
      </div>
      <div className="loginPasswordOrganisms-buttons">
        {isLoading ? (
          <div className="firstButton">
            <StatusState
              statusType="loading"
              statusText={t("reusable.loading")}
            />
          </div>
        ) : (
          <>
            <Button
              className="firstButton"
              content={t("public.change_password.part1.recover_password")}
              styleType="second"
              type="submit"
              fulllWidth
            />
          </>
        )}
      </div>
    </form>
  );
};
export default ForgotPassword;
