import {Box} from "@mui/material";
import {PropsWithChildren, ReactNode} from "react";

type Props = {
    /**
     * Title is specifically made for the header of the component
     * */
    title: string;
    /**
     * Options can vary it can have different approaches
     * */
    options?: ReactNode
}

/**
 * DataLayout is a reusable component its structure comes with a title, options and pre made styling
 * */
const DataLayout = ({title, options, children, ...rest}: PropsWithChildren<Props>) => (
    <Box padding={0.5} display="flex" flexDirection="column" gap={2} marginTop={2} marginBottom={2} {...rest}>
        <h1 style={{
            fontWeight: 500,
        }}>{title}</h1>
        <Box display="flex" justifyContent="space-between" alignItems="center" gap={2}>
            {options && options}
        </Box>
        <Box display="flex" gap={2} flexDirection="column">
            {children}
        </Box>
    </Box>
);

export default DataLayout;