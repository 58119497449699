import decode from "jwt-decode";
import { JWT_KEY } from "../../config/app";
import { JwtModel } from "../../interfaces/models/Jwt.model";
import { readFromStorage, removeFromStorage, writeToStorage } from "./storage";

// read auth token from localstorage
export const readAuthenticationToken = (): string | null =>
  readFromStorage(JWT_KEY);

// write auth token from localstorage
export const writeAuthenticationToken = (token: string): void => {
  writeToStorage(JWT_KEY, token);
};

// remove auth token from localstorage
export const removeAuthenticationToken = (): void => {
  removeFromStorage(JWT_KEY);
};

export const validateAuthenticationToken = (token: string) => {
  if (token) {
    try {
      const decoded = decode<JwtModel>(token);
      const now = Date.now() / 1000;
      return now <= decoded.exp;
    } catch (err) {
      console.log("invalid token format", err);
      return false;
    }
  }
  return false;
};
