import {ReactElement, useEffect} from "react";
import "./_LoginPasswordLayout.scss";
import mainLogo from "../../assets/icons/mainLogo.png";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {useErrorsStore} from "../../zustand-store/Errors.store";
import {readAuthenticationToken} from "../../utils/storage/authentication";

export function LoginPasswordLayout({
                                        formToComplite,
                                    }: {
    formToComplite: ReactElement;
}): ReactElement {
    const location = useLocation();
    const navigate = useNavigate();
    const {reset} = useErrorsStore();
    useEffect(() => {
        reset();
        !!readAuthenticationToken() && navigate("/dashboard");
    }, [location.pathname]);
    return (
        <div className="loginPassswordLayout">
            <NavLink to="/">
                <img src={mainLogo} alt="Patro logo" className="loginPassswordLayout-mainLogo"/>
            </NavLink>
            <div className="loginPassswordLayout-content">{formToComplite}</div>
        </div>
    );
}
