import "./_Footer.scss";
import mainLogo from "../../assets/icons/mainLogo.png";
import {NavLink} from "react-router-dom";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import ShareIcon from "@mui/icons-material/Share";
import {t} from "i18next";
import {RWebShare} from "react-web-share";

const Footer = () => {
    const currentYear: number = new Date().getFullYear();
    const iconStyle = {
        "&:hover": {
            color: "#FEED24",
        },
    };

    const shareData = {
        title: "Patro",
        url: "https://revuelepatro.be/",
    };

    const shareLink = async () => {
        try {
            await navigator.share(shareData);
        } catch (err) {
            console.log(err);
        }
    };
    return (
        <footer>
            <div className="footerMobileView">
                <NavLink to="/" className="interactive-button interactive-cursor center">
                    <img src={mainLogo} alt="Patro logo" className="icon"/>
                </NavLink>
                <div className="links">
                    <NavLink to="/">{t("public.reusable.home")}</NavLink>
                    <NavLink to="/magazines">{t("reusable.magazines")}</NavLink>
                    <NavLink to="/contact">{t("public.reusable.contact")}</NavLink>
                    <NavLink to="/subscribe">{t("public.reusable.subscribe")}</NavLink>
                    <a href="https://patro.be/politique-de-confidentialite/">
                        {t("public.footer.privacy_policy")}
                    </a>
                    <a href="https://patro.be/politique-de-confidentialite/">
                        {t("public.footer.terms_and_conditions")}
                    </a>
                </div>
                <div className="copyright">
                    <p>{`Copyright - Patro - ${currentYear}`}</p>
                    <div className="copyright-icons">
                        <a
                            href="https://www.instagram.com/patrofnp/"
                            style={{textDecoration: "none", color: "white"}}
                        >
                            <InstagramIcon sx={iconStyle}/>
                        </a>

                        <a
                            href="https://www.facebook.com/LePatro"
                            style={{textDecoration: "none", color: "white"}}
                        >
                            <FacebookIcon sx={iconStyle}/>
                        </a>
                        <button type="button" onClick={() => shareLink()}>
                            <ShareIcon sx={iconStyle}/>
                        </button>
                    </div>
                </div>
            </div>
            <div className="footerDesktopView userPagesScreen">
                <div className="footerDesktopView-part1 ">
                    <NavLink to="/" className="interactive-button interactive-cursor center">
                        <img src={mainLogo} alt="Patro logo"/>
                    </NavLink>
                    <p>{`Copyright - Patro - ${currentYear}`}</p>
                </div>
                <div className="footerDesktopView-part2">
                    <div className="links">
                        <NavLink to="/">{t("public.reusable.home")}</NavLink>
                        <NavLink to="/magazines">{t("reusable.magazines")}</NavLink>
                        <NavLink to="/contact">{t("public.reusable.contact")}</NavLink>
                        <NavLink to="/subscribe">{t("public.reusable.subscribe")}</NavLink>
                        <a href="https://patro.be/politique-de-confidentialite/">
                            {t("public.footer.privacy_policy")}
                        </a>
                        <a href="https://patro.be/politique-de-confidentialite/">
                            {t("public.footer.terms_and_conditions")}
                        </a>
                    </div>
                    <div className="icons">
                        <a
                            href="https://www.instagram.com/patrofnp/"
                            style={{textDecoration: "none", color: "white"}}
                        >
                            <InstagramIcon sx={iconStyle}/>
                        </a>
                        <a
                            href="https://www.facebook.com/LePatro"
                            style={{textDecoration: "none", color: "white"}}
                        >
                            <FacebookIcon sx={iconStyle}/>
                        </a>
                        <button type="button" className="ghost-button interactive-button interactive-cursor icons">
                            <RWebShare data={shareData}>
                                <ShareIcon sx={iconStyle}/>
                            </RWebShare>
                        </button>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
