import mainLogo from '../../assets/icons/mainLogo.png';
import { NavLink, useParams } from 'react-router-dom';
import { usePutEditMagazineArticlePage } from '../../react-query/hooks/mutationHooks/Magazine';
import { useErrorsStore } from '../../zustand-store/Errors.store';
import StatusState from '../../components/molecules/StatusState/StatusState';
import { useGetMagazineArticlePageById } from '../../react-query/hooks/queriesHooks/Magazine';
import LoadingStatus from '../../components/organisms/LoadingStatus/LoadingStatus';
import WebBuilder from './WebBuilder';
import { useGetAllMediaFilesQuery } from '../../react-query/hooks/queriesHooks/MediaFile';
import { stringToBase64 } from '../../utils/stringToBase64';
import { base64ToString } from '../../utils/base64ToString';
import { useQueryClient } from '@tanstack/react-query';
import 'grapesjs/dist/css/grapes.min.css';

export default function PageEditor() {
  const queryClient = useQueryClient();
  const { magazineArticlePageId } = useParams();
  const { errors: serverResponseErrors, hookName } = useErrorsStore();

  const { data: { data: articlePage } = {}, isLoading: isGetPageLoading } =
    useGetMagazineArticlePageById(Number(magazineArticlePageId));

  const {
    mutate: editMagazineArticlePage,
    isLoading,
    isSuccess,
    reset,
  } = usePutEditMagazineArticlePage(Number(magazineArticlePageId));

  const { data: { data: files } = {}, isLoading: isLoadingMedia } =
    useGetAllMediaFilesQuery();

  const handleSaveMagazine = (html: string, css: string) => {
    if (articlePage) {
      editMagazineArticlePage(
        {
          title: articlePage?.title,
          pageNumber: articlePage?.pageNumber,
          pageContent: {
            cssContent: stringToBase64(css),
            htmlContent: stringToBase64(html),
          },
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(['getMagazineArticlePageById']);
            setTimeout(() => {
              reset();
            }, 2000);
          },
        }
      );
    }
  };
  let htmlContent;
  let cssContent;

  try {
    htmlContent = base64ToString(articlePage?.pageContent?.htmlContent ?? '');
  } catch (e) {
    console.error('Failed to decode HTML content:', e);
  }
  try {
    cssContent = base64ToString(articlePage?.pageContent?.cssContent ?? '');
  } catch (e) {
    console.error('Failed to decode CSS content:', e);
  }

  if (isLoadingMedia || isGetPageLoading) return <LoadingStatus />;

  return (
    <>
      <div className='pageEditor'>
        <div className='pageEditor-header'>
          <NavLink to='/'>
            <img src={mainLogo} alt='' className='icon' />
          </NavLink>

          {serverResponseErrors !== undefined &&
            hookName === 'usePutEditMagazineArticlePage' && (
              <>
                {serverResponseErrors.map((error) => {
                  return (
                    <div className='status'>
                      <StatusState
                        statusType='error'
                        statusText={error.message}
                      />
                    </div>
                  );
                })}
              </>
            )}
          {isSuccess && (
            <div className='status'>
              <StatusState
                statusType='success'
                statusText='Your changes have been saved!'
              />
            </div>
          )}
        </div>
        <div className='pageEditor-body'>
          <div className='pageEditor-body-content'>
            <div className='buttons'></div>
            <div className='editor'>
              <WebBuilder
                id='grapejs-react'
                plugins={[
                  'gjs-preset-webpage',
                  'gjs-blocks-basic',
                  'gjs-blocks-basic',
                  'grapesjs-plugin-forms',
                  'grapesjs-component-countdown',
                  'grapesjs-plugin-export',
                  'grapesjs-tabs',
                  'grapesjs-custom-code',
                  'grapesjs-touch',
                  'grapesjs-parser-postcss',
                  'grapesjs-tooltip',
                  'grapesjs-tui-image-editor',
                  'grapesjs-typed',
                  'grapesjs-style-bg',
                  'grapesjs-preset-webpage',
                ]}
                assetManager={{
                  assets:
                    files &&
                    files.map((file) => ({
                      type: 'image',
                      src: file.filePath,
                      height: 350,
                      width: 250,
                      name: file.fileName,
                    })),
                }}
                isLoading={isLoading}
                onSave={handleSaveMagazine}
                storageManager={{
                  autosave: false,
                  autoload: true,
                }}
                components={
                  articlePage?.pageContent?.htmlContent
                    ? htmlContent
                    : `<div class="my-component"><div class="my-component__bg-image"></div><div class="my-component__content"><div class="my-component__title">Title</div><div class="my-component__image"></div><div class="my-component__description">Description</div></div></div>`
                }
                style={
                  articlePage?.pageContent?.cssContent
                    ? cssContent
                    : ` .my-component {
                  display: flex;
                  flex-direction: column;
                  background-color: #f0f0f0;
                }
                .my-component__bg-image {
                  background-image: url('https://example.com/bg-image.jpg');
                  background-size: cover;
                  height: 300px;
                }
                .my-component__content {
                  flex: 1;
                  padding: 20px;
                }
                .my-component__title {
                  font-size: 32px;
                  font-weight: bold;
                  margin-bottom: 20px;
                }
                .my-component__image {
                  background-image: url('https://example.com/image.jpg');
                  background-size: cover;
                  height: 200px;
                  margin-bottom: 20px;
                }
                .my-component__description {
                  font-size: 18px;
                }`
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
