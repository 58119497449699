import "./_DashboardNavbar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FolderIcon from "@mui/icons-material/Folder";
import PlayLessonIcon from "@mui/icons-material/PlayLesson";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import PersonIcon from "@mui/icons-material/Person";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import BarChartIcon from "@mui/icons-material/BarChart";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import AccordionDetails from "@mui/material/AccordionDetails";
import {SyntheticEvent, useState} from "react";
import mainLogo from "../../../assets/icons/mainLogo.png";
import {useAuthenticationStore} from "../../../zustand-store/Authentication.store";
import {removeAuthenticationToken} from "../../../utils/storage/authentication";
import {useUserClaimStore} from "../../../zustand-store/UserClaim.store";
import {useTranslation} from "react-i18next";

const DashboardNavbar = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {allowedRoutes} = useUserClaimStore();
  const {pathname} = useLocation();
  const [expanded, setExpanded] = useState<string | false>(false);
  const handleChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const {removeAuthenticatedUser} = useAuthenticationStore();

  const handleLogout = () => {
    navigate("/");
    removeAuthenticatedUser();
    removeAuthenticationToken();
  };

  return (
    <div className="dashboardNavbar">
      <NavLink to="/" className="dashboardNavbar-logo ghost-button interactive-button">
        <img src={mainLogo} alt="patro logo"/>
      </NavLink>
      <div className="dashboardNavbar-navlinks">
        {allowedRoutes?.includes("Dashboard") && (
          <NavLink to="" className="dashboardNavbar-navlink group" end>
            <div className="item">
              <DashboardIcon/>
              <h4 className="group-hover:colorize">{t("dashboard.reusable.dashboard")}</h4>
            </div>
          </NavLink>
        )}
        {allowedRoutes?.includes("Files") && (
          <NavLink to="folders" className="dashboardNavbar-navlink group">
            <div className="item">
              <FolderIcon/>
              <h4 className="group-hover:colorize">
                {t("dashboard.reusable.folders")}
              </h4>
            </div>
          </NavLink>
        )}

        {allowedRoutes?.includes("MediaFile") && (
          <NavLink to="media-files" className="dashboardNavbar-navlink group">
            <div className="item">
              <PlayLessonIcon/>
              <h4 className="group-hover:colorize">{t("dashboard.reusable.media_files")}</h4>
            </div>
          </NavLink>
        )}
        {allowedRoutes?.includes("Magazines") && (
          <NavLink to="magazines" className="dashboardNavbar-navlink group">
            <div className="item">
              <TextSnippetIcon/>
              <h4 className="group-hover:colorize">{t("dashboard.reusable.magazines")}</h4>
            </div>
          </NavLink>
        )}
        {(allowedRoutes?.includes("Authorizations") ||
          allowedRoutes?.includes("Roles")) && (
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}

          >
            <AccordionSummary
              aria-controls="panel1bh-content"
              id="panel1bh-header"

            >
              <div className="dashboardNavbar-navlink group">
                <div className={`item ${pathname.includes('authorisations') && 'active'}`}>
                  <LibraryAddIcon/>
                  <h4 className="group-hover:colorize">{t("dashboard.reusable.authorisations")}</h4>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              {allowedRoutes?.includes("Authorizations") && (
                <NavLink
                  to="authorisations/premissions"
                  className="dashboardNavbar-navlinkChild parent"
                >
                  <div className="itemChild child">
                    <FiberManualRecordIcon sx={{fontSize: "8px"}}/>
                    <h5>{t("dashboard.reusable.premissions")}</h5>
                  </div>
                </NavLink>
              )}
              {allowedRoutes?.includes("Roles") && (
                <NavLink
                  to="authorisations/roles"
                  className="dashboardNavbar-navlinkChild"
                >
                  <div className="itemChild">
                    <FiberManualRecordIcon sx={{fontSize: "8px"}}/>
                    <h5>{t("dashboard.reusable.roles")}</h5>
                  </div>
                </NavLink>
              )}
            </AccordionDetails>
          </Accordion>
        )}
        {allowedRoutes?.includes("Users") && (
          <NavLink to="users" className="dashboardNavbar-navlink group">
            <div className="item">
              <PersonIcon/>
              <h4 className="group-hover:colorize">{t("dashboard.reusable.users")}</h4>
            </div>
          </NavLink>
        )}

        {allowedRoutes?.includes("Newsletters") && (
          <NavLink to="newsletters" className="dashboardNavbar-navlink group">
            <div className="item">
              <NewspaperIcon/>
              <h4 className="group-hover:colorize">{t("dashboard.reusable.newsletters")}</h4>
            </div>
          </NavLink>
        )}
        {allowedRoutes?.includes("Statistics") && (
          <NavLink to="statistics" className="dashboardNavbar-navlink group">
            <div className="item">
              <BarChartIcon/>
              <h4 className="group-hover:colorize">{t("dashboard.reusable.statictics")}</h4>
            </div>
          </NavLink>
        )}
        <NavLink to="settings" className="dashboardNavbar-navlink group">
          <div className="item">
            <SettingsIcon/>
            <h4 className="group-hover:colorize">{t("dashboard.reusable.settings")}</h4>
          </div>
        </NavLink>
        <button
          type="button"
          className="dashboardNavbar-logout"
          onClick={handleLogout}
        >
          <LogoutIcon/>
          <h4 className="group-hover:colorize">{t("dashboard.log_out")}</h4>
        </button>
      </div>
    </div>
  );
};

export default DashboardNavbar;
