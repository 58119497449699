import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import {ChangeEvent, useEffect, useState} from "react";
import ListPermissions from "../../components/molecules/ListsItems/Permissions";
import LoadingStatus from "../../components/organisms/LoadingStatus/LoadingStatus";
import {
    useGetAllClaimTypesQuery,
    useGetAllRolesQuery,
    useGetRoleByIdQuery,
} from "../../react-query/hooks/queriesHooks/Admin";
import {getClaimValues} from "../../utils/claimsManagment/getClaimValues";
import "./_Permissions.scss";
import {useTranslation} from "react-i18next";
import DataLayout from "../../components/atoms/DataLayout/DataLayout";
import {Box} from "@mui/material";

const Permissions = () => {
    const {t} = useTranslation();
    const {data: {data: roles = []} = {}} = useGetAllRolesQuery("");
    const {data: {data: claimTypes} = {}, isLoading} =
        useGetAllClaimTypesQuery();

    const [roleId, setRoleId] = useState<string>();

    const {data: {data: rolePermissions} = {}} = useGetRoleByIdQuery(
        roleId!,
        {
            enabled: roleId !== undefined,
        }
    );

    const handleRoleChange = (
        e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
        setRoleId(e.target.value);
    };

    useEffect(() => {
        roles && setRoleId(roles[0]?.roleId);
    }, [roles]);

    return (
        <DataLayout title={t("dashboard.reusable.premissions")}
                    options={
                        <Box display="flex" justifyContent="right" width="100%" alignItems="center">
                            <TextField
                                fullWidth
                                select
                                label={t("dashboard.reusable.role")}
                                color="success"
                                value={roleId ?? ""}
                                onChange={handleRoleChange}
                                className="premissionsSelect"
                            >
                                {roles?.map((role) => {
                                    return (
                                        <MenuItem value={role?.roleId} key={role?.roleId}>
                                            {role.name}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        </Box>
                    }>
            {isLoading ? (
                <LoadingStatus/>
            ) : (
                <Box position="relative" className="pagesStyle-tables scroll-group" minHeight={400}
                     padding={2}
                     boxShadow="0 0 1px rgba(0, 0, 0, 0.15)">
                    <Box overflow="auto" className="scroll-group-hover:no-scrollbar">
                        <table>
                            <thead>
                            <tr className='no-hover'>
                                <th className="firstItem">
                                    {t("dashboard.authorisations.reusable.function")}
                                </th>
                                <th> {t("dashboard.authorisations.premissions.read")}</th>
                                <th> {t("dashboard.authorisations.premissions.create")}</th>
                                <th> {t("dashboard.authorisations.premissions.edit")}</th>
                                <th> {t("dashboard.authorisations.premissions.delete")}</th>
                            </tr>
                            </thead>
                            {claimTypes && (
                                <tbody>
                                {claimTypes?.map((item) => {
                                    return (
                                        <ListPermissions
                                            key={item.claimTypeId}
                                            roleId={roleId ?? ""}
                                            claimValue={
                                                getClaimValues(
                                                    rolePermissions?.roleClaims,
                                                    item.claimType
                                                )?.claimValue
                                            }
                                            claimType={item.claimType}
                                            claimTypeId={item.claimTypeId}
                                        />
                                    );
                                })}
                                </tbody>
                            )}
                        </table>
                    </Box>
                </Box>
            )}
        </DataLayout>
    );
};

export default Permissions;
