import { AddFolderInputModel } from "../../interfaces/models/folderModels/AddFolder.model";
import { EditFolderInputModel } from "../../interfaces/models/folderModels/EditFolder.model";
import { EditFolderFileInputModel } from "../../interfaces/models/folderModels/EditFolderFile.model";
import axios from "../../initializations/axios";

//-----POST MUTATION-----
export const postCreateFolder = async (
  createFolderPayload: AddFolderInputModel
): Promise<any> => {
  const { data } = await axios.post("Folder/CreateFolder", createFolderPayload);
  return data;
};
export const postAddFolderFile = async (
  FolderId: number,
  addFolderFilePayload: FormData
): Promise<any> => {
  const { data } = await axios.post(
    `Folder/AddFolderFile/${FolderId}`,
    addFolderFilePayload,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
  return data;
};
//-----PUT MUTATION-----
export const putEditFolder = async (
  folderId: number,
  editFolderPayload: EditFolderInputModel
): Promise<any> => {
  const { data } = await axios.put(
    `Folder/EditFolder/${folderId}`,
    editFolderPayload
  );
  return data;
};
export const putEditFolderFile = async (
  folderFileId: number,
  editFolderPayload: EditFolderFileInputModel
): Promise<any> => {
  const { data } = await axios.put(
    `Folder/EditFolderFile/${folderFileId}`,
    editFolderPayload
  );
  return data;
};
//-----DELETE MUTATION-----
export const deleteDeleteFolder = async (folderId: number): Promise<any> => {
  const { data } = await axios.delete(`Folder/DeleteFolder/${folderId}`);
  return data;
};
export const deleteDeleteFolderFile = async (
  folderFileId: number
): Promise<any> => {
  const { data } = await axios.delete(
    `Folder/DeleteFolderFile/${folderFileId}`
  );
  return data;
};
