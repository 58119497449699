import Switch from "@mui/material/Switch";
import { FC, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import {
  usePutPublishMagazine,
  usePutUnPublishMagazine,
} from "../../../react-query/hooks/mutationHooks/Magazine";
import { useUserClaimStore } from "../../../zustand-store/UserClaim.store";
import { useTranslation } from "react-i18next";

interface magazinePublishmentProps {
  isPublished: boolean;
}
const MagazinePublishment: FC<magazinePublishmentProps> = ({ isPublished }) => {
  const { t } = useTranslation();
  const { canRoleEdit } = useUserClaimStore();
  const { magazineId } = useParams();
  const [published, setPublished] = useState(isPublished);
  const { mutate: publishMagazine } = usePutPublishMagazine(Number(magazineId));
  const { mutate: unPublishMagazine } = usePutUnPublishMagazine(
    Number(magazineId)
  );
  const handleChange = useCallback(() => {
    setPublished(!published);
    if (published) {
      unPublishMagazine();
    } else {
      publishMagazine();
    }
  }, [published, unPublishMagazine, publishMagazine]);
  return (
    <div className="magazineStatus">
      <div className="status">
        <h4>{t("dashboard.magazine.status")}</h4>
        <div
          className={`${
            isPublished ? "publishtMagazine" : "unPublishtMagazine "
          } dot`}
        ></div>
      </div>
      <p>{t("dashboard.magazine.magazine_status")}</p>
      <div className="published">
        <h4>{t("dashboard.magazine.published")}</h4>
        <Switch
          checked={published}
          onChange={handleChange}
          size="small"
          color="success"
          inputProps={{ "aria-label": "controlled" }}
          disabled={!canRoleEdit}
        />
      </div>
      <p>{t("dashboard.magazine.change_publish_status")}</p>
    </div>
  );
};

export default MagazinePublishment;
