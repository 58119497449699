import { useCallback, useEffect, useState } from "react";
import { useGetMyProfileQuery } from "../react-query/hooks/queriesHooks/Account";
import {
  readAuthenticationToken,
  removeAuthenticationToken,
  validateAuthenticationToken,
} from "../utils/storage/authentication";
import { useAuthenticationStore } from "../zustand-store/Authentication.store";

export const useAuthenticationInit = () => {
  // local state
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // hooks
  const { removeAuthenticatedUser } = useAuthenticationStore();

  const { refetch } = useGetMyProfileQuery();

  // authentication token
  const jwtToken = readAuthenticationToken();
  // handlers
  const handleFetchUser = useCallback(async () => {
    await refetch();
    setIsLoading(false);
  }, [refetch]);

  // effect
  useEffect(() => {
    if (validateAuthenticationToken(jwtToken ?? "")) {
      handleFetchUser();
    } else {
      removeAuthenticationToken();
      removeAuthenticatedUser();
      setIsLoading(false);
    }
  }, [handleFetchUser, removeAuthenticatedUser, jwtToken]);
  return { isLoading };
};
