import Joi from "joi";

export const CreateQuizSchema = Joi.object({
  articlePageId: Joi.number()
    .required()
    .messages({ "any.required": "Required!" }),
  question: Joi.string().required().messages({ "string.empty": "Required!" }),
  popUpQuiz: Joi.boolean().required().messages({ "any.required": "Required!" }),
  options: Joi.array()
    .required()
    .messages({ "any.required": "Please provide at least one option!" })
    .items(
      Joi.object({
        option: Joi.string().required(),
        isCorrect: Joi.boolean().required(),
      })
    ),
});
