import './_PrivacyAndPolicy.scss';

const PrivacyAndPolicy = () => {
  return (
    <div>
      <h1>Privacy And Policy</h1>
    </div>
  );
};
export default PrivacyAndPolicy;
