import {FC, useState} from "react";
import EditIcon from "@mui/icons-material/Edit";
import MenuItem from "@mui/material/MenuItem";
import AddPageTitle from "../../../modals/dashboard/AddPageTitle/AddPageTitle";
import {useTranslation} from "react-i18next";

interface EditPageProps {
    articleId: number;
    pageId?: number;
    pageTitle?: string;
    pagePath?: string;
    setAnchorEl: (value: null | HTMLElement) => void;
}

const EditPage: FC<EditPageProps> = ({
                                         articleId,
                                         pageId,
                                         pageTitle,
                                         pagePath,
                                         setAnchorEl,
                                     }) => {
    const {t} = useTranslation();
    const [modalShow, setModalShow] = useState(false);
    const handleModalShow = () => setModalShow(true);
    return (
        <>
            <MenuItem
                onClick={handleModalShow}
                sx={{
                    color: "#CCC",
                    minWidth: "200px",
                    "&:hover": {
                        color: "#707070",
                    },
                }}
            >
                <EditIcon sx={{marginRight: "10px"}}/>
                {t("dashboard.magazine.pages.modals.edit_page_title")}
            </MenuItem>
            <AddPageTitle
                articleId={articleId}
                pageId={pageId}
                pageTitle={pageTitle}
                pagePath={pagePath}
                update
                setModalShow={setModalShow}
                modalShow={modalShow}
            />
        </>
    );
};
export default EditPage;
