import './_TermsOfServices.scss';

const TermsOfServices = () => {
  return (
    <div>
      <h1>Terms Of Services</h1>
    </div>
  );
};
export default TermsOfServices;
