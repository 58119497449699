import axios from '../../initializations/axios';
import { basicParamsTypes } from '../../interfaces/models/basicParams.model';
import { sizeOfItemsPerPage } from '../../static/sizeOfItemsPerPage';

export const getAllMediaFiles = async (
  getAllMediaFilesParams?: basicParamsTypes
): Promise<any> => {
  const { data } = await axios.get('MediaFile/GetAllMediaFiles', {
    params: {
      searchWord: getAllMediaFilesParams?.searchWord,
      pageSize: sizeOfItemsPerPage,
      pageNumber: getAllMediaFilesParams?.pageNumber,
    },
  });
  return data;
};
export const getMediaFileById = async (magazineFileId: number) => {
  const { data } = await axios.get(
    `MediaFile/GetMediaFileById/${magazineFileId}`
  );
  return data;
};
