import { ReactElement } from 'react';
import { create } from 'zustand';

interface ModalStore {
  modalShow: boolean;
  setModalShow: (modalShow: boolean) => void;
  title: string;
  setTitle: (title: string) => void;
  description: string;
  setDescription: (description: string) => void;
  modalContent: ReactElement | null;
  setModalContent: (modalContent: ReactElement) => void;
}

export const useModalStore = create<ModalStore>((set) => ({
  modalShow: false,
  setModalShow: (modalShow: boolean) => set(() => ({ modalShow })),
  title: '',
  setTitle: (title: string) => set(() => ({ title })),
  description: '',
  setDescription: (description: string) => set(() => ({ description })),
  modalContent: null,
  setModalContent: (modalContent: ReactElement) =>
    set(() => ({ modalContent })),
}));
