import Joi, { allow } from "joi";
import { validateFile } from "../../utils/fileValidation";

export const CreateMagazineSchema = Joi.object({
  Title: Joi.string().required().messages({ "string.empty": "Required!" }),
  Description: Joi.string()
    .max(500)
    .required()
    .messages({
      "string.empty": "Required!",
      "string.max": "Description must be max 500 letteres",
    }),
  EditorialNumber: Joi.string()
    .required()
    .messages({ "string.empty": "Required!" }),
  // HasQuizes: Joi.boolean().required().messages({ "any.required": "Required!" }),
  Cover: Joi.alternatives()
    .try(
      Joi.custom(
        (value, helpers) =>
          validateFile(
            value,
            5,
            ["image/png", "image/jpeg", "image/jpg"],
            helpers
          ),
        "file-validate"
      )
    )
    .messages({
      "any.fileSize":
        "The maximum file size that can be uploaded as an image is 5 MB.",
      "any.fileType": "This Type is not allowed!",
    })
    .required()
    .messages({ "any.required": "Required!" }),
  MagazinePdf: Joi.alternatives()
    .try(
      Joi.custom((value, helpers) => {
        return validateFile(value, 1000000000000, ["application/pdf"], helpers);
      }, "file-validate")
    )
    .optional()
    .allow(null)
    .messages({
      "any.fileSize":
        "The maximum file size that can be uploaded as an pdf is 10 MB.",
    }),
});
