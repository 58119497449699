import ErrorIcon from "@mui/icons-material/Error";
import { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDeleteDeactivateMyAccount } from "../../../react-query/hooks/mutationHooks/Account";
import { useAuthenticationStore } from "../../../zustand-store/Authentication.store";
import Button from "../../atoms/Button/Button.tsx";
import StatusState from "../../molecules/StatusState/StatusState";
import { useTranslation } from "react-i18next";

const DeactivateAccount = () => {
  const { t } = useTranslation();
  const { removeAuthenticatedUser } = useAuthenticationStore();
  const navigate = useNavigate();
  const [isCheckboxInputChecked, setIsCheckboxInputChecked] = useState(false);
  const handleCheckboxInput = (e: ChangeEvent<HTMLInputElement>) => {
    setIsCheckboxInputChecked(e.target.checked);
  };
  const {
    mutate: deactivateAccount,
    isLoading,
    isError,
  } = useDeleteDeactivateMyAccount();
  const handleDeactivateAccount = () => {
    deactivateAccount(undefined, {
      onSuccess: () => {
        removeAuthenticatedUser();
        navigate("/");
      },
    });
  };
  return (
    <div className="settingsOrganismsStyle">
      <h3 className="settingsOrganismsStyle-title">
        {t("dashboard.settings.deactivate_acc")}
      </h3>
      <div className="settingsOrganismsStyle-inputs">
        <div className="warning">
          <ErrorIcon sx={{ fontSize: " 30px", color: "#FBEAB1" }} />
          <div className="warning-content">
            <h4>{t("dashboard.settings.deactivating")}</h4>
            <p>{t("dashboard.settings.deactivating_info")}</p>
          </div>
        </div>
        {!isLoading && (
          <div className="deactivateAccountInput">
            <input
              type="checkbox"
              name=""
              id="deactivateAccount"
              onChange={(e) => handleCheckboxInput(e)}
            />
            <label htmlFor="deactivateAccount">
              {t("dashboard.settings.deactivating_confirm")}
            </label>
          </div>
        )}
      </div>
      {isLoading && (
        <div className="settingsOrganismsStyle-status">
          <StatusState
            statusText={t("reusable.loading")}
            statusType="loading"
          />
        </div>
      )}
      {!isLoading && (
        <div className="settingsOrganismsStyle-buttons">
          <Button
            onClick={handleDeactivateAccount}
            content={t("dashboard.settings.deactivate_acc")}
            styleType="fourth"
            type="button"
            disable={!isCheckboxInputChecked}
          />
        </div>
      )}
    </div>
  );
};
export default DeactivateAccount;
