import axios from '../../initializations/axios';

export const getQuizById = async (quizId: number) => {
  const { data } = await axios.get(`Quiz/GetQuizById/${quizId}`);
  return data;
};

export const getMagazinesQuizzes = async (magazineId: number) => {
  const { data } = await axios.get(
    `Magazine/GetAllMagazineQuizzes/${magazineId}`
  );
  return data.data;
};

export const getMagazinesQuizzStatistics = async (
  magazineId: number,
  quizId: number
) => {
  const { data } = await axios.get('statistics/getMagazineQuizStatistics', {
    params: { magazineId, quizId },
  });
  return data.data;
};
