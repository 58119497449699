import "./_Magazines.scss";
import {useRef, useState} from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import {useGeneralStore} from "../../zustand-store/General.store";
import Header from "../../partials/Header/Header";
import PatroMagazines from "../../components/organisms/PatroMagazines/PatroMagazines";
import Footer from "../../partials/Footer/Footer";
import {t} from "i18next";

const Magazines = () => {
    const [page, setPage] = useState(1);
    const {searchWord: headerSearchWord} = useGeneralStore();
    const [searchWord, setSearchWord] = useState(headerSearchWord);
    const magRef = useRef<HTMLDivElement | null>(null);

    const handleMags = () => magRef.current?.scrollIntoView({
        behavior: 'smooth'
    })

    return (
        <>
            <Header greenBg={true}/>
            <div className="magazines userPagesScreen" ref={magRef}>
                <div className="magazines-content">
                    <div className="magazines-content-header">
                        <h1>{t("reusable.magazines")}</h1>
                        <TextField
                            id="outlined-basic"
                            label={t("reusable.search_magazine")}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" style={{
                                        padding: 0,
                                        margin: 0,
                                    }}>
                                        <SearchIcon sx={{color: "#a1a7b3"}}/>
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(e) => {
                                setSearchWord(e.target.value);
                                setPage(1);
                            }}
                            defaultValue={headerSearchWord}
                            variant="outlined"
                            color="success"
                        />
                    </div>
                    <PatroMagazines
                        isInDashboard={false}
                        pageNumber={String(page)}
                        searchWord={searchWord}
                        page={page}
                        setPage={setPage}
                        handleMags={handleMags}
                    />
                </div>
            </div>
            <Footer/>
        </>
    );
};
export default Magazines;
