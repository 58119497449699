import Joi from "joi";
import { TFunction } from "react-i18next";

export const SignInSchema = (t: TFunction) =>
  Joi.object({
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .messages({
        "string.empty": t("reusable.required"),
        "string.email": t("reusable.email_must_be_valid"),
      }),
    password: Joi.string()
      .required()
      .messages({ "string.empty": t("reusable.required") }),
  });
