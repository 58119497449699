import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import {
  DashboardModel,
  MagazineStatisticsByIdModel,
} from '../../../interfaces/models/statistics/Dashboard.model';
import {
  getDashboardData,
  getMagazineStatisticsById,
} from '../../queries/Statistics';

export const useGetDashboardDataQuery = (
  options?: Omit<UseQueryOptions<DashboardModel>, 'queryKey' | 'queryFn'>
): UseQueryResult<DashboardModel> => {
  return useQuery(['getDashboardData'], getDashboardData, options);
};

export const useGetMagazineStatisticsByIdQuery = (
  id: number,
  options?: Omit<
    UseQueryOptions<MagazineStatisticsByIdModel>,
    'queryKey' | 'queryFn'
  >
): UseQueryResult<MagazineStatisticsByIdModel> => {
  return useQuery(
    ['getMagazineStatisticsById', id],
    () => getMagazineStatisticsById(id),
    options
  );
};
