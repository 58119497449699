import "./_ListItems.scss";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import { FC, useState } from "react";
import MuiModal from "../../../modals/MuiModal";
import Delete from "../../../modals/dashboard/Delete";
import EditFile from "../../../modals/dashboard/EditFile";
import { useDeleteFolderFile } from "../../../react-query/hooks/mutationHooks/Folder";
import { convertDateFormat } from "../../../utils/covertDateFormat";
import { useUserClaimStore } from "../../../zustand-store/UserClaim.store";

interface FileProps {
  folderId: number;
  fileId: number;
  fileName: string;
  filePath: string;
  date: string;
}

const File: FC<FileProps> = ({
  folderId,
  fileId,
  fileName,
  filePath,
  date,
}) => {
  const { canRoleEdit, canRoleDelete } = useUserClaimStore();

  //deleteFileModal
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const handleDeleteModalShow = () => setDeleteModalShow(true);
  const { mutate: deleteFile, isLoading, isSuccess } = useDeleteFolderFile();
  const handleDeleteFile = () => {
    deleteFile(fileId);
  };
  //editFileModal
  const [editModalShow, setEditModalShow] = useState(false);
  const handleEditModalShow = () => setEditModalShow(true);

  return (
    <tr className="cursor-default">
      <td className="firstItem">
        <p>{fileName}</p>
      </td>
      <td>
        <p>{convertDateFormat(date).date}</p>
      </td>
      <td>
        <p>{filePath.split(".").pop()?.toUpperCase()}</p>
      </td>

      <td className="lastItem actionsIcons">
        <div className="listItem-icons tables-item">
          {(canRoleDelete || canRoleEdit) && (
            <>
              {canRoleEdit && (
                <button
                  type="button"
                  className="ghost-button interactive-cursor"
                  onClick={handleEditModalShow}
                >
                  <EditIcon className="muiIcon" />
                </button>
              )}
              {canRoleDelete && (
                <button
                  type="button"
                  className="ghost-button interactive-cursor"
                  onClick={handleDeleteModalShow}
                >
                  <DeleteIcon className="muiIcon" />
                </button>
              )}
            </>
          )}
          <a href={filePath} download="proposed_file_name">
            <DownloadIcon className="muiIcon" />
          </a>
        </div>
      </td>
      <MuiModal
        title="Delete a File"
        description="Are you sure that you want to delete this file?"
        modalContent={
          <Delete
            deleteFunction={handleDeleteFile}
            setModalShow={setDeleteModalShow}
          />
        }
        modalShow={deleteModalShow}
        setModalShow={setDeleteModalShow}
      />
      <MuiModal
        title="Delete File"
        description="Are you sure that you want to delete this file?"
        modalContent={
          <Delete
            isLoading={isLoading}
            isSuccess={isSuccess}
            deleteFunction={handleDeleteFile}
            setModalShow={setDeleteModalShow}
          />
        }
        modalShow={deleteModalShow}
        setModalShow={setDeleteModalShow}
      />
      <MuiModal
        title="Update File"
        description="Write new name of file"
        modalContent={
          <EditFile
            setModalShow={setEditModalShow}
            fileId={fileId}
            fileName={fileName}
          />
        }
        modalShow={editModalShow}
        setModalShow={setEditModalShow}
      />
    </tr>
  );
};

export default File;
