import { Box, Paper, Typography } from "@mui/material";
import "./_Statistics.scss";
import Chart from "react-apexcharts";
import { Filters } from "./Filters";
import { useGetMagazineReadingStatisticsMutation } from "../../react-query/hooks/mutationHooks/Statistics";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

export const NumberOfReadings = () => {
  const { t } = useTranslation();
  let [searchParams] = useSearchParams();

  //react-query
  const { data = [], refetch } = useGetMagazineReadingStatisticsMutation(
    {
      magazineIds: searchParams
        .get("magazineIds")
        ?.split(",")
        .map((item) => +item),
      startDate: searchParams.get("startDate") || "",
      endDate: searchParams.get("endDate") || "",
    },
    { enabled: false }
  );

  /**
   * handlers
   */
  const handleFilterApply = () => refetch();

  const generateChartData = (data: any) => {
    const counts: any = {};
    const chartData = [];

    // Loop through the data to count the occurrences of each month
    data.forEach((item: any) => {
      item.date?.forEach((dateString: any) => {
        const date = dayjs(dateString);
        const key = date.format("YYYY-M");
        counts[key] = counts[key] ? counts[key] + 1 : 1;
      });
    });

    // Generate the chart data from the counts
    for (let i = 1; i <= 12; i++) {
      const key = `2023-${i}`;
      const count = counts[key] || 0;
      chartData.push(count);
    }

    return chartData;
  };

  const chartData = data.map((item) => ({
    ...item,
    date: generateChartData(item.date),
  }));

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // const startYear = dayjs(searchParams.get('startDate')).year();

  // const endYear = dayjs(searchParams.get('endDate')).year();
  // const selectedMonths = [];

  // // check if start and end dates are in the same year
  // if (startYear === endYear) {
  //   const startMonth = dayjs(searchParams.get('startDate')).month();
  //   const endMonth = dayjs(searchParams.get('endDate')).month();

  //   for (let i = startMonth; i <= endMonth; i++) {
  //     selectedMonths.push(months[i]);
  //   }

  //   console.log(`Selected months for ${startYear}:`, selectedMonths);
  // } else {
  //   console.log('Please select dates within the same year.');
  // }

  // console.log(selectedMonths);

  return (
    <Box display="flex" flexDirection="column">
      <Box>
        <h2 className="pagesStyle-title">
          {t("dashboard.statistics.number_of_readings")}
        </h2>
      </Box>
      <Box component={Paper} p={2} boxShadow="0 0 1px rgba(0, 0, 0, 0.15)">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography fontWeight={500}>
            {t("dashboard.statistics.reading_comparison")}
          </Typography>
          <Filters onFilterApply={handleFilterApply} />
        </Box>
        <Chart
          type="line"
          height={250}
          series={chartData.map((chart) => ({
            name: chart.title,
            data: chart.date,
          }))}
          options={{
            noData: { text: `${t("dashboard.statistics.select_filters")}` },
            chart: {
              type: "line",
              zoom: {
                enabled: false,
              },
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: "smooth",
            },

            legend: {
              tooltipHoverFormatter: function (val, opts) {
                return (
                  val +
                  " - " +
                  opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
                  ""
                );
              },
            },
            markers: {
              size: 0,
              hover: {
                sizeOffset: 6,
              },
            },
            xaxis: {
              categories: months,
            },
            grid: {
              borderColor: "#f1f1f1",
            },

            // responsive: [
            //   {
            //     breakpoint: 768,
            //     options: {
            //       chart: { height: 120, width: 'auto' },
            //       plotOptions: {
            //         radialBar: {
            //           hollow: { size: '50%' },
            //           dataLabels: {
            //             value: { fontSize: '10px' },
            //           },
            //         },
            //       },
            //     },
            //   },
            // ],
          }}
        />
      </Box>
    </Box>
  );
};
