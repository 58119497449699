import {FC} from "react";
import {basicParamsTypes} from "../../../interfaces/models/basicParams.model";
import {
    useGetAllMagazinesQuery,
    useGetAllPublishedMagazinesQuery,
} from "../../../react-query/hooks/queriesHooks/Magazine";
import {isServerError} from "../../../utils/isServerError";
import {useErrorsStore} from "../../../zustand-store/Errors.store";
import MagazineCardSkeleton from "../../molecules/MagazineCardSkeleton/MagazineCardSkeleton";
import MagazineLinkCard from "../../molecules/MagazineLinkCard/MagazineLinkCard";
import MuiPagination from "../../molecules/MuiPagination/MuiPagination";
import NoResults from "../NoResults/NoResults";
import "./_PatroMagazines.scss";

interface patroMagazinesInterface extends basicParamsTypes {
    page?: number;
    setPage?: (page: number) => void;
    isInDashboard: boolean;
    handleMags?: () => void;
}

const PatroMagazines: FC<patroMagazinesInterface> = ({
                                                         pageNumber,
                                                         searchWord,
                                                         page,
                                                         setPage,
                                                         isInDashboard,
                                                         handleMags
                                                     }) => {
    const {setHasServerError} = useErrorsStore();
    const {
        data: {data: PublishedMagazines, metaData: publishedMetaData} = {},
        isLoading: isPublishedLoading,
    } = useGetAllPublishedMagazinesQuery(
        {
            pageNumber: pageNumber,
            searchWord: searchWord,
        },
        {
            onError: (data: any) => {
                setHasServerError(!!isServerError(data.response.status));
            },
            enabled: !isInDashboard,
        }
    );

    const {data: {data: Magazines, metaData} = {}, isLoading} =
        useGetAllMagazinesQuery(
            {
                pageNumber: pageNumber,
                searchWord: searchWord,
            },
            {
                onError: (data: any) => {
                    setHasServerError(!!isServerError(data.response.status));
                },
                enabled: isInDashboard,
            }
        );
    const showSkeleton = () => {
        return [...Array(6)].map((e, i) => <MagazineCardSkeleton key={2000 + i}/>);
    };

    if (!isInDashboard && isPublishedLoading) {
        return <div className="patroMagazines">{showSkeleton()}</div>;
    }
    if (isLoading && isInDashboard) {
        return <div className="patroMagazines">{showSkeleton()}</div>;
    }
    if (!isInDashboard && publishedMetaData?.totalItemCount === 0) {
        return <NoResults/>;
    }
    if (metaData?.totalItemCount === 0 && isInDashboard) {
        return <NoResults/>;
    }
    return (
        <>
            <div id="top" className="patroMagazines">
                {isInDashboard
                    ? Magazines?.map((magazine) => {
                        return (
                            <MagazineLinkCard
                                key={magazine.id}
                                id={magazine.id}
                                cover={magazine.cover}
                                description={magazine.description}
                                editorialNumber={magazine.editorialNumber}
                                tags={magazine.tags}
                                insertedDate={magazine.insertedDate}
                                isPublished={magazine.isPublished}
                                title={magazine.title}
                                magazinePath={magazine.magazinePath}
                                hasQuizes={magazine.hasQuizes}
                            />
                        );
                    })
                    : PublishedMagazines?.map((magazine) => {
                        return (
                            <MagazineLinkCard
                                key={magazine.id}
                                id={magazine.id}
                                cover={magazine.cover}
                                description={magazine.description}
                                editorialNumber={magazine.editorialNumber}
                                tags={magazine.tags}
                                insertedDate={magazine.insertedDate}
                                isPublished={magazine.isPublished}
                                title={magazine.title}
                                magazinePath={magazine.magazinePath}
                                hasQuizes={magazine.hasQuizes}
                            />
                        );
                    })}
            </div>
            <div className="magazines-content-pagination">
                <MuiPagination
                    pagesNumber={
                        isInDashboard ? metaData?.pageCount : publishedMetaData?.pageCount
                    }
                    page={page}
                    setPage={setPage}
                    handleRef={handleMags}
                />
            </div>
        </>
    );
};

export default PatroMagazines;
