import "./_Header.scss";
import mainLogo from "../../assets/icons/mainLogo.png";
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";
import { KeyboardEvent, useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import { useGeneralStore } from "../../zustand-store/General.store";
import { t } from "i18next";

const Header = ({ greenBg }: { greenBg?: boolean }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showSearchInput, setShowSearchInput] = useState<boolean>(false);
  const [showDownMenu, setShowDownMenu] = useState<boolean>(false);
  const handleNavbarDownMenuShow = () => {
    setShowDownMenu((prev) => !prev);
  };
  const handleClickAway = () => {
    setShowDownMenu(false);
  };
  const { searchWord, setSearchWord } = useGeneralStore();
  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (searchWord !== "") {
        navigate("/magazines");
      }
    }
  };

  const handleClickSearchIcon = () => {
    if (!showSearchInput) {
      setShowSearchInput((prev) => !prev);
    } else {
      if (searchWord !== "") {
        navigate("/magazines");
      } else {
        setShowSearchInput((prev) => !prev);
      }
    }
  };

  useEffect(() => {
    if (location.pathname === "/magazines") {
      setSearchWord("");
    }
  }, [location.pathname]);

  return (
    <header>
      <div
        className="headerMobileView"
        style={{
          backgroundColor: greenBg ? "#045428" : "",
        }}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <div className="headerMobileView-content userPagesScreen">
            <div className="headerMobileView-content-part1">
              <NavLink to="/" className="ghost-button interactive-button">
                <img src={mainLogo} alt="Patro logo" className="icon" />
              </NavLink>
              <button
                type="button"
                onClick={handleNavbarDownMenuShow}
                className="ghost-button interactive-button headerMobileView-inputs"
              >
                <MenuIcon sx={{ color: "white", fontSize: "25px" }} />
              </button>
            </div>
            <div
              className={`headerMobileView-content-part2 ${
                showDownMenu ? "showDownMenu" : "hideshowDownMenu"
              }`}
            >
              <NavLink to="/" onClick={handleNavbarDownMenuShow}>
                {t("public.reusable.home")}
              </NavLink>
              <NavLink to="/magazines" onClick={handleNavbarDownMenuShow}>
                {t("reusable.magazines")}
              </NavLink>
              <NavLink to="/contact" onClick={handleNavbarDownMenuShow}>
                {t("public.reusable.contact")}
              </NavLink>
              <NavLink to="/subscribe" onClick={handleNavbarDownMenuShow}>
                {t("public.reusable.subscribe")}
              </NavLink>
              <div className="input">
                <input
                  type="text"
                  placeholder={t("public.header.search") ?? ""}
                  defaultValue={searchWord}
                  onChange={(e) => setSearchWord(e.target.value)}
                  onKeyDown={(e) => handleKeyDown(e)}
                  ref={(input) => input && input.focus()}
                />
                <button
                  type="button"
                  onClick={handleClickSearchIcon}
                  className="search-btn ghost-button interactive-button"
                >
                  <SearchIcon
                    sx={{
                      color: "white",
                      fontSize: "18px",
                    }}
                  />
                </button>
              </div>
            </div>
          </div>
        </ClickAwayListener>
      </div>
      <div
        className="headerDestopView"
        style={{ backgroundColor: greenBg ? "#045428" : "" }}
      >
        <div className="headerDestopView-content userPagesScreen">
          <NavLink to="/" className="ghost-button interactive-button">
            <img src={mainLogo} alt="Patro logo" className="icon" />
          </NavLink>
          <div
            className={`headerDestopView-content-links ${
              showDownMenu ? "showDownMenu" : "hideshowDownMenu"
            }`}
          >
            <NavLink to="/" onClick={handleNavbarDownMenuShow}>
              {t("public.reusable.home")}
            </NavLink>
            <NavLink to="/magazines" onClick={handleNavbarDownMenuShow}>
              {t("reusable.magazines")}
            </NavLink>
            <NavLink to="/contact" onClick={handleNavbarDownMenuShow}>
              {t("public.reusable.contact")}
            </NavLink>
            <NavLink to="/subscribe" onClick={handleNavbarDownMenuShow}>
              {t("public.reusable.subscribe")}
            </NavLink>
            <div className="input">
              <input
                type="text"
                placeholder={t("public.header.search") ?? ""}
                defaultValue={searchWord}
                onChange={(e) => setSearchWord(e.target.value)}
                onKeyDown={(e) => handleKeyDown(e)}
                className={`${showSearchInput ? "non-hidden" : "hidden"}`}
              />
              <button
                type="button"
                onClick={handleClickSearchIcon}
                className="search-btn ghost-button interactive-button"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 4,
                  paddingRight: 8,
                  paddingLeft: 8,
                }}
              >
                <SearchIcon
                  sx={{
                    color: "white",
                    fontSize: "20px",
                  }}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header;
