import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { FC } from 'react';
import '../NoResults/_NoResults.scss';

interface ServerErrorStatusProps {
  errorText?: string;
}
const ServerErrorStatus: FC<ServerErrorStatusProps> = ({ errorText }) => {
  return (
    <div className="serverErrorStatus">
      <div className="serverErrorStatus-content">
        <ErrorOutlineIcon sx={{ fontSize: '45px' }} />
        {errorText === undefined ? (
          <h3>Sorry, we're having some technical issues!</h3>
        ) : (
          <h3>{errorText}</h3>
        )}
      </div>
    </div>
  );
};
export default ServerErrorStatus;
