import TextField from "@mui/material/TextField";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Button from "../../components/atoms/Button/Button.tsx";
import { useModalStore } from "../../zustand-store/Modal.store";
import { usePutAddMediaFile } from "../../react-query/hooks/mutationHooks/MediaFile";
import { EditMediaFileTitleModel } from "../../interfaces/models/mediaFile/EditMediaFileTitle.model";
import { EditMediaFileSchema } from "../../schemas/mediaFileSchemas/EditMediaFile.schema";
import StatusState from "../../components/molecules/StatusState/StatusState";
import { closeModalWithDelay } from "../../utils/closeModalWithDelay";

interface EditMediaFileProps {
  mediaFileId: number;
  fileName: string;
}
const EditMediaFile: FC<EditMediaFileProps> = ({ mediaFileId, fileName }) => {
  const { setModalShow } = useModalStore();
  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm<EditMediaFileTitleModel>({
    resolver: joiResolver(EditMediaFileSchema),
    defaultValues: {
      fileName: fileName,
    },
  });

  const {
    mutate: updateMediaFile,
    isLoading,
    isSuccess,
  } = usePutAddMediaFile(mediaFileId);

  const handleUpdateMediaFile = (
    updateMediaFilePayload: EditMediaFileTitleModel
  ) => {
    updateMediaFile(updateMediaFilePayload, {
      onSuccess: () => {
        closeModalWithDelay(setModalShow);
      },
    });
  };

  const handleCancel = () => {
    reset();
    setModalShow(false);
    clearErrors();
  };

  return (
    <form
      className="muiModal-content"
      onSubmit={handleSubmit(handleUpdateMediaFile)}
    >
      <div className="muiModal-content-inputs">
        <TextField
          className="muiModal-content-inputs-input"
          fullWidth
          id="outlined-basic"
          label="Title"
          color="success"
          variant="outlined"
          error={!!errors?.fileName}
          helperText={errors?.fileName?.message}
          {...register("fileName")}
        />
      </div>
      <div className="muiModal-content-status">
        {isLoading && (
          <StatusState statusText="Updating file..." statusType="loading" />
        )}
        {isSuccess && (
          <StatusState
            statusText="File has been successfully updated!"
            statusType="success"
          />
        )}
      </div>
      {!isLoading && !isSuccess && (
        <div className="muiModal-content-modalbuttons">
          <Button
            onClick={handleCancel}
            content="Cancel"
            styleType="third"
            type="button"
          />
          <Button content="Save" styleType="second" type="submit" />
        </div>
      )}
    </form>
  );
};
export default EditMediaFile;
