export enum DashboardTitleEnum {
  "/dashboard" = "dashboard.reusable.dashboard",
  "/dashboard/folders" = "dashboard.reusable.folders",
  "/dashboard/magazines" = "dashboard.reusable.magazines",
  "/dashboard/users" = "dashboard.reusable.users",
  "/dashboard/media-files" = "dashboard.reusable.media_files",
  "/dashboard/authorisations/premissions" = "dashboard.reusable.authorisations",
  "/dashboard/authorisations/roles" = "dashboard.reusable.authorisations",
  "/dashboard/newsletters" = "dashboard.reusable.newsletters",
  "/dashboard/statistics" = "dashboard.reusable.statictics",
  "/dashboard/settings" = "dashboard.reusable.settings",
  "/dashboard/magazines/create-magazine" = "dashboard.reusable.magazines",
}
