import axios from "../../initializations/axios";
import { EditMediaFileTitleModel } from "../../interfaces/models/mediaFile/EditMediaFileTitle.model";

// ----- POST mutations -----
export const postMediaFile = async (
  addMediaFilePayload: FormData
): Promise<any> => {
  const { data } = await axios.post("MediaFile/AddFile", addMediaFilePayload, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return data;
};

// ----- PUT mutations -----
export const putMediaFile = async (
  mediaFileId: number,
  updateMediaFilePayload: EditMediaFileTitleModel
) => {
  const { data } = await axios.put(
    `MediaFile/EditMediaFileName/${mediaFileId}`,
    updateMediaFilePayload
  );
  return data;
};

// ----- DELETE mutations -----
export const deleteMediaFile = async (mediaFileId: number) => {
  const { data } = await axios.delete(
    `MediaFile/DeleteMediaFile/${mediaFileId}`
  );
  return data;
};
