import TextField from "@mui/material/TextField";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { usePutFolderFile } from "../../react-query/hooks/mutationHooks/Folder";
import Button from "../../components/atoms/Button/Button.tsx";
import { EditFolderFileInputModel } from "../../interfaces/models/folderModels/EditFolderFile.model";
import { EditFolderFileSchema } from "../../schemas/folderSchemas/EditFolderFile.schema";
import StatusState from "../../components/molecules/StatusState/StatusState";
import { closeModalWithDelay } from "../../utils/closeModalWithDelay";

interface EditFileProp {
  fileId: number;
  fileName: string;
  setModalShow: (modalshow: boolean) => void;
}
const EditFile: FC<EditFileProp> = ({ fileId, fileName, setModalShow }) => {
  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm<EditFolderFileInputModel>({
    resolver: joiResolver(EditFolderFileSchema),
    defaultValues: {
      fileName: fileName,
    },
  });

  const {
    mutate: editFolderFile,
    isLoading,
    isSuccess,
  } = usePutFolderFile(fileId);

  const handleCancel = () => {
    reset();
    setModalShow(false);
    clearErrors();
  };
  const handleUpdate = (editFolderFilePayload: EditFolderFileInputModel) => {
    editFolderFile(editFolderFilePayload, {
      onSuccess: () => {
        closeModalWithDelay(setModalShow);
      },
    });
  };
  return (
    <form className="muiModal-content" onSubmit={handleSubmit(handleUpdate)}>
      <div className="muiModal-content-inputs">
        <TextField
          className="muiModal-content-inputs-input"
          fullWidth
          id="outlined-basic"
          label="Title"
          color="success"
          variant="outlined"
          error={!!errors?.fileName}
          helperText={errors?.fileName?.message}
          {...register("fileName")}
        />
      </div>
      <div className="muiModal-content-status">
        {isLoading && (
          <StatusState statusText="Updating file..." statusType="loading" />
        )}
        {isSuccess && (
          <StatusState
            statusText="File has been successfully updated!"
            statusType="success"
          />
        )}
      </div>
      {!isLoading && !isSuccess && (
        <div className="muiModal-content-modalbuttons">
          <Button
            onClick={handleCancel}
            content="Cancel"
            styleType="third"
            type="button"
          />
          <Button content="Save" styleType="second" type="submit" />
        </div>
      )}
    </form>
  );
};
export default EditFile;
