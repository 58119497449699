import { useMutation, useQueryClient } from "@tanstack/react-query";
import { EditMediaFileTitleModel } from "../../../interfaces/models/mediaFile/EditMediaFileTitle.model";
import {
  deleteMediaFile,
  postMediaFile,
  putMediaFile,
} from "../../mutations/MediaFile";

//-----POST mutationsHooks-----
export const usePostAddMediaFile = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (addMediaFilePayload: FormData) => postMediaFile(addMediaFilePayload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["getAllMediaFiles"]);
      },
    }
  );
};
//-----PUT mutationsHooks-----
//TODO: me kqyr mire a i ka la qishtu Joni
export const usePutAddMediaFile = (mediaFileId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (updateMediaFilePayload: EditMediaFileTitleModel) =>
      putMediaFile(mediaFileId, updateMediaFilePayload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["getAllMediaFiles"]);
      },
    }
  );
};

//-----DELETE mutationsHooks-----
export const useDeleteMediaFile = () => {
  const queryClient = useQueryClient();
  return useMutation((mediaFileId: number) => deleteMediaFile(mediaFileId), {
    onSuccess: () => queryClient.invalidateQueries(["getAllMediaFiles"]),
  });
};
