import { joiResolver } from "@hookform/resolvers/joi";
import TextField from "@mui/material/TextField";
import { FC } from "react";
import { useForm } from "react-hook-form";
import Button from "../../components/atoms/Button/Button.tsx";
import StatusState from "../../components/molecules/StatusState/StatusState";
import { AddFolderInputModel } from "../../interfaces/models/folderModels/AddFolder.model";
import {
  usePostCreateFolders,
  usePutFolder,
} from "../../react-query/hooks/mutationHooks/Folder";
import { AddFolderSchema } from "../../schemas/folderSchemas/AddFolder.schema";
import { closeModalWithDelay } from "../../utils/closeModalWithDelay";
import { useTranslation } from "react-i18next";

interface AddFolderProp {
  update?: boolean;
  folderId?: number;
  folderTitle?: string;
  setModalShow: (modalshow: boolean) => void;
}
const AddFolder: FC<AddFolderProp> = ({
  update,
  folderId,
  folderTitle,
  setModalShow,
}) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<AddFolderInputModel>({
    resolver: joiResolver(AddFolderSchema),
    defaultValues: {
      name: folderTitle,
    },
  });
  const {
    mutate: createFolder,
    isLoading: isPostLoading,
    isError: isPostError,
    isSuccess: isPostSuccess,
  } = usePostCreateFolders();
  const {
    mutate: putFolder,
    isLoading: isPutLoading,
    isError: isPutError,
    isSuccess: isPutSuccess,
  } = usePutFolder(folderId!);
  const handleAddFolderSubmit = (addFolderPayload: AddFolderInputModel) => {
    createFolder(addFolderPayload, {
      onSuccess: () => {
        closeModalWithDelay(setModalShow);
      },
    });
  };
  const handleUpdateFolderSubmit = (
    updateFolderPayload: AddFolderInputModel
  ) => {
    putFolder(updateFolderPayload, {
      onSuccess: () => {
        closeModalWithDelay(setModalShow);
      },
    });
  };
  const handleCancel = () => {
    reset();
    setModalShow(false);
  };
  return (
    <form
      className="muiModal-content"
      onSubmit={handleSubmit(
        update ? handleUpdateFolderSubmit : handleAddFolderSubmit
      )}
    >
      <div className="muiModal-content-inputs">
        <TextField
          className="muiModal-content-inputs-input"
          fullWidth
          id="outlined-basic"
          label={t("dashboard.folders.folder_name")}
          color="success"
          variant="outlined"
          error={!!errors.name}
          helperText={errors.name?.message}
          {...register("name")}
        />
      </div>

      <div className="muiModal-content-status">
        {isPostLoading && (
          <StatusState
            statusText={t("reusable.loading")}
            statusType="loading"
          />
        )}
        {isPostSuccess && (
          <StatusState
            statusText={t("dashboard.folders.modals.success_post_status")}
            statusType="success"
          />
        )}
        {isPutLoading && (
          <StatusState
            statusText={t("reusable.loading")}
            statusType="loading"
          />
        )}
        {isPutSuccess && (
          <StatusState
            statusText={t("dashboard.folders.modals.success_update_status")}
            statusType="success"
          />
        )}
      </div>
      {!isPostLoading && !isPostSuccess && !isPutLoading && !isPutSuccess && (
        <div className="muiModal-content-modalbuttons">
          <Button
            onClick={handleCancel}
            content={t("dashboard.reusable.cancel")}
            styleType="third"
            type="button"
          />
          <Button
            content={t("dashboard.reusable.save")}
            styleType="second"
            type="submit"
          />
        </div>
      )}
    </form>
  );
};

export default AddFolder;
