import { joiResolver } from "@hookform/resolvers/joi";
import TextField from "@mui/material/TextField";
import { FC } from "react";
import { useForm } from "react-hook-form";
import Button from "../../components/atoms/Button/Button.tsx";
import StatusState from "../../components/molecules/StatusState/StatusState";
import { CreateRoleInputModel } from "../../interfaces/models/adminModels/CreateRole.model";
import { usePostRole } from "../../react-query/hooks/mutationHooks/Admin";
import { CreateRoleSchema } from "../../schemas/adminSchemas/CreateRole.schema";
import { closeModalWithDelay } from "../../utils/closeModalWithDelay";
import { removeEmptySpaces } from "../../utils/formaters/removeEmptySpaces";
import { useTranslation } from "react-i18next";

interface AddRoleProp {
  setModalShow: (modalshow: boolean) => void;
}
const AddRole: FC<AddRoleProp> = ({ setModalShow }) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CreateRoleInputModel>({
    resolver: joiResolver(CreateRoleSchema),
  });
  const { mutate: createRole, isLoading, isSuccess } = usePostRole();
  const handleCreateRoleSubmit = (creatRolePayload: CreateRoleInputModel) => {
    createRole(
      {
        ...creatRolePayload,
        roleName: removeEmptySpaces(creatRolePayload.roleName),
      },
      {
        onSuccess: () => {
          closeModalWithDelay(setModalShow);
        },
      }
    );
  };
  const handleCancel = () => {
    reset();
    setModalShow(false);
  };

  return (
    <form
      className="muiModal-content"
      onSubmit={handleSubmit(handleCreateRoleSubmit)}
    >
      <div className="muiModal-content-inputs">
        <TextField
          className="muiModal-content-inputs-input"
          fullWidth
          id="outlined-basic"
          label={t("dashboard.reusable.role")}
          color="success"
          variant="outlined"
          error={!!errors.roleName}
          helperText={errors.roleName?.message}
          {...register("roleName")}
        />
      </div>
      <div className="muiModal-content-status">
        {isLoading && (
          <StatusState
            statusText={t("reusable.loading")}
            statusType="loading"
          />
        )}
        {isSuccess && (
          <StatusState
            statusText={t(
              "dashboard.authorisations.roles.modals.success_status"
            )}
            statusType="success"
          />
        )}
      </div>
      {!isLoading && !isSuccess && (
        <div className="muiModal-content-modalbuttons">
          <Button
            onClick={handleCancel}
            content={t("dashboard.reusable.cancel")}
            styleType="third"
            type="button"
          />
          <Button
            content={t("dashboard.reusable.save")}
            styleType="second"
            type="submit"
          />
        </div>
      )}
    </form>
  );
};
export default AddRole;
