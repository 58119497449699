import { RouteProps } from "react-router-dom";
import Dashboard from "../pages/Dashboard/Dashboard";
import Users from "../pages/Users/Users";
import Statistics from "../pages/Statistics/Statistics";
import Settings from "../pages/Settings/Settings";
import Page404 from "../pages/Page404/Page404";
import PrivacyAndPolicy from "../pages/PrivacyAndPolicy/PrivacyAndPolicy";
import TermsOfServices from "../pages/TermsOfServices/TermsOfServices";
import Newsletters from "../pages/Newsletters/Newsletters";
import Permissions from "../pages/Permissions/Permissions";
import Roles from "../pages/Roles/Roles";
import FilesFolders from "../pages/FilesFolders/FilesFolders";
import Files from "../pages/Files/Files";
import Login from "../components/organisms/LoginPasswordOrganisms/Login";
import { ContactFollowLayout } from "../layouts/ContactFollowLayout/ContactFollowLayout";
import { LoginPasswordLayout } from "../layouts/LoginPasswordLayout/LoginPasswordLayout";
import DashboardMagazines from "../pages/DashboardMagazines/DashboardMagazines";
import DashboardMagazine from "../pages/DashboardMagazine/DashboardMagazine";
import Magazines from "../pages/Magazines/Magazines";
import Magazine from "../pages/Magazine/Magazine";
import Contact from "../components/organisms/ContactFollowOrganisms/Contact";
import CreateMagazine from "../pages/CreateMagazine/CreateMagazine";
import ResetPassword from "../pages/ResetPassword/ResetPassword";
import Home from "../pages/Home/Home";
import Follow from "../components/organisms/ContactFollowOrganisms/Follow";
import MediaFiles from "../pages/MediaFiles/MediaFiles";
import PageEditor from "../pages/PageEditor/PageEditor";

type RestrictTypes = {
  type: string[];
  name?: string;
};
export type AppRoute = RestrictTypes & RouteProps;

export const AppRoutes: AppRoute[] = [
  // Public Routes
  {
    type: ["PUBLIC"],
    path: "",
    element: <Home />,
    index: true,
  },
  {
    type: ["PUBLIC"],
    path: "magazines",
    element: <Magazines />,
  },
  {
    type: ["PUBLIC"],
    path: "magazines/:magazineId",
    element: <Magazine />,
  },
  {
    type: ["PUBLIC"],
    path: "contact",
    element: <ContactFollowLayout formToComplite={<Contact />} />,
  },
  {
    type: ["PUBLIC"],
    path: "subscribe",
    element: <ContactFollowLayout formToComplite={<Follow />} />,
  },
  {
    type: ["PUBLIC"],
    path: "privacy-and-policy",
    element: <PrivacyAndPolicy />,
  },
  {
    type: ["PUBLIC"],
    path: "terms-of-services",
    element: <TermsOfServices />,
  },
  {
    type: ["PUBLIC"],
    path: "login",
    element: <LoginPasswordLayout formToComplite={<Login />} />,
  },
  {
    type: ["PUBLIC"],
    path: "change-password",
    element: <LoginPasswordLayout formToComplite={<ResetPassword />} />,
  },
  // Private Routes
  {
    type: ["PRIVATE"],
    name: "Dashboard",
    path: "",
    index: true,
    element: <Dashboard />,
  },
  {
    type: ["PRIVATE"],
    name: "Files",
    path: "folders",
    element: <FilesFolders />,
  },
  {
    type: ["PRIVATE"],
    name: "MediaFile",
    path: "media-files",
    element: <MediaFiles />,
  },
  {
    type: ["PRIVATE"],
    name: "Files",
    path: "folders/:folderId",
    element: <Files />,
  },

  {
    type: ["PRIVATE"],
    name: "Magazines",
    path: "magazines",
    element: <DashboardMagazines />,
  },
  {
    type: ["PRIVATE"],
    name: "Magazines",
    path: "magazines/:magazineId",
    element: <DashboardMagazine />,
  },
  {
    type: ["PRIVATE"],
    name: "Magazines",
    path: "magazines/create-magazine",
    element: <CreateMagazine />,
  },

  {
    type: ["PRIVATE"],
    name: "Users",
    path: "users",
    element: <Users />,
  },
  {
    type: ["PRIVATE"],
    name: "Authorizations",
    path: "authorisations/premissions",
    element: <Permissions />,
  },
  {
    type: ["PRIVATE"],
    name: "Roles",
    path: "authorisations/roles",
    element: <Roles />,
  },
  {
    type: ["PRIVATE"],
    name: "Newsletters",
    path: "newsletters",
    element: <Newsletters />,
  },
  {
    type: ["PRIVATE"],
    name: "Statistics",
    path: "statistics",
    element: <Statistics />,
  },
  {
    type: ["PRIVATE"],
    name: "Settings",
    path: "settings",
    element: <Settings />,
  },
  // Private and Public Routes
  {
    type: ["PRIVATE", "PUBLIC"],
    path: "*",
    element: <Page404 />,
  },
];
