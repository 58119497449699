import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MyAxiosErrorResponse } from "../../../interfaces/models/AxiosErrorResponse";
import { ErrorResponse } from "../../../interfaces/models/ErrorResponse.model";
import { CreateMagazineArticleInputModel } from "../../../interfaces/models/magazineModels/CreateMagazineArticle.model";
import { CreateMagazineArticlePageInputModel } from "../../../interfaces/models/magazineModels/CreateMagazineArticlePage.model";
import { EditMagazineInputModel } from "../../../interfaces/models/magazineModels/EditMagazine.model";
import { EditMagazineArticlePageInputModel } from "../../../interfaces/models/magazineModels/EditMagazineArticlePage.model";
import { EditMagazineArticlePageTitleInputModel } from "../../../interfaces/models/magazineModels/EditMagazineArticlePageTitle.model";
import { isServerError } from "../../../utils/isServerError";
import { useErrorsStore } from "../../../zustand-store/Errors.store";
import {
  deleteDeleteMagazine,
  deleteDeleteMagazineArticle,
  deleteDeleteMagazineArticlePage,
  deleteDeleteMagazineCover,
  deleteTemplate,
  postAddEditMagazineCover,
  postCreateMagazine,
  postCreateMagazineArticle,
  postCreateMagazineArticlePage,
  putEditMagazine,
  putEditMagazineArticle,
  putEditMagazineArticlePage,
  putEditMagazineArticlePageTitle,
  putPublishMagazine,
} from "../../mutations/Magazine";
import { putUnPublishMagazine } from "../../mutations/Magazine";

//-----POST mutationsHooks----
export const usePostCreateMagazine = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (createMagazinePayload: FormData) =>
      postCreateMagazine(createMagazinePayload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["getMagazine"]);
      },
    }
  );
};
export const usePostAddMagazineCover = (magazineId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (changeMagazineCoverPayload: FormData) =>
      postAddEditMagazineCover(magazineId, changeMagazineCoverPayload),
    {
      onSuccess: () => queryClient.invalidateQueries(["getMagazine"]),
    }
  );
};
export const usePostAddMagazineArticle = (magazineId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (createMagazineArticlePayload: CreateMagazineArticleInputModel) =>
      postCreateMagazineArticle(magazineId, createMagazineArticlePayload),
    {
      onSuccess: () => queryClient.invalidateQueries(["getMagazineArticle"]),
    }
  );
};
export const usePostCreateMagazineArticlePage = (magazineArticleId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (createMagazineArticlePagePayload: CreateMagazineArticlePageInputModel) =>
      postCreateMagazineArticlePage(
        magazineArticleId,
        createMagazineArticlePagePayload
      ),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(["getAllMagazineArticlePages"]),
    }
  );
};
//-----PUT mutationsHooks-----
export const usePutEditMagazine = (magazineId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (editMagazinePayload: EditMagazineInputModel) =>
      putEditMagazine(magazineId, editMagazinePayload),
    {
      onSuccess: () => queryClient.invalidateQueries(["getMagazine"]),
    }
  );
};
export const usePutPublishMagazine = (magazineId: number) => {
  const queryClient = useQueryClient();
  return useMutation(() => putPublishMagazine(magazineId), {
    onSuccess: () => queryClient.invalidateQueries(["getMagazine"]),
  });
};
export const usePutUnPublishMagazine = (magazineId: number) => {
  const queryClient = useQueryClient();
  return useMutation(() => putUnPublishMagazine(magazineId), {
    onSuccess: () => queryClient.invalidateQueries(["getMagazine"]),
  });
};
export const usePutEditMagazineArticle = (magazineArticleId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (editMagazineArticlePayload: CreateMagazineArticleInputModel) =>
      putEditMagazineArticle(magazineArticleId, editMagazineArticlePayload),
    {
      onSuccess: () => queryClient.invalidateQueries(["getMagazineArticle"]),
    }
  );
};
export const usePutEditMagazineArticlePageTitle = (
  magazineArticlePageId: number
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (editMagazineArticlePageTitle: EditMagazineArticlePageTitleInputModel) =>
      putEditMagazineArticlePageTitle(
        magazineArticlePageId,
        editMagazineArticlePageTitle
      ),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(["getAllMagazineArticlePages"]),
    }
  );
};
export const usePutEditMagazineArticlePage = (
  magazineArticlePageId: number
) => {
  const { setErrors, setHasServerError, setHookName } = useErrorsStore();
  return useMutation(
    (editMagazineArticlePage: EditMagazineArticlePageInputModel) =>
      putEditMagazineArticlePage(
        magazineArticlePageId,
        editMagazineArticlePage
      ),
    {
      onError: (response: unknown) => {
        setHookName("usePutEditMagazineArticlePage");
        const responseOnError = response as MyAxiosErrorResponse<ErrorResponse>;
        setErrors(responseOnError.response.data.errors);
        setHasServerError(!!isServerError(responseOnError.response.status));
      },
    }
  );
};

//-----DELETE mutationsHooks-----
export const useDeleteMagazine = () => {
  return useMutation((magazineId: number) => deleteDeleteMagazine(magazineId));
};
export const useDeleteTemplate = () => {
  const queryClient = useQueryClient();
  return useMutation((templateId: number) => deleteTemplate(templateId), {
    onSuccess: () => queryClient.invalidateQueries(["getTemplates"]),
  });
};
export const useDeleteMagazineArticle = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (articleId: number) => deleteDeleteMagazineArticle(articleId),
    {
      onSuccess: () => queryClient.invalidateQueries(["getMagazineArticle"]),
    }
  );
};
export const useDeleteMagazineCover = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (magazineId: number) => deleteDeleteMagazineCover(magazineId),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(["getMagazineBasicInformations"]),
    }
  );
};
export const useDeleteMagazineArticlePage = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (magazineArticlePageId: number) =>
      deleteDeleteMagazineArticlePage(magazineArticlePageId),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(["getAllMagazineArticlePages"]),
    }
  );
};
