import Skeleton from '@mui/material/Skeleton';

const MagazineCardSkeleton = () => {
  return (
    <div className="magazineLinkCard-skeleton">
      <Skeleton sx={{ height: 160 }} animation="wave" variant="rounded" />
      <div className="magazineLinkCard-skeleton-content">
        <Skeleton
          sx={{ height: 20, width: '40%' }}
          animation="wave"
          variant="rounded"
        />
        <Skeleton
          sx={{ height: 20, width: '80%' }}
          animation="wave"
          variant="rounded"
        />
        <Skeleton sx={{ height: 30 }} animation="wave" variant="rounded" />
        <div className="magazineLinkCard-skeleton-content-tagsSkeleton">
          <Skeleton
            sx={{ height: 30, width: '30%', borderRadius: '15px' }}
            animation="wave"
            variant="rounded"
          />
          <Skeleton
            sx={{ height: 30, width: '20%', borderRadius: '15px' }}
            animation="wave"
            variant="rounded"
          />
        </div>
      </div>
    </div>
  );
};
export default MagazineCardSkeleton;
