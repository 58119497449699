import Joi from "joi";
import { validateFile } from "../../utils/fileValidation";

export const EditMagazineSchema = Joi.object({
  title: Joi.string().required().messages({ "string.empty": "Required!" }),
  description: Joi.string().required().min(1).max(500).messages({
    "string.empty": "Required!",
    "string.min": "Description must be completed",
  }),

  editorialNumber: Joi.string()
    .required()
    .messages({ "string.empty": "Required!" }),
  magazinePdf: Joi.alternatives()
    .try(
      Joi.custom((value, helpers) => {
        return validateFile(value, 1000000000000, ["application/pdf"], helpers);
      }, "file-validate")
    )
    .optional()
    .allow(null)
    .messages({
      "any.fileSize":
        "The maximum file size that can be uploaded as an pdf is 10 MB.",
    }),
});
