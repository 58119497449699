import Joi from "joi";
import { validateFile } from "../../utils/fileValidation";

export const EditMyProfileSchema = Joi.object({
  FirstName: Joi.string().required().messages({ "string.empty": "Required!" }),
  LastName: Joi.string().required().messages({ "string.empty": "Required!" }),
  Email: Joi.string()
    .email({ tlds: { allow: false } })
    .messages({
      "string.empty": "Required!",
      "string.email": "Email must be valid!",
    }),
  ProfilePicture: Joi.alternatives()
    .try(
      Joi.custom(
        (value, helpers) =>
          validateFile(
            value,
            5,
            ["image/jpeg", "image/jpg", "image/png"],
            helpers
          ),
        "file-validate"
      )
    )
    .messages({
      "any.fileSize":
        "The maximum file size that can be uploaded as an image is 5 MB.",
      "any.fileType": "This Type is not allowed!",
    }),
  LanguageId: Joi.string().default("1"),
});
