import {FC, useEffect, useState} from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import {useForm} from "react-hook-form";
import {joiResolver} from "@hookform/resolvers/joi";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CircularProgress from "@mui/material/CircularProgress";
import {useParams} from "react-router-dom";
import {AddEditMagazineCoverInputModel} from "../../../interfaces/models/magazineModels/AddEditMagazineCover.model";
import {AddEditMagazineCoverSchema} from "../../../schemas/magazineSchemas/AddEditMagazineCover.schema";
import {usePostAddMagazineCover} from "../../../react-query/hooks/mutationHooks/Magazine";
import {useImagePreview} from "../../../hooks/useImagePreview";
import Popover from "../../atoms/Popover/Popover";
import {useUserClaimStore} from "../../../zustand-store/UserClaim.store";
import {useTranslation} from "react-i18next";

interface coverImgProps {
    coverPath: string;
}

const CoverImg: FC<coverImgProps> = ({coverPath}) => {
    const {t} = useTranslation();
    const {canRoleEdit} = useUserClaimStore();
    const {magazineId} = useParams();
    const [changeCoverImgPopover, setChangeCoverImgPopover] = useState(false);
    const [saveCoverImgPopover, setSaveCoverImgPopover] = useState(false);
    const [cancelCoverImgPopover, setCancelCoverImgPopover] = useState(false);
    const {
        setValue,
        handleSubmit,
        reset,
        clearErrors,
        watch,
        formState: {errors},
    } = useForm<AddEditMagazineCoverInputModel>({
        resolver: joiResolver(AddEditMagazineCoverSchema),
    });

    const {mutate: changeCoverImg, isLoading} = usePostAddMagazineCover(
        Number(magazineId)
    );
    const {image} = useImagePreview(watch("Cover"));

    const handleSubmitCoverImg = (coverImg: AddEditMagazineCoverInputModel) => {
        const coverImgPayload = new FormData();
        coverImgPayload.append("Cover", coverImg.Cover);
        changeCoverImg(coverImgPayload, {
            onSuccess: () => {
                reset();
            },
        });
    };
    const handleCancel = () => {
        reset();
        clearErrors();
    };

    const handleChangeCoverImgMouseEnter = () => {
        setChangeCoverImgPopover(true);
    };
    const handleChangeCoverImgMouseLeave = () => {
        setChangeCoverImgPopover(false);
    };
    const handleSaveCoverImgMouseEnter = () => {
        setSaveCoverImgPopover(true);
    };

    const handleSaveCoverImgMouseLeave = () => {
        setSaveCoverImgPopover(false);
    };
    const handleCancelChangeCoverImgMouseEnter = () => {
        setCancelCoverImgPopover(true);
    };

    const handleCancelChangeCoverImgMouseLeave = () => {
        setCancelCoverImgPopover(false);
    };

    useEffect(() => {
        setCancelCoverImgPopover(false);
        setChangeCoverImgPopover(false);
        setSaveCoverImgPopover(false);
    }, [watch("Cover")]);

    return (
        <form
            className="magazineCover"
            onSubmit={handleSubmit(handleSubmitCoverImg)}
        >
            <h3>{t("dashboard.magazine.cover_magazine")}</h3>
            <p>{`${t("dashboard.reusable.allowed_file_types")}: PNG, JPG.`}</p>
            {!!errors?.Cover && <p className="pError">{errors.Cover?.message}</p>}
            <div className="coverImg">
                <img
                    src={!!image && !errors?.Cover ? image : coverPath}
                    alt="cover"
                    style={isLoading ? {filter: "opacity(30%)"} : {}}
                />
                {canRoleEdit && (
                    <label className="cameraIconInput">
                        <input
                            type="file"
                            onChange={(e) => {
                                setValue("Cover", e.target.files![0], {
                                    shouldValidate: true,
                                });
                            }}
                        />
                        {(!!errors?.Cover || image === undefined) && (
                            <div
                                className="changeCoverIcon divWithPopover"
                                onMouseEnter={handleChangeCoverImgMouseEnter}
                                onMouseLeave={handleChangeCoverImgMouseLeave}
                            >
                                <CameraAltIcon
                                    className="icon"
                                    sx={{
                                        color: "rgba(122, 122, 122, 0.7)",
                                        fontSize: "25px",
                                        backgroundColor: "transparent",
                                        borderRadius: "500px",
                                        border: "none",
                                        "&:hover": {
                                            color: "green",
                                        },
                                    }}
                                />
                                <Popover
                                    show={changeCoverImgPopover}
                                    text={t("dashboard.magazine.change_cover")}
                                />
                            </div>
                        )}
                    </label>
                )}
                {isLoading && (
                    <div className="changingCoverImgLoader">
                        <CircularProgress
                            color="success"
                            sx={{
                                fontSize: "20px",
                            }}
                        />
                    </div>
                )}
                {!errors?.Cover && image !== undefined && (
                    <div className="saveAndRemoveIcons">
                        <div
                            className="divWithPopover"
                            onMouseEnter={handleCancelChangeCoverImgMouseEnter}
                            onMouseLeave={handleCancelChangeCoverImgMouseLeave}
                        >
                            <HighlightOffIcon
                                onClick={handleCancel}
                                sx={{
                                    className: "icon",
                                    color: "rgba(122, 122, 122, 0.7)",
                                    fontSize: "30px",
                                    backgroundColor: "transparent",
                                    borderRadius: "500px",
                                    border: "none",
                                    "&:hover": {
                                        color: "red",
                                        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                                    },
                                }}
                            />
                            <Popover
                                show={cancelCoverImgPopover}
                                text={t("dashboard.reusable.cancel")}
                            />
                        </div>
                        <div
                            className="divWithPopover"
                            onMouseEnter={handleSaveCoverImgMouseEnter}
                            onMouseLeave={handleSaveCoverImgMouseLeave}
                        >
                            <button type="submit">
                                <CheckCircleOutlineIcon
                                    sx={{
                                        className: "icon",
                                        color: "rgba(122, 122, 122, 0.7)",
                                        fontSize: "30px",
                                        backgroundColor: "transparent",
                                        borderRadius: "500px",
                                        border: "none",
                                        "&:hover": {
                                            color: "rgb(0, 198, 0)",
                                            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                                        },
                                    }}
                                />
                            </button>
                            <Popover
                                show={saveCoverImgPopover}
                                text={t("dashboard.magazine.save_cover")}
                            />
                        </div>
                    </div>
                )}
            </div>
        </form>
    );
};

export default CoverImg;
