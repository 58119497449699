import { basicParamsTypes } from "../../interfaces/models/basicParams.model";
import axios from "../../initializations/axios";
import { sizeOfItemsPerPage } from "../../static/sizeOfItemsPerPage";

export const getAllFolders = async (
  getAllFolderParams: basicParamsTypes
): Promise<any> => {
  const { data } = await axios.get("Folder/AllFolders", {
    params: {
      searchWord: getAllFolderParams.searchWord,
      pageSize: sizeOfItemsPerPage,
      pageNumber: getAllFolderParams.pageNumber,
    },
  });
  return data;
};
export const getAllFoldersFiles = async (
  FolderId: number,
  getAllFolderFilesParams: basicParamsTypes
) => {
  const { data } = await axios.get(`Folder/AllFolderFiles/${FolderId}`, {
    params: {
      searchWord: getAllFolderFilesParams.searchWord,
      pageSize: sizeOfItemsPerPage,
      pageNumber: getAllFolderFilesParams.pageNumber,
    },
  });
  return data;
};
