import {useQuery, UseQueryOptions, UseQueryResult,} from "@tanstack/react-query";
import {basicParamsTypes} from "../../../interfaces/models/basicParams.model";
import {ResponseModel} from "../../../interfaces/models/Response.model";
import {MagzineResponseModel} from "../../../interfaces/models/magazineModels/MagazineResponse.model";
import {BasicResponseModel} from "../../../interfaces/models/BasicResponse.model";
import {MagazineArticleResponseModel} from "../../../interfaces/models/magazineModels/MagazineArticleResponse.model";
import {
  getAllMagazineArticles,
  getAllMagazineArticlesPages,
  getAllMagazines,
  getAllPublishedMagazines,
  getMagazineArticlesPageById,
  getMagazineBasicInformationById,
  getReadMagazine,
  getTemplate,
  getTemplates,
  getTwoMostViewedMagazine,
} from "../../queries/Magazine";
import {MagazineArticlePageResponseModel} from "../../../schemas/magazineSchemas/MagazineArticlePage.schema";
import {ReadMagazineResponseModel} from "../../../interfaces/models/magazineModels/ReadMagazineResponse.model";
import {Template} from "../../../interfaces/models/magazineModels/Template.model";

export const useGetAllMagazinesQuery = (
    getAllMagazinesParams?: basicParamsTypes,
    options?: Omit<
        UseQueryOptions<ResponseModel<MagzineResponseModel[]>>,
        "queryKey" | "queryFn"
    >
): UseQueryResult<ResponseModel<MagzineResponseModel[]>> => {
    return useQuery(
        [
            "getAllMagazines",
            getAllMagazinesParams?.pageNumber,
            getAllMagazinesParams?.searchWord,
        ],
        () => getAllMagazines(getAllMagazinesParams),
        options
    );
};
export const useGetAllPublishedMagazinesQuery = (
    getAllMagazinesParams?: basicParamsTypes,
    options?: Omit<
        UseQueryOptions<ResponseModel<MagzineResponseModel[]>>,
        "queryKey" | "queryFn"
    >
): UseQueryResult<ResponseModel<MagzineResponseModel[]>> => {
    return useQuery(
        [
            "getAllPublishedMagazines",
            getAllMagazinesParams?.pageNumber,
            getAllMagazinesParams?.searchWord,
        ],
        () => getAllPublishedMagazines(getAllMagazinesParams),
        options
    );
};
// export const useGetAllMagazinesInfiniteQuery = (
//   getAllMagazinesParams?: basicParamsTypes,
//   options?: Omit<
//     UseInfiniteQueryOptions<ResponseModel<MagzineResponseModel[]>>,
//     'queryKey' | 'queryFn'
//   >
// ): UseInfiniteQueryResult<ResponseModel<MagzineResponseModel[]>> => {
//   return useInfiniteQuery(
//     [
//       'getAllMagazines',
//       getAllMagazinesParams?.pageNumber,
//       getAllMagazinesParams?.searchWord,
//     ],
//     () => getAllMagazines(getAllMagazinesParams),
//     options
//   );
// };
export const useGetMagazineQuery = (
    magazineId: number,
    options?: Omit<
        UseQueryOptions<BasicResponseModel<MagzineResponseModel>>,
        "queryKey" | "queryFn"
    >
): UseQueryResult<BasicResponseModel<MagzineResponseModel>> => {
    return useQuery(
        ["getMagazine", magazineId],
        () => getMagazineBasicInformationById(magazineId),
        options
    );
};
export const useGetAllMagazineArticlesQuery = (
    magazineId: number,
    options?: Omit<
        UseQueryOptions<BasicResponseModel<MagazineArticleResponseModel[]>>,
        "queryKey" | "queryFn"
    >
): UseQueryResult<BasicResponseModel<MagazineArticleResponseModel[]>> => {
    return useQuery(
        ["getMagazineArticle"],
        () => getAllMagazineArticles(magazineId),
        options
    );
};
export const useGetAllMagazineArticlePagesQuery = (
    magazineArticleId: number,
    options?: Omit<
        UseQueryOptions<BasicResponseModel<MagazineArticlePageResponseModel[]>>,
        "queryKey" | "queryFn"
    >
): UseQueryResult<BasicResponseModel<MagazineArticlePageResponseModel[]>> => {
    return useQuery(
        ["getAllMagazineArticlePages", magazineArticleId],
        () => getAllMagazineArticlesPages(magazineArticleId),
        options
    );
};

export const useGetMagazineArticlePageById = (
    magazineArticlePageId: number,
    options?: Omit<
        UseQueryOptions<BasicResponseModel<MagazineArticlePageResponseModel>>,
        "queryKey" | "queryFn"
    >
): UseQueryResult<BasicResponseModel<MagazineArticlePageResponseModel>> => {
    return useQuery(
        ["getMagazineArticlePageById", magazineArticlePageId],
        () => getMagazineArticlesPageById(magazineArticlePageId),
        options
    );
};

export const useGetTwoMostViewedMagazines = (
    options?: Omit<
        UseQueryOptions<BasicResponseModel<MagzineResponseModel[]>>,
        "queryKey" | "queryFn"
    >
): UseQueryResult<BasicResponseModel<MagzineResponseModel[]>> => {
    return useQuery(
        ["getTwoMostViewedMagazines"],
        () => getTwoMostViewedMagazine(),
        options
    );
};

export const useGetTemplates = (
    searchWord: string,
    options?: Omit<
        UseQueryOptions<BasicResponseModel<Template[]>>,
        "queryKey" | "queryFn"
    >
): UseQueryResult<BasicResponseModel<Template[]>> => {
    return useQuery(
        ["getTemplates", searchWord],
        () => getTemplates(searchWord),
        options
    );
};
export const useGetTemplate = (
    id: number,
    options?: Omit<
        UseQueryOptions<BasicResponseModel<Template[]>>,
        "queryKey" | "queryFn"
    >
): UseQueryResult<BasicResponseModel<Template[]>> => {
    return useQuery(["getTemplate"], () => getTemplate(id), options);
};

export const useGetReadMagazineById = (
    magazineId: number,
    options?: Omit<
        UseQueryOptions<ResponseModel<ReadMagazineResponseModel>>,
        "queryKey" | "queryFn"
    >
): UseQueryResult<ResponseModel<ReadMagazineResponseModel>> => {
    return useQuery(
        ["getReadMagazineById", magazineId],
        () => getReadMagazine(magazineId),
        options
    );
};
