import {ReactElement, useEffect} from "react";
import "./_ContactFollowLayout.scss";
import EmailIcon from "@mui/icons-material/Email";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import {useErrorsStore} from "../../zustand-store/Errors.store";
import Header from "../../partials/Header/Header";
import Footer from "../../partials/Footer/Footer";
import {useLocation} from "react-router-dom";
import {t} from "i18next";

export function ContactFollowLayout({
                                        formToComplite,
                                    }: {
    formToComplite: ReactElement;
}): ReactElement {
    const location = useLocation();
    const locationPath = location.pathname.toString().split("/:")[0];
    const {reset} = useErrorsStore();
    useEffect(() => {
        reset();
    }, [location.pathname]);

    return (
        <>
            <Header/>
            <div
                className={`contactFollowLayout  ${
                    locationPath === "/subscribe" ? "subscribe" : "contact"
                }`}
            >
                <div className="contactFollowLayout-content userPagesScreen">
                    <div className="contactFollowLayout-content-part1">
                        <h1>
                            {locationPath === "/subscribe"
                                ? t("public.subscribe.title")
                                : t("public.contact_us.title")}
                        </h1>
                        <p style={{maxWidth: "550px"}}>
                            {locationPath === "/subscribe"
                                ? ""
                                : t("public.contact_us.subtitle")}
                        </p>
                        <div className="infos">
                            <div className="info ">
                                <EmailIcon sx={{color: "#A4C408"}}/>
                                <p>info@patro.be</p>
                            </div>
                            <div className="info ">
                                <FmdGoodIcon sx={{color: "#A4C408"}}/>
                                <p>Rue de l'Hôpital 15, 6060 Charleroi</p>
                            </div>
                        </div>
                    </div>
                    <div className="form" style={{backgroundColor: "white"}}>
                        {formToComplite}
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
}
