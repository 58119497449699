import { ClaimValueResponse } from "../../interfaces/models/adminModels/ClaimValueResponse.model";

export function getClaimId(
  claimValues: ClaimValueResponse[] | undefined,
  claimValue: string
) {
  if (claimValues !== undefined) {
    return claimValues.filter((value) => value.claimValue === claimValue)[0];
  }
}
