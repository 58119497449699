import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField/TextField";
import {useRef, useState} from "react";
import Button from "../../components/atoms/Button/Button.tsx";
import NoResults from "../../components/organisms/NoResults/NoResults";
import MuiPagination from "../../components/molecules/MuiPagination/MuiPagination";
import LoadingStatus from "../../components/organisms/LoadingStatus/LoadingStatus";
import {useGetAllMediaFilesQuery} from "../../react-query/hooks/queriesHooks/MediaFile";
import {useModalStore} from "../../zustand-store/Modal.store";
import AddMediaFile from "../../modals/dashboard/AddMediaFile";
import MediaFileItem from "../../components/molecules/MediaFile/MediaFileItem";
import {isImage} from "../../utils/isImage";
import {isVideo} from "../../utils/isVideo";
import {useUserClaimStore} from "../../zustand-store/UserClaim.store";
import {useTranslation} from "react-i18next";
import DataLayout from "../../components/atoms/DataLayout/DataLayout";
import {Box} from "@mui/material";

const MediaFiles = () => {
    const {t} = useTranslation();
    const {setTitle, setDescription, setModalShow, setModalContent} =
        useModalStore();
    const {canRoleCreate} = useUserClaimStore();
    const [page, setPage] = useState(1);
    const [searchWord, setSearchWord] = useState<string>("");
    const mediaRef = useRef<HTMLDivElement>(null);
    const {data: {data: files, metaData} = {}, isLoading} =
        useGetAllMediaFilesQuery({
            pageNumber: String(page),
            searchWord: searchWord,
        });

    const handleMedia = () => mediaRef.current?.scrollIntoView({
        behavior: 'smooth'
    });

    const handleModalShow = () => {
        setTitle("+ Add Media File");
        setDescription("You can add Images and Videos...");
        setModalShow(true);
        setModalContent(<AddMediaFile/>);
    };

    return (
        <DataLayout title={t("dashboard.reusable.media_files")}
                    options={<Box display="flex" gap={2} justifyContent="space-between" width="100%"
                                  alignItems="center">
                        <TextField
                            id="outlined-basic"
                            label={t("dashboard.media_files.search_media_files")}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" style={{
                                        padding: 0,
                                        margin: 0,
                                    }}>
                                        <SearchIcon sx={{color: "#a1a7b3"}}/>
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(e) => {
                                setSearchWord(e.target.value);
                                setPage(1);
                            }}
                            variant="outlined"
                            color="success"
                        />
                        <Button
                            show={canRoleCreate}
                            onClick={handleModalShow}
                            content={`+ ${t("dashboard.media_files.add_media_file")}`}
                            styleType="first"
                            type="button"
                        />
                    </Box>}>
            {isLoading ? (
                <LoadingStatus/>
            ) : (
                <>
                    {metaData?.totalItemCount !== 0 ? (
                        <Box minHeight={600}>
                            <Box className="grid grid-cols-1 md:grid-cols-3" minHeight={500} gap={4}>
                                {files?.map((file) => (
                                    <MediaFileItem
                                        key={file.id}
                                        fileId={file.id}
                                        isImage={!!isImage(file.filePath)}
                                        isVideo={!!isVideo(file.filePath)}
                                        videoType={`${file.filePath.split(".").pop()}`}
                                        filePath={file.filePath}
                                        fileName={file.fileName}
                                        uploader={file.uploader}
                                    />
                                ))}
                            </Box>
                            <Box className="pagesStyle-mediaFiles-pagination" marginTop={2}>
                                <MuiPagination
                                    pagesNumber={metaData?.pageCount}
                                    page={page}
                                    setPage={setPage}
                                    handleRef={handleMedia}
                                />
                            </Box>
                        </Box>
                    ) : (
                        <NoResults/>
                    )}
                </>
            )}
        </DataLayout>
    );
};
export default MediaFiles;
