import "./_DashboardMagazines.scss";
import SearchIcon from "@mui/icons-material/Search";
import {useNavigate} from "react-router-dom";
import {ChangeEvent, useRef, useState} from "react";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Button from "../../components/atoms/Button/Button.tsx";
import PatroMagazines from "../../components/organisms/PatroMagazines/PatroMagazines";
import {useUserClaimStore} from "../../zustand-store/UserClaim.store";
import MuiModal from "../../modals/MuiModal";
import {Box, Divider, Radio, RadioGroup, Typography,} from "@mui/material";
import pdficon from "../../assets/images/pdf-icon.png";
import pagebuildericon from "../../assets/images/pagebuilder-icon.png";
import {useTranslation} from "react-i18next";
import DataLayout from "../../components/atoms/DataLayout/DataLayout";

const DashboardMagazines = () => {
    const {t} = useTranslation();
    const {canRoleCreate} = useUserClaimStore();
    /**
     * local state
     */
    const [page, setPage] = useState<number>(1);
    const [searchWord, setSearchWord] = useState<string>("");
    const magsRef = useRef<HTMLDivElement | null>(null);
    const [showModal, setShowModal] = useState(false);
    const [radioValue, setRadioValue] = useState("");

    /**
     * handlers
     */
    const handleMags = () => magsRef.current?.scrollIntoView({
        behavior: 'smooth'
    })
    const handleModalShow = () => setShowModal(true);
    const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
        setRadioValue((event.target as HTMLInputElement).value);
    };
    const handleContinue = () => {
        navigate(
            `create-magazine?type=${radioValue === "pdf" ? "pdf" : "pagebuilder"}`
        );
    };

    /**
     * hooks
     */
    const navigate = useNavigate();

    return (
        <>
            <DataLayout title={t("dashboard.reusable.magazines")}
                        options={
                            <Box display="flex" justifyContent="space-between" width="100%" alignItems="center"
                                 gap={2}>
                                <TextField
                                    id="outlined-basic"
                                    label={t("dashboard.magazines.search_magazine")}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start" style={{
                                                padding: 0,
                                                margin: 0,
                                            }}>
                                                <SearchIcon sx={{color: "#a1a7b3"}}/>
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={(e) => {
                                        setSearchWord(e.target.value);
                                        setPage(1);
                                    }}
                                    variant="outlined"
                                    color="success"
                                />
                                <Button
                                    content={`+ ${t("dashboard.magazines.add_magazine")}`}
                                    styleType="first"
                                    type="button"
                                    show={canRoleCreate}
                                    onClick={handleModalShow}
                                />
                            </Box>
                        }>
                <PatroMagazines
                    isInDashboard={true}
                    pageNumber={String(page)}
                    searchWord={searchWord}
                    page={page}
                    setPage={setPage}
                    handleMags={handleMags}
                />
            </DataLayout>
            <MuiModal
                title={`${t(
                    "dashboard.magazines.modals.what_would_you_like_to_publish"
                )}?`}
                description={`${t("dashboard.magazines.modals.select_an_option")}?`}
                modalShow={showModal}
                setModalShow={setShowModal}
                modalContent={
                    <>
                        <RadioGroup
                            name="use-radio-group"
                            defaultValue={radioValue}
                            onChange={handleRadioChange}
                        >
                            <div className="radioGroup">
                                <button type="button"
                                        className="ghost-button interactive-cursor item interactive-button"
                                        onClick={() => setRadioValue('pdf')}>
                                    <img src={pdficon} alt="pdf icon"/>
                                    <Radio value="pdf" checked={radioValue === 'pdf'}/>
                                    <Typography
                                        fontSize="13px"
                                        fontWeight="bold"
                                        textAlign="center"
                                    >
                                        {t("dashboard.magazines.modals.pdf_document")}
                                    </Typography>
                                    <Typography
                                        fontSize="12px"
                                        sx={{color: "#9A9A9A"}}
                                        textAlign="center"
                                    >
                                        {t("dashboard.magazines.modals.upload_you_pfd_magazine")}
                                    </Typography>
                                </button>
                                <Divider orientation="vertical" flexItem/>
                                <button type="button"
                                        className="ghost-button interactive-cursor item interactive-button"
                                        onClick={() => setRadioValue('pagebuilder')}>
                                    <img src={pagebuildericon} alt="page builder"/>
                                    <Radio value="pagebuilder" checked={radioValue === 'pagebuilder'}/>
                                    <Typography
                                        fontSize="13px"
                                        fontWeight="bold"
                                        textAlign="center"
                                    >
                                        {t("dashboard.magazines.modals.page_builder")}
                                    </Typography>
                                    <Typography
                                        fontSize="12px"
                                        sx={{color: "#9A9A9A"}}
                                        textAlign="center"
                                    >
                                        {t(
                                            "dashboard.magazines.modals.build_your_magazine_from_scratch"
                                        )}
                                    </Typography>
                                </button>
                            </div>
                        </RadioGroup>
                        <div className="continuebutton" style={{marginTop: "30px"}}>
                            <Button
                                content={t("dashboard.magazines.modals.continue")}
                                type="button"
                                onClick={handleContinue}
                            />
                        </div>
                    </>
                }
            />
        </>
    );
};
export default DashboardMagazines;
