import {useMutation, UseMutationOptions, UseMutationResult, useQueryClient,} from '@tanstack/react-query';
import {BasicResponseModel} from '../../../interfaces/models/BasicResponse.model';
import {AnswerQuizInputModel, AnswerQuizResponseModel,} from '../../../interfaces/models/quizModels/AnswerQuiz.model';
import {CreateQuizInputModel} from '../../../interfaces/models/quizModels/CreateQuiz.model';
import {EditQuizInputModel} from '../../../interfaces/models/quizModels/EditQuiz.model';
import {deleteDeleteQuiz, postAnswerQuiz, postCreateQuiz, putEditQuiz,} from '../../mutations/Quiz';

//-----POST mutationsHooks-----
export const useCreateQuiz = () => {
    const queryClient = useQueryClient();
    return useMutation(
        (createQuizPayload: CreateQuizInputModel) =>
            postCreateQuiz(createQuizPayload),
        {
            onSuccess: () =>
                queryClient.invalidateQueries(['getAllMagazineArticlePages']),
        }
    );
};
export const useAnswerQuiz = (
    quizId: number,
    options?: Omit<
        UseMutationOptions<
            BasicResponseModel<AnswerQuizResponseModel>,
            unknown,
            any,
            unknown
        >,
        'mutationKey' | 'mutationFn'
    >
): UseMutationResult<
    BasicResponseModel<AnswerQuizResponseModel>,
    unknown,
    any,
    unknown
> => {
    return useMutation(
        ['answerQuiz'],
        (answerQuizPayload: AnswerQuizInputModel) =>
            postAnswerQuiz(quizId, answerQuizPayload),
        options
    );
};
//-----PUT mutationsHooks-----
export const useEditQuiz = (quizId: number) => {
    const queryClient = useQueryClient();
    return useMutation(
        (editQuizPayload: EditQuizInputModel) =>
            putEditQuiz(quizId, editQuizPayload),
        {
            onSuccess: () =>
                queryClient.invalidateQueries(['getAllMagazineArticlePages']),
        }
    );
};
//-----DELETE mutationsHooks----
export const useDeleteQuiz = () => {
    const queryClient = useQueryClient();
    return useMutation((quizId: number) => deleteDeleteQuiz(quizId), {
        onSuccess: () =>
            queryClient.invalidateQueries(['getAllMagazineArticlePages']),
    });
};
