import { roleClaimsResponseModel } from "../../interfaces/models/adminModels/RoleClaimsResponse.model";

export function getClaimValues(
  roleClaims: roleClaimsResponseModel[] | undefined,
  claimType: string
) {
  if (roleClaims !== undefined) {
    return roleClaims.filter(function (roleClaim) {
      return roleClaim.claimType === claimType;
    })[0];
  }
}
