import Joi from "joi";
import { validateFile } from "../../utils/fileValidation";

export const AddEditMagazineCoverSchema = Joi.object({
  Cover: Joi.alternatives()
    .try(
      Joi.custom(
        (value, helpers) =>
          validateFile(
            value,
            5,
            ["image/jpeg", "image/jpg", "image/png"],
            helpers
          ),
        "file-validate"
      )
    )
    .messages({
      "any.fileSize":
        "The maximum file size that can be uploaded as an image is 5 MB.",
      "any.fileType": "This Type is not allowed!",
    }),
});
