import TextField from "@mui/material/TextField";
import "./_ContactFollowOrganisms.scss";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { ContactInputModel } from "../../../interfaces/models/accountModels/Contact.model";
import { ContactUsSchema } from "../../../schemas/accountSchemas/ContactUs.schema";
import { usePostContactUs } from "../../../react-query/hooks/mutationHooks/Account";
import { removeEmptyField } from "../../../utils/removeEmptyField";
import Button from "../../atoms/Button/Button.tsx";
import StatusState from "../../molecules/StatusState/StatusState";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ContactInputModel>({
    resolver: joiResolver(ContactUsSchema(t)),
  });
  const { mutate: contactUs, isLoading, isSuccess } = usePostContactUs();
  const handleContactSubmit = (contactPayload: ContactInputModel) => {
    removeEmptyField(contactPayload);
    contactUs(contactPayload);
  };
  return (
    <form
      className="contactFollowOrganisms"
      onSubmit={handleSubmit(handleContactSubmit)}
    >
      <div className="firstInputs">
        <TextField
          fullWidth
          id="outlined-basic"
          label={t("reusable.name")}
          color="success"
          variant="outlined"
          error={!!errors.firstName}
          helperText={errors.firstName?.message}
          {...register("firstName")}
        />
        <TextField
          fullWidth
          id="outlined-basic"
          label={t("reusable.last_name")}
          color="success"
          variant="outlined"
          {...register("lastName")}
          error={!!errors.lastName}
          helperText={errors.lastName?.message}
        />
      </div>
      <TextField
        fullWidth
        id="outlined-basic"
        label={t("reusable.email")}
        color="success"
        type="email"
        variant="outlined"
        {...register("email")}
        error={!!errors.email}
        helperText={errors.email?.message}
      />
      <TextField
        fullWidth
        id="outlined-basic"
        label={t("public.contact_us.phone_number")}
        color="success"
        {...register("phoneNumber")}
        variant="outlined"
        error={!!errors.phoneNumber}
        helperText={errors.phoneNumber?.message}
      />
      <TextField
        fullWidth
        id="outlined-basic"
        label={t("public.contact_us.message")}
        color="success"
        variant="outlined"
        {...register("message")}
        multiline
        inputProps={{ maxLength: 500 }}
        error={!!errors.message}
        helperText={errors.message?.message}
      />
      {!isLoading && (
        <div className="contactFollowOrganisms-buttons">
          <Button
            content={t("public.contact_us.send_message")}
            styleType="second"
            type="submit"
            fulllWidth
            disable={isSuccess}
          />
        </div>
      )}
      <div className="contactFollowOrganisms-status">
        {isLoading && (
          <StatusState
            statusText={t("reusable.loading")}
            statusType="loading"
          />
        )}
        {isSuccess && (
          <StatusState
            statusText={t("public.contact_us.success_status")}
            statusType="success"
          />
        )}
      </div>
    </form>
  );
};
export default Contact;
