import {useTranslation} from "react-i18next";
import NoResultsFoundIcon from "../../../assets/images/NoResultsFoundIcon.png";
import "./_NoResults.scss";

const NoResults = () => {
    const {t} = useTranslation();
    return (
        <div className="noResultsFound">
            <div>
                <img src={NoResultsFoundIcon} alt="No results found icon"/>

                <h3>
                    {t("reusable.no_results")}
                    <span> {t("reusable.found")}</span>
                </h3>
            </div>
        </div>
    );
};
export default NoResults;
