import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { ReactElement } from "react";
import "./_MuiModal.scss";

export default function MuiModal({
  modalContent,
  modalShow,
  setModalShow,
  title,
  description,
  setAnchorEl,
  zIndex,
}: {
  modalContent: ReactElement | null;
  modalShow: boolean;
  setModalShow: (modalShow: boolean) => void;
  title?: string;
  description?: string;
  setAnchorEl?: (value: null | HTMLElement) => void;
  zIndex?: number;
}) {
  const handleClose = () => {
    setModalShow(false);
    if (setAnchorEl) {
      setAnchorEl(null);
    }
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={modalShow}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      sx={{ zIndex: !zIndex ? "" : `${zIndex}` }}
    >
      <Fade in={modalShow}>
        <Box>
          <div className="muiModal">
            <div className="muiModal-header">
              <h3>{title}</h3>
              <p>{description}</p>
            </div>
            {modalContent}
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
