import { MagazineReadingStatisticsParms } from './../../interfaces/models/statistics/Dashboard.model';
import { MagazineReadingStatisticsModel } from '../../interfaces/models/statistics/Dashboard.model';
import axios from '../../initializations/axios';

export const getMagazineReadingStatistics = async (
  params: MagazineReadingStatisticsParms
): Promise<MagazineReadingStatisticsModel[]> => {
  const { data } = await axios.get('statistics/getMagazineReadingStatistics', {
    params,
  });
  return data.data;
};
