import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import PageNotFoundIcon from "../../assets/images/404Icon.png";
import Button from "../../components/atoms/Button/Button.tsx";
import "./_Page404.scss";

const Page404 = () => {
  const navigate = useNavigate();
  const navigateToPrevPage = () => {
    navigate(-1);
  };
  return (
    <div className="page404">
      <div>
        <img src={PageNotFoundIcon} alt="" />
        <h3>
          <span>{t("dashboard.page404.page_not_found")}</span>
        </h3>
        <p>{t("dashboard.page404.looks_like_this_page_donot_exist")}</p>
        <Button
          content={t("dashboard.page404.go_back")}
          styleType="second"
          type="button"
          onClick={navigateToPrevPage}
        />
      </div>
    </div>
  );
};

export default Page404;
