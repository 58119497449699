import ChangePassword from "../../components/organisms/LoginPasswordOrganisms/ChangePassword";
import { useState } from "react";
import ForgotPassword from "../../components/organisms/LoginPasswordOrganisms/ForgotPassword";

const ResetPassword = () => {
  const [step, setStep] = useState<number>(1);
  return (
    <div>
      {step === 1 ? <ForgotPassword setStep={setStep} /> : <ChangePassword />}
    </div>
  );
};
export default ResetPassword;
