import "./_ListItems.scss";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { FC, useState } from "react";
import { UserResponseModel } from "../../../interfaces/models/adminModels/User.model";
import { useDeleteUser } from "../../../react-query/hooks/mutationHooks/Admin";
import MuiModal from "../../../modals/MuiModal";
import Delete from "../../../modals/dashboard/Delete";
import AddUser from "../../../modals/dashboard/AddUser";
import { useUserClaimStore } from "../../../zustand-store/UserClaim.store";
import { useTranslation } from "react-i18next";

const User: FC<UserResponseModel> = ({ userId, fullName, email, roles }) => {
  const { t } = useTranslation();
  const { canRoleEdit, canRoleDelete } = useUserClaimStore();
  //deleteUser
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const handleDeleteModalShow = () => setDeleteModalShow(true);

  const { mutate: deleteUser, isLoading, isSuccess } = useDeleteUser();
  const handleDeleteUser = () => {
    deleteUser(userId);
  };

  //editUser
  const [editModalShow, setEditModalShow] = useState(false);
  const handleEditModalShow = () => setEditModalShow(true);
  let color = Math.floor(Math.random() * 16777215).toString(16);

  return (
    <tr className="cursor-default">
      <td className="firstItem">
        <p style={{ fontWeight: "500" }}>{fullName}</p>
      </td>
      <td>
        <p>{email}</p>
      </td>
      <td className={`${!(canRoleEdit || canRoleDelete) ? "lastItem" : ""}`}>
        <div
          className="listItem-role"
          style={{
            color: `#${color}`,
            border: `1px solid #${color}`,
          }}
        >
          {roles !== undefined ? roles[0].role ?? "" : ""}
        </div>
      </td>
      {(canRoleDelete || canRoleEdit) && (
        <td className="lastItem">
          <div className="listItem-icons tables-item">
            {canRoleEdit && (
              <button
                type="button"
                className="muiIcon ghost-button interactive-cursor"
              >
                <EditIcon onClick={handleEditModalShow} />
              </button>
            )}
            {canRoleDelete && (
              <button
                type="button"
                className="muiIcon ghost-button interactive-cursor"
              >
                <DeleteIcon onClick={handleDeleteModalShow} />
              </button>
            )}
          </div>
        </td>
      )}
      <MuiModal
        title={t("dashboard.users.modals.delete_user")}
        description={t("dashboard.users.modals.are_you_sure")}
        modalContent={
          <Delete
            deleteFunction={handleDeleteUser}
            setModalShow={setDeleteModalShow}
            isLoading={isLoading}
            isSuccess={isSuccess}
          />
        }
        modalShow={deleteModalShow}
        setModalShow={setDeleteModalShow}
      />
      <MuiModal
        title="Edit User"
        description="Complete those fields and press save to update"
        modalContent={
          <AddUser
            update
            userId={userId}
            userEmail={email}
            userFullName={fullName}
            setModalShow={setEditModalShow}
            userRole={roles}
          />
        }
        modalShow={editModalShow}
        setModalShow={setEditModalShow}
      />
    </tr>
  );
};
export default User;
