import {ReactElement, useLayoutEffect, useState} from "react";
import {Navigate, NavLink, Outlet, useLocation} from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import "./_DashboardLayout.scss";
import {DashboardTitle} from "../../interfaces/types/DashboardTitles.type";
import {useAuthenticationInit} from "../../hooks/useAuthenticationInit";
import ServerErrorStatus from "../../components/organisms/ServerErrorStatus/ServerErrorStatus";
import {DashboardTitleEnum} from "../../interfaces/enums/DashboardTitle.enum";
import LoadingStatus from "../../components/organisms/LoadingStatus/LoadingStatus";
import DashboardNavbar from "../../components/organisms/DashboardNavbar/DashboardNavbar";
import {useErrorsStore} from "../../zustand-store/Errors.store";
import {useAuthenticationStore} from "../../zustand-store/Authentication.store";
import defaultProfilePic from "../../assets/images/defaultProfilePic.png";
import {UserClaims} from "../../interfaces/types/UserClaims.type";
import {UserClaimsEnum} from "../../interfaces/enums/UserClaims.enum copy";
import {useUserClaimStore} from "../../zustand-store/UserClaim.store";
import {useTranslation} from "react-i18next";

export function DashboardLayout(): ReactElement {
    const {t} = useTranslation();
    const location = useLocation();
    let locationPath: DashboardTitle = location.pathname
        .toString()
        .split("/:")[0] as DashboardTitle;

    let locationPathForClaim: UserClaims = location.pathname
        .toString()
        .split("/:")[0] as UserClaims;

    const numberMatch = location.pathname.match(/\/(\d+)$/);
    if (numberMatch) {
        locationPathForClaim = location.pathname.substring(
            0,
            location.pathname.lastIndexOf(`/${numberMatch[1]}`)
        ) as UserClaims;
        locationPath = location.pathname.substring(
            0,
            location.pathname.lastIndexOf(`/${numberMatch[1]}`)
        ) as DashboardTitle;
    }
    const drawerWidth: number = 220;

    const {isLoading} = useAuthenticationInit();

    const {authenticatedUser, isAuthenticated} = useAuthenticationStore();
    const {
        setCanRoleCreate,
        setCanRoleEdit,
        setCanRoleDelete,
        setCanRoleRead,
        userClaims,
    } = useUserClaimStore();
    const {hasServerError} = useErrorsStore();

    const [mobileOpen, setMobileOpen] = useState<boolean>(false);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const {reset} = useErrorsStore();

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
        reset();
        if (userClaims) {
            setCanRoleEdit(
                userClaims[UserClaimsEnum[locationPathForClaim]]?.includes("edit")
            );
            setCanRoleDelete(
                userClaims[UserClaimsEnum[locationPathForClaim]]?.includes("delete")
            );
            setCanRoleRead(
                userClaims[UserClaimsEnum[locationPathForClaim]]?.includes("read")
            );
            setCanRoleCreate(
                userClaims[UserClaimsEnum[locationPathForClaim]]?.includes("add")
            );
        }
    }, [location.pathname, userClaims]);
    if (isLoading) {
        return <LoadingStatus/>;
    }
    return isAuthenticated ? (
        <Box sx={{display: "flex"}} className="dashboardLayout">
            <AppBar
                position="fixed"
                sx={{
                    width: {md: `calc(100% - ${drawerWidth}px)`},
                    minHeight: {xs: "60px", md: "80px"},
                    boxShadow: "none",
                    ml: {md: `${drawerWidth}px`},
                }}
            >
                <Toolbar className="dashboardLayout-topNavbar">
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{mr: 2, display: {md: "none"}, flex: 1}}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <div className="title">
                        <h2>{t(DashboardTitleEnum[locationPath])}</h2>
                        <div className="title-part2">
                            <NavLink to="settings">
                                <img
                                    src={authenticatedUser?.profilePhoto ?? defaultProfilePic}
                                    alt="User profile photo"
                                />
                            </NavLink>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                className="dashboardLayout-nav"
                sx={{width: {md: drawerWidth}, flexShrink: {md: 0}}}
                aria-label="mailbox folders"
            >
                <Drawer
                    variant="temporary"
                    className="dashboardLayout-mobileSideNavbar"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: {xs: "block", md: "none"},
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                        },
                    }}
                >
                    <DashboardNavbar/>
                </Drawer>
                <Drawer
                    variant="permanent"
                    className="dashboardLayout-sideNavbar"
                    sx={{
                        display: {xs: "none", md: "block"},
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                            boxShadow: "0px 0px 22px -3px rgba(0, 0, 0, 0.15)",
                            border: "none",
                        },
                    }}
                    open
                >
                    <DashboardNavbar/>
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    width: {md: `calc(100% - ${drawerWidth}px)`},
                }}
            >
                {hasServerError ? (
                    <ServerErrorStatus/>
                ) : (
                    <div className="dashboardLayout-outlet">
                        <div className="dashboardLayout-outlet-content">
                            <Outlet/>
                        </div>
                    </div>
                )}
            </Box>
        </Box>
    ) : (
        <Navigate to="/login" replace/>
    );
}
