import './_MagazineLinkCard.scss';
import {NavLink, useLocation} from 'react-router-dom';
import {FC} from 'react';
import {MagzineResponseModel} from '../../../interfaces/models/magazineModels/MagazineResponse.model';
import {convertDateFormat} from '../../../utils/covertDateFormat';

const MagazineLinkCard: FC<MagzineResponseModel> = ({
                                                        id,
                                                        insertedDate,
                                                        cover,
                                                        editorialNumber,
                                                        description,
                                                        tags,
                                                        title,
                                                        hasQuizes,
                                                        magazinePath,
                                                    }) => {
    const location = useLocation();

    const locationPath = location.pathname.toString().split('/:')[0];
    const adminPath = location.pathname.includes('dashboard');

    return (
        <>
            {magazinePath && !adminPath ? (
                <a
                    href={magazinePath}
                    target="_blank"
                    className="magazineLinkCard"
                    rel="noreferrer"
                >
                    <img src={cover} alt={`${title} magazine cover`}/>
                    <div className="magazineLinkCard-content">
                        <div className="magazineLinkCard-content-part1">
                            <p className="magazineLinkCard-date">
                                <>
                                    {editorialNumber} <span></span>{' '}
                                    {convertDateFormat(insertedDate).date}
                                </>
                            </p>
                            <h3 className="magazineLinkCard-title">{title}</h3>
                            <p className="magazineLinkCard-description">{description}</p>
                        </div>
                        <div className="magazineLinkCard-tags">
                            {tags?.map((tag, index) => {
                                let color = Math.floor(Math.random() * 16777215).toString(16);
                                return (
                                    <div
                                        className="tag"
                                        key={index}
                                        style={{
                                            color: `#${color}`,
                                            border: `1px solid #${color}`,
                                        }}
                                    >
                                        {tag}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </a>
            ) : (
                <NavLink
                    to={locationPath === '/' ? `magazines/${id}` : `${id}`}
                    className="magazineLinkCard"
                >
                    <img src={cover} alt="cover icon"/>
                    <div className="magazineLinkCard-content">
                        <div className="magazineLinkCard-content-part1">
                            <p className="magazineLinkCard-date">
                                <>
                                    {editorialNumber} <span></span>{' '}
                                    {convertDateFormat(insertedDate).date}
                                </>
                            </p>
                            <h3 className="magazineLinkCard-title">{title}</h3>
                            <p className="magazineLinkCard-description">{description}</p>
                        </div>
                        <div className="magazineLinkCard-tags">
                            {tags?.map((tag, index) => {
                                let color = Math.floor(Math.random() * 16777215).toString(16);
                                return (
                                    <div
                                        className="tag"
                                        key={index}
                                        style={{
                                            color: `#${color}`,
                                            border: `1px solid #${color}`,
                                        }}
                                    >
                                        {tag}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </NavLink>
            )}
        </>
    );
};

export default MagazineLinkCard;
