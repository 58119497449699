import {ReactElement, useLayoutEffect, useMemo} from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {DashboardLayout} from "./layouts/DashboardLayout/DashboardLayout";
import {MainLayout} from "./layouts/MainLayout";
import PageEditor from "./pages/PageEditor/PageEditor";
import {AppRoutes} from "./routes/Routes";
import {deleteProperties} from "./utils/deleteObjectProperties";
import {readAuthenticationToken} from "./utils/storage/authentication";
import jwt_decode from "jwt-decode";
import {useUserClaimStore} from "./zustand-store/UserClaim.store";

const App = (): ReactElement | null => {
    const {allowedRoutes, setUserClaims, setAllowedRoutes} =
        useUserClaimStore();
    // generate public routes
    const publicRoutes = useMemo(
        () =>
            AppRoutes.filter((route) =>
                route.type?.some((type) => type === "PUBLIC")
            ),
        [AppRoutes]
    );

    // generate private routes
    const privateRoutes = useMemo(
        () =>
            AppRoutes.filter(
                (route) =>
                    route.type?.some((type) => type === "PRIVATE") &&
                    (allowedRoutes?.includes(route.name ?? "") ||
                        route.name === "Settings")
            ),
        [AppRoutes, allowedRoutes]
    );

    /**
     * @PATCH Forced approach to manually set window scroll restoration it makes sure to scroll top useLayoutEffect was used to make sure it loads before picture
     */
    useLayoutEffect(() => {
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual'
        }
        window.scrollTo(0, 0);

        if (readAuthenticationToken() !== null) {
            setUserClaims(
                deleteProperties(
                    jwt_decode(readAuthenticationToken() ?? "") as object,
                    ["email", "role", "nbf", "exp", "iat", "nameid"]
                )
            );
            setAllowedRoutes(
                Object.keys(
                    deleteProperties(
                        jwt_decode(readAuthenticationToken() ?? "") as object,
                        ["email", "role", "nbf", "exp", "iat", "nameid"]
                    )
                )
            );
        }
    }, [readAuthenticationToken()]);

    return (
        <Router>
            <Routes>
                <Route path="/" element={<MainLayout/>}>
                    {publicRoutes?.map((publicRoute) => {
                        const {path, ...rest} = publicRoute;
                        return <Route {...rest} key={`${path}`} path={`${path}`}/>;
                    })}
                    {!!readAuthenticationToken() &&
                        allowedRoutes?.includes("Magazines") && (
                            <Route
                                path="page-editor/articlePage/:magazineArticlePageId"
                                element={<PageEditor/>}
                            />
                        )}
                    <Route path="dashboard" element={<DashboardLayout/>}>
                        {privateRoutes?.map((privateRoute) => {
                            const {path, ...rest} = privateRoute;
                            return <Route {...rest} key={`${path}`} path={`${path}`}/>;
                        })}
                    </Route>
                </Route>
            </Routes>
        </Router>
    );
};

export default App;
