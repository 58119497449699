export enum UserClaimsEnum {
  "/dashboard" = "Dashboard",
  "/dashboard/folders" = "Files",
  "/dashboard/magazines" = "Magazines",
  "/dashboard/users" = "Users",
  "/dashboard/authorisations/premissions" = "Authorizations",
  "/dashboard/authorisations/roles" = "Roles",
  "/dashboard/newsletters" = "Newsletters",
  "/dashboard/statistics" = "Statistics",
  "/dashboard/media-files" = "MediaFile",
  "/dashboard/magazines/create-magazine" = "Magazines",
}
