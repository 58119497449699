import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { basicParamsTypes } from "../../../interfaces/models/basicParams.model";
import { FileResponseModel } from "../../../interfaces/models/folderModels/FileResponse.model";
import { FoldersResponseModel } from "../../../interfaces/models/folderModels/FolderResponse.model";
import { ResponseModel } from "../../../interfaces/models/Response.model";
import { getAllFolders, getAllFoldersFiles } from "../../queries/Folder";

export const useGetAllFoldersQuery = (
  getAllFoldersParams: basicParamsTypes,
  options?: Omit<
    UseQueryOptions<ResponseModel<FoldersResponseModel[]>>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<ResponseModel<FoldersResponseModel[]>> => {
  return useQuery(
    [
      "getAllFolders",
      getAllFoldersParams.pageNumber,
      getAllFoldersParams.searchWord,
    ],
    () => getAllFolders(getAllFoldersParams),
    options
  );
};
export const useGetAllFolderFilesQuery = (
  folderId: number,
  getAllFolderFileParams: basicParamsTypes,
  options?: Omit<
    UseQueryOptions<ResponseModel<FileResponseModel[]>>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<ResponseModel<FileResponseModel[]>> => {
  return useQuery(
    [
      "getAllFiles",
      getAllFolderFileParams.pageNumber,
      getAllFolderFileParams.searchWord,
    ],
    () => getAllFoldersFiles(folderId, getAllFolderFileParams),
    options
  );
};
