import TextField from "@mui/material/TextField";
import "./_CreateMagazine.scss";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import {useForm} from "react-hook-form";
import {joiResolver} from "@hookform/resolvers/joi";
import CancelIcon from "@mui/icons-material/Cancel";
import {useNavigate, useSearchParams} from "react-router-dom";
import {CreateMagazineInputModel} from "../../interfaces/models/magazineModels/CreateMagazine.model";
import {useImagePreview} from "../../hooks/useImagePreview";
import {usePostCreateMagazine} from "../../react-query/hooks/mutationHooks/Magazine";
import {CreateMagazineSchema} from "../../schemas/magazineSchemas/CreateMagazine.schema";
import LoadingStatus from "../../components/organisms/LoadingStatus/LoadingStatus";
import StatusState from "../../components/molecules/StatusState/StatusState";
import Button from "../../components/atoms/Button/Button.tsx";
import {Typography} from "@mui/material";
import {GenericDocumentUpload} from "../../components/molecules/GenericDocumentUpload/GenericDocumentUpload";

const CreateMagazine = () => {
    /**
     * hooks
     */
    let [searchParams] = useSearchParams();
    const magazineType = searchParams.get("type");
    /**
     * handlers
     */
    const handleSelectPdfFile = async (file: File | null) => {
        setValue("MagazinePdf", file, {shouldValidate: true});
    };

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        clearErrors,
        getValues,
        watch,
        formState: {errors},
    } = useForm<CreateMagazineInputModel>({
        defaultValues: {MagazinePdf: null},
        resolver: joiResolver(CreateMagazineSchema),
    });

    const navigate = useNavigate();
    const {image} = useImagePreview(getValues("Cover"));
    const {mutate: createMagazine, isLoading} = usePostCreateMagazine();
    const handleCreateMagazineSubmit = (
        createMagazineInputs: CreateMagazineInputModel
    ) => {
        const createMagazineFormData = new FormData();
        createMagazineFormData.append("Title", createMagazineInputs.Title);
        createMagazineFormData.append("Cover", createMagazineInputs.Cover);
        // createMagazineFormData.append('HasQuizes', createMagazineInputs.HasQuizes);
        createMagazineFormData.append(
            "Description",
            createMagazineInputs.Description
        );
        createMagazineFormData.append(
            "EditorialNumber",
            createMagazineInputs.EditorialNumber
        );
        if (createMagazineInputs.MagazinePdf)
            createMagazineFormData.append(
                "MagazinePdf",
                createMagazineInputs.MagazinePdf
            );
        createMagazine(createMagazineFormData, {
            onSuccess: (data) => {
                if (createMagazineInputs.MagazinePdf && magazineType === "pdf") {
                    navigate("/dashboard/magazines");
                } else {
                    navigate(`/dashboard/magazines/${data.data.id}`);
                }
            },
        });
    };
    const handleRemoveCoverPic = () => {
        reset({Cover: undefined});
    };
    const handleCancel = () => {
        reset();
        clearErrors();
    };
    if (isLoading) {
        return <LoadingStatus/>;
    }
    return (
        <div className="pagesStyle">
            <h2 className="pagesStyle-title">Create a magazine</h2>
            <div className="createMagazine">
                <div className="createMagazine-header">
                    <h3>Basic information</h3>
                    <p>Information about the magazine</p>
                </div>
                <form
                    className="createMagazine-content"
                    onSubmit={handleSubmit(handleCreateMagazineSubmit)}
                >
                    <div className="inputs">
                        <div className="inputs-part1">
                            {(!image || errors?.Cover) && (
                                <label>
                                    <input
                                        type="file"
                                        onChange={(e) => {
                                            setValue("Cover", e.target.files![0], {
                                                shouldValidate: true,
                                            });
                                        }}
                                    />
                                    <div
                                        className={`uploadMagazineCover ${
                                            !!errors?.Cover ? "errorNoFile" : ""
                                        }`}
                                    >
                                        <div className="noPhoto">
                                            <PhotoLibraryIcon
                                                sx={{
                                                    color: errors?.Cover ? "rgb(200, 0 , 0)" : "#1976D2",
                                                    fontSize: "40px",
                                                    marginBottom: "10px",
                                                }}
                                            />
                                            <h4>
                                                <span>Click to upload</span>
                                            </h4>
                                            <p>File type: PNG, JPG.</p>
                                            {!!errors?.Cover && (
                                                <p className="pError">{errors.Cover.message}</p>
                                            )}
                                        </div>
                                    </div>
                                </label>
                            )}
                            {!!image && !errors.Cover && (
                                <div className="magazineCover">
                                    {image && <img src={image} alt='magazine cover'/>}
                                    <CancelIcon
                                        className="icon"
                                        sx={{
                                            color: "white",
                                            fontSize: "25px",
                                            backgroundColor: "#979BAD",
                                            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                                            borderRadius: "500px",
                                            border: "none",
                                            cursor: "pointer"
                                        }}
                                        onClick={handleRemoveCoverPic}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="inputs-part2">
                            {/* <div className="magazineTypes">
                <div>
                  <input
                    type="radio"
                    value="true"
                    id="magazine-with-quiz"
                    {...register("HasQuizes")}
                  />
                  <label htmlFor="magazine-with-quiz">With quiz</label>
                </div>
                <div>
                  <input
                    type="radio"
                    value="false"
                    defaultChecked
                    {...register("HasQuizes")}
                    id="magazine-without-quiz"
                  />
                  <label htmlFor="magazine-without-quiz">Without quiz</label>
                </div>
              </div> */}
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                label="Title of the magazine*"
                                color="success"
                                variant="outlined"
                                error={!!errors?.Title}
                                helperText={errors?.Title?.message}
                                {...register("Title")}
                            />
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                label="Description*"
                                color="success"
                                variant="outlined"
                                multiline
                                inputProps={{maxLength: 495}}
                                error={!!errors?.Description}
                                helperText={errors?.Description?.message}
                                {...register("Description")}
                            />
                            <TextField
                                id="outlined-basic"
                                label="Editorial number*"
                                color="success"
                                variant="outlined"
                                error={!!errors?.EditorialNumber}
                                helperText={errors?.EditorialNumber?.message}
                                {...register("EditorialNumber")}
                            />
                            {magazineType === "pdf" && (
                                <>
                                    <Typography fontSize="12px" sx={{color: "#9A9A9A"}}>
                                        Upload your magazine*
                                    </Typography>
                                    <GenericDocumentUpload
                                        onSelect={handleSelectPdfFile}
                                        selectedFile={watch("MagazinePdf")}
                                        canDelete={!!watch("MagazinePdf")}
                                        helperText={errors.MagazinePdf?.message}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                    {isLoading ? (
                        <div className="createMagazine-content-buttons">
                            <StatusState
                                statusText="Creating Magazine..."
                                statusType="loading"
                            />
                        </div>
                    ) : (
                        <div className="createMagazine-content-buttons">
                            <Button
                                content="Cancel"
                                styleType="third"
                                type="button"
                                onClick={handleCancel}
                            />
                            <Button content="Save" styleType="second " type="submit"/>
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
};
export default CreateMagazine;
