import { FC } from 'react';
import './_StatusState.scss';
import ReportIcon from '@mui/icons-material/Report';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircularProgress from '@mui/material/CircularProgress';

interface StatusStateProps {
  statusText: string;
  statusType: 'loading' | 'error' | 'success';
}
const StatusState: FC<StatusStateProps> = ({ statusText, statusType }) => {
  return (
    <div className={`statusState statusState-${statusType}`}>
      {statusType === 'error' && <ReportIcon />}
      {statusType === 'success' && <CheckCircleIcon />}
      {statusType === 'loading' && <CircularProgress size={24} thickness={5} />}
      {<p>{statusText}</p>}
    </div>
  );
};
export default StatusState;
