import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import "./_MediaFiles.scss";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField/TextField";
import InputAdornment from "@mui/material/InputAdornment/InputAdornment";
import { useState } from "react";
import Button from "../../../components/atoms/Button/Button.tsx";
import { useModalStore } from "../../../zustand-store/Modal.store";
import AddMediaFile from "../AddMediaFile";
import { useGetAllMediaFilesQuery } from "../../../react-query/hooks/queriesHooks/MediaFile";
import MediaFileItem from "../../../components/molecules/MediaFile/MediaFileItem";
import { isImage } from "../../../utils/isImage";
import { isVideo } from "../../../utils/isVideo";
import MuiPagination from "../../../components/molecules/MuiPagination/MuiPagination";
import LoadingStatus from "../../../components/organisms/LoadingStatus/LoadingStatus";

export default function MediaFilesModal({
  modalShow,
  setModalShow,
}: {
  modalShow: boolean;
  setModalShow: (modalShow: boolean) => void;
}) {
  const {
    setTitle,
    setDescription,
    setModalShow: setAddFileModalShow,
    setModalContent,
  } = useModalStore();
  //states
  const [page, setPage] = useState(1);
  const [searchWord, setSearchWord] = useState<string>("");

  //media files data query
  const { data: { data: files, metaData } = {}, isLoading } =
    useGetAllMediaFilesQuery({
      pageNumber: String(page),
      searchWord: searchWord,
    });

  //close main modal
  const handleCloseModal = () => {
    setModalShow(false);
  };

  //second modal
  const handleAddFileModalShow = () => {
    setTitle("+ Add Media File");
    setDescription("You can add Images and Videos...");
    setAddFileModalShow(true);
    setModalContent(<AddMediaFile />);
  };
  if (isLoading) {
    return <LoadingStatus />;
  }
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={modalShow}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      sx={{ zIndex: "10003" }}
    >
      <Fade in={modalShow}>
        <Box>
          <div className="mediaFilesModal">
            <div className="mediaFilesModal-header">
              <h2 className="mediaFilesModal-header-title">Media Files</h2>
              <div className="mediaFilesModal-header-inputs">
                <TextField
                  id="outlined-basic"
                  label="Search for media file"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ color: "#a1a7b3" }} />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    setSearchWord(e.target.value);
                    setPage(1);
                  }}
                  variant="outlined"
                  color="success"
                />
                <Button
                  onClick={handleAddFileModalShow}
                  content="+ Add File"
                  styleType="first"
                  type="button"
                />
              </div>
            </div>

            <div className="mediaFilesModal-content">
              {files?.map((file) => {
                return (
                  <MediaFileItem
                    key={file.id}
                    fileId={file.id}
                    isImage={!!isImage(file.filePath)}
                    isVideo={!!isVideo(file.filePath)}
                    videoType={`${file.filePath.split(".").pop()}`}
                    filePath={file.filePath}
                    fileName={file.fileName}
                    uploader={file.uploader}
                  />
                );
              })}
            </div>
            <div className="mediaFilesModal-pagination">
              <MuiPagination
                pagesNumber={metaData?.pageCount}
                page={page}
                setPage={setPage}
              />
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
