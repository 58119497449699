import { create } from 'zustand';

interface GeneralStore {
  searchWord: string;
  setSearchWord: (searchWord: string) => void;
  email: string;
  setEmail: (email: string) => void;
}

export const useGeneralStore = create<GeneralStore>((set) => ({
  email: '',
  setEmail: (email: string) => set(() => ({ email })),
  searchWord: '',
  setSearchWord: (searchWord) => set(() => ({ searchWord })),
}));
