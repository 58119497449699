import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { SyntheticEvent, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import "./_DashboardMagazine.scss";
import { Collapse } from "@mui/material";
import { useGetMagazineQuery } from "../../react-query/hooks/queriesHooks/Magazine";
import { useDeleteMagazine } from "../../react-query/hooks/mutationHooks/Magazine";
import LoadingStatus from "../../components/organisms/LoadingStatus/LoadingStatus";
import CoverImg from "../../components/molecules/Magazine/CoverImg";
import MagazinePublishment from "../../components/molecules/Magazine/MagazinePublishment";
import General from "../../components/organisms/DashboardMagazine/General";
import Articles from "../../components/organisms/DashboardMagazine/Articles";
import Pages from "../../components/organisms/DashboardMagazine/Pages";
import MuiModal from "../../modals/MuiModal";
import Delete from "../../modals/dashboard/Delete";
import { useUserClaimStore } from "../../zustand-store/UserClaim.store";
import { useTranslation } from "react-i18next";

const DashboardMagazine = () => {
  const { t } = useTranslation();
  const { canRoleDelete } = useUserClaimStore();
  const { magazineId } = useParams();
  const navigate = useNavigate();

  //tabs
  const [value, setValue] = useState(0);
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { data: { data: magazineInfo } = {}, isLoading: isGetMagazineLoading } =
    useGetMagazineQuery(Number(magazineId));

  //deleteMagazineModal
  const [modalShow, setModalShow] = useState(false);
  const handleModalShow = () => setModalShow(true);
  const { mutate: deleteMagazine, isLoading: isDeleteMagazineLoading } =
    useDeleteMagazine();
  const handleMagazineDelete = () => {
    deleteMagazine(Number(magazineId), {
      onSuccess: () => {
        navigate("/dashboard/magazines");
      },
    });
  };

  if (isGetMagazineLoading || isDeleteMagazineLoading) {
    return <LoadingStatus />;
  }

  return (
    <>
      <div className="dashboardMagazine pagesStyle">
        <div className="dashboardMagazine-header">
          <h2 className="pagesStyle-title">
            {t("dashboard.magazine.magazine")}
          </h2>
          <div className="dashboardMagazine-header-part2">
            {canRoleDelete && (
              <button
                type="button"
                onClick={handleModalShow}
                className="delete"
              >
                <DeleteIcon sx={{ fontSize: "20px", color: "#EA5C5F" }} />
                {t("dashboard.reusable.delete")}
              </button>
            )}
            <NavLink to={`/magazines/${magazineId}`} className="preview">
              <button>
                <VisibilityIcon sx={{ fontSize: "20px", color: "#367CD2" }} />
                {t("dashboard.magazine.preview")}
              </button>
            </NavLink>
          </div>
        </div>
        <div className="dashboardMagazine-content">
          <div className="part1">
            <div className="cover">
              <CoverImg coverPath={magazineInfo?.cover ?? ""} />
              <MagazinePublishment isPublished={!!magazineInfo?.isPublished} />
            </div>
          </div>
          <div className="part2">
            <div className="part2-navbar">
              <Tabs value={value} onChange={handleChange}>
                <Tab label={t("dashboard.magazine.general.general")} />
                {!magazineInfo?.magazinePath && (
                  <Tab label={t("dashboard.magazine.articles.articles")} />
                )}
                {!magazineInfo?.magazinePath && (
                  <Tab label={t("dashboard.magazine.pages.pages")} />
                )}
              </Tabs>
            </div>
            <div className="part2-content">
              <Collapse in={value === 0} timeout={0} mountOnEnter>
                <General
                  title={magazineInfo?.title}
                  description={magazineInfo?.description}
                  editorialNumber={magazineInfo?.editorialNumber}
                  magazinePath={magazineInfo?.magazinePath}
                  hasQuizes={magazineInfo?.hasQuizes}
                />
              </Collapse>
              <Collapse in={value === 1} timeout={0} mountOnEnter>
                <Articles index={value} />
              </Collapse>
              <Collapse in={value === 2} timeout={0} mountOnEnter>
                <Pages index={value} />
              </Collapse>
            </div>
          </div>
        </div>
      </div>
      <MuiModal
        title="Delete a Magazine"
        description="Are you sure that you want to delete this Magazine?"
        modalContent={
          <Delete
            deleteFunction={handleMagazineDelete}
            setModalShow={setModalShow}
          />
        }
        modalShow={modalShow}
        setModalShow={setModalShow}
      />
    </>
  );
};
export default DashboardMagazine;
