import { ChangePasswordInputModel } from "../../interfaces/models/accountModels/ChangePassword.model";
import { ContactInputModel } from "../../interfaces/models/accountModels/Contact.model";
import { ForgotPasswordInputModel } from "../../interfaces/models/accountModels/ForgotPassword.model";
import { ResetPasswordInputModel } from "../../interfaces/models/accountModels/ResetPassword.model";
import { SignInInputModel } from "../../interfaces/models/accountModels/SignIn.model";
import axios from "../../initializations/axios";

//-----POST MUTATION-----
export const postSignIn = async (
  signInPayload: SignInInputModel
): Promise<any> => {
  const { data } = await axios.post("Account/SignIn", signInPayload);
  return data;
};
export const postRefreshToken = async (refreshToken: string): Promise<any> => {
  const { data } = await axios.post("Account/RefreshToken", null, {
    params: { refreshToken: refreshToken },
  });
  return data;
};
export const postForgotPassword = async (
  forgotPasswordPayload: ForgotPasswordInputModel
): Promise<any> => {
  const { data } = await axios.post(
    "Account/ForgotPassword",
    forgotPasswordPayload
  );
  return data;
};
export const postResetPassword = async (
  resetPasswordPayload: ResetPasswordInputModel
): Promise<any> => {
  const { data } = await axios.post(
    "Account/ResetPassword",
    resetPasswordPayload
  );
  return data;
};
export const postChangePassword = async (
  changePasswordPayload: ChangePasswordInputModel
): Promise<any> => {
  const { data } = await axios.post(
    "Account/ChangePassword",
    changePasswordPayload
  );
  return data;
};
export const postContactUs = async (
  contactUsPayload: ContactInputModel
): Promise<any> => {
  const { data } = await axios.post("Account/ContactUs", contactUsPayload);
  return data;
};
//-----PUT MUTATION-----
export const putEditMyProfile = async (
  editMyProfilePayload: FormData
): Promise<any> => {
  const { data } = await axios.put(
    "Account/EditMyProfile",
    editMyProfilePayload,
    { headers: { "Content-Type": "multipart/form-data" } }
  );
  return data;
};
//-----DELETE MUTATION-----
export const deleteDeactivateMyAccount = async (): Promise<any> => {
  const { data } = await axios.delete("Account/DeactivateMyAccount");
  return data;
};
export const deleteDeleteProfilePicture = async (): Promise<any> => {
  const { data } = await axios.delete("Account/DeleteProfilePicture");
  return data;
};
