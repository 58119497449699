import "./_Users.scss";
import SearchIcon from "@mui/icons-material/Search";
import {useState} from "react";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import {useGetAllUsersQuery} from "../../react-query/hooks/queriesHooks/Admin";
import Button from "../../components/atoms/Button/Button.tsx";
import LoadingStatus from "../../components/organisms/LoadingStatus/LoadingStatus";
import User from "../../components/molecules/ListsItems/User";
import MuiPagination from "../../components/molecules/MuiPagination/MuiPagination";
import AddUser from "../../modals/dashboard/AddUser";
import MuiModal from "../../modals/MuiModal";
import NoResults from "../../components/organisms/NoResults/NoResults";
import {useUserClaimStore} from "../../zustand-store/UserClaim.store";
import {useTranslation} from "react-i18next";
import {Box} from "@mui/material";
import DataLayout from "../../components/atoms/DataLayout/DataLayout";

const Users = () => {
    const {t} = useTranslation();
    const {canRoleCreate, canRoleEdit, canRoleDelete} = useUserClaimStore();
    const [modalShow, setModalShow] = useState(false);
    const [page, setPage] = useState(1);
    const [searchWord, setSearchWord] = useState<string>("");
    const {data: {data: users, metaData} = {}, isLoading} =
        useGetAllUsersQuery({
            pageNumber: String(page),
            searchWord: searchWord,
        });
    const handleModalShow = () => setModalShow(true);

    return (
        <>
            <DataLayout title={t("dashboard.users.list_of_users")}
                        options={<Box display="flex" justifyContent="space-between" gap={2} width="100%">
                            <TextField
                                id="outlined-basic"
                                label={t("dashboard.users.search_user")}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start" style={{
                                            padding: 0,
                                            margin: 0,
                                        }}>
                                            <SearchIcon sx={{color: "#a1a7b3"}}/>
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={(e) => {
                                    setSearchWord(e.target.value);
                                    setPage(1);
                                }}
                                variant="outlined"
                                color="success"
                            />
                            <Button
                                show={canRoleCreate}
                                onClick={handleModalShow}
                                content={`+ ${t("dashboard.users.add_user")}`}
                                styleType="first"
                                type="button"
                            />
                        </Box>}>
                {isLoading ? (
                    <LoadingStatus/>
                ) : (
                    <>
                        {metaData?.totalItemCount !== 0 ? (
                            <>
                                <Box position="relative" className="pagesStyle-tables scroll-group" minHeight={535}
                                     padding={2}
                                     boxShadow="0 0 1px rgba(0, 0, 0, 0.15)">
                                    <Box overflow="auto" className="scroll-group-hover:no-scrollbar">
                                        <table>
                                            <thead>
                                            <tr className="no-hover">
                                                <th className="firstItem">
                                                    {t("dashboard.users.add_user")}
                                                </th>
                                                <th>{t("reusable.email")}</th>
                                                <th
                                                    className={`${
                                                        !(canRoleEdit || canRoleDelete) ? "lastItem" : ""
                                                    }`}
                                                >
                                                    {t("dashboard.reusable.role")}
                                                </th>
                                                {(canRoleEdit || canRoleDelete) && (
                                                    <th className="lastItem">
                                                        {t("dashboard.reusable.action")}
                                                    </th>
                                                )}
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {users?.map((user, index) => {
                                                return (
                                                    <User
                                                        key={user.userId}
                                                        userId={user.userId}
                                                        fullName={user.fullName}
                                                        email={user.email}
                                                        roles={user.roles}
                                                    />
                                                );
                                            })}
                                            </tbody>
                                        </table>
                                    </Box>
                                    <Box position="absolute" bottom={0} padding={2} right={0}>
                                        <MuiPagination
                                            pagesNumber={metaData?.pageCount}
                                            page={page}
                                            setPage={setPage}
                                        />
                                    </Box>
                                </Box>
                            </>
                        ) : (
                            <NoResults/>
                        )}
                    </>
                )}
            </DataLayout>
            <MuiModal
                title={t("dashboard.users.add_user")}
                description={t("dashboard.reusable.complete_fields")}
                modalContent={<AddUser setModalShow={setModalShow}/>}
                modalShow={modalShow}
                setModalShow={setModalShow}
            />
        </>
    );
};
export default Users;
