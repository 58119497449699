import {useQuery,} from "@tanstack/react-query";
import {BasicResponseModel} from "../../../interfaces/models/BasicResponse.model";
import {UserModel} from "../../../interfaces/models/User.model";
import {removeAuthenticationToken} from "../../../utils/storage/authentication";
import {useAuthenticationStore} from "../../../zustand-store/Authentication.store";
import {getMyProfile} from "../../queries/Account";

export const useGetMyProfileQuery = () => {
    const {setAuthenticatedUser, removeAuthenticatedUser} =
        useAuthenticationStore();
    return useQuery(["myProfile"], getMyProfile, {
        enabled: false,
        onSuccess: (getMyProfileResponse: BasicResponseModel<UserModel>) =>
            setAuthenticatedUser(getMyProfileResponse.data, true),
        onError: () => {
            removeAuthenticatedUser();
            removeAuthenticationToken();
        },
    });
};
