import { useParams } from "react-router-dom";
import { useGetAllMagazineArticlesQuery } from "../../../react-query/hooks/queriesHooks/Magazine";
import Page from "../../molecules/Magazine/Page";
import LoadingStatus from "../LoadingStatus/LoadingStatus";
import NoResults from "../NoResults/NoResults";
import "./_Magazine.scss";
import { useTranslation } from "react-i18next";

const Pages = ({ index }: { index: number }) => {
  const { t } = useTranslation();
  const { magazineId } = useParams();
  const { data: { data: articles } = {}, isLoading } =
    useGetAllMagazineArticlesQuery(Number(magazineId), {
      enabled: index === 2,
    });
  if (isLoading) {
    <LoadingStatus />;
  }
  return (
    <div>
      <div className="tab-header">
        <div className="tab-header-title">
          <h3>{t("dashboard.magazine.pages.list_of_pages")}</h3>
          <p>{t("dashboard.magazine.pages.create_page_by_article")}</p>
        </div>
      </div>
      {articles?.length === 0 ? (
        <NoResults />
      ) : (
        <div className="tab-body">
          {articles?.map((article) => {
            return (
              <Page
                key={article?.id}
                articleId={article?.id}
                articleTitle={article?.title}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
export default Pages;
