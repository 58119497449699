import Joi from "joi";
import { TFunction } from "react-i18next";

export const ResetPasswordSchema = (t: TFunction) =>
  Joi.object({
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .messages({
        "string.empty": t("reusable.required"),
        "string.email": t("reusable.email_must_be_valid"),
      }),
    resetPasswordCode: Joi.string()
      .required()
      .min(6)
      .messages({
        "any.required": t("reusable.required"),
        "string.min": t("public.change_password.part2.code_must_be_6"),
      }),
    newPassword: Joi.string()
      .required()
      .regex(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
      )
      .messages({
        "string.empty": t("reusable.required"),
        "string.pattern.base": t("reusable.new_password_validation_error"),
      }),
    confirmNewPassword: Joi.string()
      .required()
      .valid(Joi.ref("newPassword"))
      .messages({
        "any.required": t("reusable.required"),
        "string.pattern.base": t("reusable.password_must_be_8"),
        "any.only": t("reusable.confrim_password"),
      }),
  });
