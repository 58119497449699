import "./_ListItems.scss";
import DeleteIcon from "@mui/icons-material/Delete";
import { FC, useState } from "react";
import { RoleResponseModel } from "../../../interfaces/models/adminModels/Role.model";
import MuiModal from "../../../modals/MuiModal";
import { useDeleteRole } from "../../../react-query/hooks/mutationHooks/Admin";
import Delete from "../../../modals/dashboard/Delete";
import { useUserClaimStore } from "../../../zustand-store/UserClaim.store";

const Role: FC<RoleResponseModel> = ({ roleId, roleClaims, name }) => {
  //deleteRoleModal
  const { canRoleDelete } = useUserClaimStore();
  const [modalShow, setModalShow] = useState(false);
  const handleModalShow = () => setModalShow(true);
  const { mutate: deleteRole, isLoading, isSuccess } = useDeleteRole();
  const handleDeleteRole = () => {
    deleteRole(roleId);
  };

  return (
    <tr className="cursor-default">
      <td className="firstItem">
        <p>{name}</p>
      </td>
      {canRoleDelete && (
        <td className="lastItem">
          <div className="listItem-icons tables-item">
            <DeleteIcon className="muiIcon" onClick={handleModalShow} />
          </div>
        </td>
      )}
      <MuiModal
        title="Delete a Role"
        description="Are you sure that you want to delete this role?"
        modalContent={
          <Delete
            deleteFunction={handleDeleteRole}
            setModalShow={setModalShow}
            isLoading={isLoading}
            isSuccess={isSuccess}
          />
        }
        modalShow={modalShow}
        setModalShow={setModalShow}
      />
    </tr>
  );
};

export default Role;
