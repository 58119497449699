import { basicParamsTypes } from "../../interfaces/models/basicParams.model";
import axios from "../../initializations/axios";

export const getAllNewsletterSubscribers = async (
  userNumber: number,
  getAllFolderFilesParams: basicParamsTypes
) => {
  const { data } = await axios.get("NewsLetter/GetAllNewsletterSubscribers", {
    params: {
      searchWord: getAllFolderFilesParams.searchWord,
      pageSize: userNumber,
      pageNumber: getAllFolderFilesParams.pageNumber,
    },
  });
  return data;
};
