import TextField from "@mui/material/TextField";
import { ChangeEvent, FC, KeyboardEvent, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { useParams } from "react-router-dom";
import { CreateMagazineArticleInputModel } from "../../interfaces/models/magazineModels/CreateMagazineArticle.model";
import { CreateMagazineArticleSchema } from "../../schemas/magazineSchemas/CreateMagazineArticle.schema";
import {
  usePostAddMagazineArticle,
  usePutEditMagazineArticle,
} from "../../react-query/hooks/mutationHooks/Magazine";
import { closeModalWithDelay } from "../../utils/closeModalWithDelay";
import StatusState from "../../components/molecules/StatusState/StatusState";
import Button from "../../components/atoms/Button/Button.tsx";
import { useTranslation } from "react-i18next";

interface AddArticleInformationsProps {
  update?: boolean;
  title?: string;
  articleTags?: string[];
  articleId?: number;
  setModalShow: (modalshow: boolean) => void;
}
const AddArticleInformations: FC<AddArticleInformationsProps> = ({
  update,
  title,
  articleTags,
  articleId,
  setModalShow,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<CreateMagazineArticleInputModel>({
    resolver: joiResolver(CreateMagazineArticleSchema),
    defaultValues: {
      title: title,
      tags: articleTags,
    },
  });
  const { magazineId } = useParams();
  const { t } = useTranslation();
  const {
    mutate: addMagazineArticle,
    isLoading: isPostArticleLoading,
    isSuccess: isPostArticleSuccess,
  } = usePostAddMagazineArticle(Number(magazineId));
  const {
    mutate: updateMagazineArticle,
    isLoading: isUpdateArticleLoading,
    isSuccess: isUpdateArticleSuccess,
  } = usePutEditMagazineArticle(Number(articleId));

  const [tag, setTag] = useState<string>();
  const handleCancel = () => {
    reset();
    setModalShow(false);
  };
  const handleAddArticleInformations = (
    addArticleInformationsPayload: CreateMagazineArticleInputModel
  ) => {
    if (addArticleInformationsPayload.tags === null)
      delete addArticleInformationsPayload.tags;
    addMagazineArticle(addArticleInformationsPayload, {
      onSuccess: () => {
        closeModalWithDelay(setModalShow);
      },
    });
  };
  const handleUpdateAddArticleInformations = (
    updateAddArticleInformationsPayload: CreateMagazineArticleInputModel
  ) => {
    if (updateAddArticleInformationsPayload.tags === null) {
      delete updateAddArticleInformationsPayload.tags;
    }

    updateMagazineArticle(updateAddArticleInformationsPayload, {
      onSuccess: () => {
        closeModalWithDelay(setModalShow);
      },
    });
  };
  const handleTagsFieldChanges = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setTag(e.target.value);
  };
  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddTag();
    }
  };
  const handleAddTag = () => {
    if (tag !== undefined) {
      if (tag !== "" && !watch("tags")?.includes(tag)) {
        setValue("tags", [...(watch("tags") || []), tag]);
        setTag("");
      }
      if (watch("tags")?.includes(tag)) {
        setTag("");
      }
    }
  };
  const handleRemoveTag = (tag: string) => {
    setValue(
      "tags",
      watch("tags")?.filter((tagItem) => tagItem !== tag)
    );
  };
  return (
    <form
      className="muiModal-content"
      onSubmit={handleSubmit(
        update
          ? handleUpdateAddArticleInformations
          : handleAddArticleInformations
      )}
    >
      <div className="muiModal-content-inputs">
        <TextField
          className="muiModal-content-inputs-input"
          fullWidth
          id="outlined-basic"
          label={t("dashboard.files.title")}
          color="success"
          variant="outlined"
          error={!!errors?.title}
          helperText={errors?.title?.message}
          {...register("title")}
        />
        <div className="muiModal-content-inputs-addTags">
          <TextField
            className="muiModal-content-inputs-input"
            fullWidth
            id="outlined-basic"
            label={t("dashboard.magazine.articles.modals.article_tags")}
            color="success"
            variant="outlined"
            value={tag}
            onChange={(e) => handleTagsFieldChanges(e)}
            onKeyDown={(e) => handleKeyDown(e)}
          />
          <button type="button" onClick={handleAddTag}>
            {t("dashboard.magazine.articles.modals.add_tag")}
          </button>
        </div>
      </div>
      <div
        className={`muiModal-content-tags ${
          watch("tags") !== null || watch("tags") !== undefined
            ? "scrollbarShow"
            : ""
        }`}
      >
        {watch("tags")?.map((tag, index) => {
          return (
            <div key={index} className="muiModal-content-tags-tag">
              <span>{tag}</span>
              <CancelIcon
                className="icon"
                sx={{
                  color: "rgba(0, 0, 0, 0.23)",
                  fontSize: "18px",
                  backgroundColor: "transparent",
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                  borderRadius: "500px",
                  border: "none",
                }}
                onClick={() => handleRemoveTag(tag)}
              />
            </div>
          );
        })}
      </div>
      <div className="muiModal-content-status">
        {isPostArticleLoading && (
          <StatusState
            statusText={t("reusable.loading")}
            statusType="loading"
          />
        )}
        {isPostArticleSuccess && (
          <StatusState
            statusText={t(
              "dashboard.magazine.articles.modals.success_post_status"
            )}
            statusType="success"
          />
        )}
        {isUpdateArticleLoading && (
          <StatusState
            statusText={t("reusable.loading")}
            statusType="loading"
          />
        )}
        {isUpdateArticleSuccess && (
          <StatusState
            statusText={t(
              "dashboard.magazine.articles.modals.success_update_status"
            )}
            statusType="success"
          />
        )}
      </div>
      {!isPostArticleLoading &&
        !isPostArticleSuccess &&
        !isUpdateArticleLoading &&
        !isUpdateArticleSuccess && (
          <div className="muiModal-content-modalbuttons">
            <Button
              onClick={handleCancel}
              content={t("dashboard.reusable.cancel")}
              styleType="third"
              type="button"
            />
            <Button
              content={t("dashboard.reusable.save")}
              styleType="second"
              type="submit"
            />
          </div>
        )}
    </form>
  );
};

export default AddArticleInformations;
