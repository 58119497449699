import CircularProgress from '@mui/material/CircularProgress';
import '../NoResults/_NoResults.scss';
const LoadingStatus = () => {
  return (
    <div className="noResultsFound">
      <div>
        <CircularProgress color="success" />
      </div>
    </div>
  );
};
export default LoadingStatus;
